/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import CheckListRow from './CheckListRow';
import checkListRowFields from './checkListRowFields';


function CheckListModal({ onSave, onCancel, application }) {
  const [object, setObject] = useState({});
  const [errorFields, setErrorFields] = useState([]);
  const [finishButtonName, setFinishButtonName] = useState('Направить Заявку на доработку');
  const [isShowConfirmDeclineModal, setIsShowConfirmDeclineModal] = useState(false);

  useEffect(() => {
    const obj = {};
    const fields = checkListRowFields.map((f) => f.fieldName).filter((f) => !!f && f !== 'row4');
    fields.forEach((field) => {
      obj[`${field}CheckBox`] = application[`${field}CheckBox`];
      obj[`${field}Comment`] = application[`${field}Comment`];
    });
    setObject(obj);
    if (application.declineComment) {
      setFinishButtonName('Направить в архив');
    }
  }, []);

  const onCancelModal = () => {
    setObject({});
    onCancel();
  };

  const onSaveModal = () => {
    const fields = checkListRowFields.map((f) => f.fieldName).filter((f) => !!f && f !== 'row4');
    const errors = [];
    fields.forEach((field) => {
      if (!object[`${field}CheckBox`] && !object[`${field}Comment`]) {
        errors.push(field);
      }
    });
    if (errors.length > 0) {
      setErrorFields(errors);
    } else if (finishButtonName === 'Согласовать Заявку') {
      setErrorFields([]);
      onSave({ ...object, status: 'Принята к рассмотрению' }, null);
    } else {
      setErrorFields([]);
      setIsShowConfirmDeclineModal(true);
    }
  };

  const onCheckBoxChange = (value, field) => {
    const fields = checkListRowFields.map((f) => f.fieldName).filter((f) => !!f && f !== 'row4');
    let isHasComment = false;
    fields.forEach((f) => {
      if (f !== field) {
        if (!object[`${f}CheckBox`]) {
          isHasComment = true;
        }
      } else if (!value) {
        isHasComment = true;
      }
    });
    if (isHasComment) {
      if (application.declineComment) {
        setFinishButtonName('Направить в архив');
      } else {
        setFinishButtonName('Направить Заявку на доработку');
      }
    } else {
      setFinishButtonName('Согласовать Заявку');
    }
    setObject({ ...object, [`${field}CheckBox`]: value, [`${field}Comment`]: null });
  };

  const onCommentChange = (value, field) => {
    setObject({ ...object, [`${field}Comment`]: value });
  };

  const comments = [];
  checkListRowFields.forEach((field) => {
    if (object[`${field.fieldName}Comment`]) {
      comments.push(object[`${field.fieldName}Comment`]);
    }
  });

  const isDisabled = application.status === 'В архиве' || application.status === 'Принята к рассмотрению';

  return (
    <>
      <Modal
        visible
        width="70vw"
        maskClosable={false}
        onCancel={onCancelModal}
        title="Чеклист заявки"
        footer={isDisabled ? [] : [
          <Button key="submit" type="primary" onClick={onSaveModal}>
            {finishButtonName}
          </Button>]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{
            display: 'flex', flexDirection: 'row', fontWeight: 'bold', border: '1px solid',
          }}
          >
            <div style={{ width: '5%', padding: '5px' }}>№</div>
            <div style={{
              width: '80%', padding: '5px', borderLeft: '1px solid', borderRight: '1px solid',
            }}
            >
              Порядок проверки поданной заявки.
            </div>
            <div style={{ width: '15%', padding: '5px' }}>Соответствие</div>
          </div>
          {checkListRowFields.map((field) => (
            <CheckListRow
              {...field}
              onCheckBoxChange={(e) => onCheckBoxChange(e, field.fieldName)}
              onCommentChange={(e) => onCommentChange(e, field.fieldName)}
              isError={errorFields.includes(field.fieldName)}
              application={application}
              isDisabled={isDisabled}
            />
          ))}
        </div>
      </Modal>
      {isShowConfirmDeclineModal
          && (
          <Modal
            visible
            width="50vw"
            maskClosable={false}
            onCancel={() => setIsShowConfirmDeclineModal(false)}
            title={finishButtonName === 'Направить в архив'
              ? 'Вы действительно хотите отправить Заявку в архив?'
              : 'Вы действительно хотите отправить Заявку на доработку?'}
            footer={[
              <Button key="submit" type="primary" onClick={() => onSave({ ...object, status: finishButtonName === 'Направить в архив' ? 'В архиве' : 'На доработке' }, comments)}>
                {finishButtonName}
              </Button>,
              <Button key="cancel" onClick={() => setIsShowConfirmDeclineModal(false)}>
                Отмена
              </Button>]}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {finishButtonName === 'Направить в архив'
                  ? `Заявка №${application.displayID} от ${application.sendOnReviewDate} направлена в архив по следующим причине(-ам):`
                  : `Заявка №${application.displayID} от ${application.sendOnReviewDate} направлена на доработку по следующим причине(-ам):`}
              </div>
              {comments.map((comment, index) => (
                <div
                  key={comment}
                  style={{
                    whiteSpace: 'break-spaces', width: '100%', display: 'flex', flexDirection: 'row',
                  }}
                >
                  <div style={{ marginRight: '5px' }}>{`${index + 1}.`}</div>
                  <div>{comment}</div>
                </div>
              ))}
              <div style={{ fontWeight: 'bold' }}>
                {finishButtonName === 'Направить в архив'
                  ? `Важно: Повторные правки Заявки №${application.displayID} от ${application.sendOnReviewDate} более недопустимы!`
                  : `Важно: Внести изменения в зявку №${application.displayID} от ${application.sendOnReviewDate} возможно только один раз`}
              </div>
            </div>
          </Modal>
          )}
    </>
  );
}

CheckListModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  application: PropTypes.shape().isRequired,
};

export default CheckListModal;
