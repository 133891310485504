import React, { useState, useEffect } from 'react';
import {
  Table, message, Input, Pagination, Select,
} from 'antd';
import { getPendingUsers, editUser } from '../../../../../../utils/http';
import './RegApplications.css';
import getRegApplicationsColumns from '../../utils/getRegApplicationsColumns';


const { Search } = Input;
const { Option } = Select;
function RegApplications() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDatasource] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 0, tableSize: 0 });
  const [searchData, setSearchData] = useState({});

  const onGetData = (newSearchData) => {
    getPendingUsers(newSearchData)
      .then((resp) => {
        setPagination(resp.pageInfo);
        setDatasource(resp.data);
        setSearchData(newSearchData);
        setLoading(false);
      });
  };

  useEffect(() => {
    onGetData({
      pageSize: 30, page: 0, name: null, isPending: null, isBlocked: null,
    });
  }, []);

  const onDecide = (isApproved, id, isDuplicate, isDzm) => {
    setLoading(true);
    const obj = {
      id,
      isPending: false,
      isBlocked: !isApproved,
      isDuplicate,
      isDzm,
    };
    editUser(obj)
      .then(() => {
        if (!isApproved) {
          message.success('Отказное письмо успешно направлено на электронный адрес заявителя');
        }
        if (isApproved) {
          message.success('Письмо-согласование успешно направлено на электронный адрес заявителя');
        }
        setLoading(false);
        setDatasource(dataSource.map((d) => (d.id === id ? ({
          ...d, isPending: false, isBlocked: !isApproved, isDzm,
        }) : d)));
      });
  };

  const onChangePage = (e) => {
    onGetData({ ...searchData, page: e });
  };

  const onSearch = (e) => {
    onGetData({ ...searchData, name: e, page: 0 });
  };

  const onFilter = (value) => {
    if (value === 'На согласовании') {
      onGetData({
        ...searchData, isPending: true, isBlocked: null, page: 0,
      });
    } else if (value === 'Отклонено') {
      onGetData({
        ...searchData, isPending: false, isBlocked: true, page: 0,
      });
    } else if (value === 'Согласовано') {
      onGetData({
        ...searchData, isPending: false, isBlocked: false, page: 0,
      });
    } else {
      onGetData({
        ...searchData, isPending: null, isBlocked: null, page: 0,
      });
    }
  };

  return (
    <div className="regApplications-wrapper">
      <div
        className="regApplications-box-wrapper"
        style={{
          height: '5vh', display: 'flex', flexDirection: 'row', gap: '20px',
        }}
      >
        <Search
          placeholder="Поиск по организации"
          style={{ width: '70%' }}
          onSearch={(e) => onSearch(e)}
        />
        <Select
          allowClear
          style={{ width: '30%' }}
          disabled={loading}
          onChange={onFilter}
          placeholder="Выберите статус..."
        >
          {['На согласовании', 'Отклонено', 'Согласовано'].map((u) => <Option key={u} value={u}>{u}</Option>)}
        </Select>
      </div>
      <div className="regApplications-box-wrapper">
        <Table
          dataSource={dataSource}
          columns={getRegApplicationsColumns(onDecide)}
          loading={loading}
          scroll={{ y: '50vh' }}
          pagination={false}
          rowKey="id"
        />
      </div>
      <Pagination
        defaultCurrent={pagination.currentPage + 1}
        current={searchData.page + 1}
        total={pagination.tableSize}
        defaultPageSize={30}
        hideOnSinglePage
        showSizeChanger={false}
        style={{ marginTop: '20px', marginLeft: '20px' }}
        onChange={onChangePage}
      />
    </div>
  );
}

export default RegApplications;
