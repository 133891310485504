/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Radio,
  Checkbox,
  notification,
  DatePicker,
  Modal,
  Popconfirm,
} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import getUserRole from '../../../../utils/localeStorage/getUserRole';
import {
  additionalFiles, checkBoxOptions, radioOptions, validateForm,
} from './consts';
import ProjectTextArea from './ProjectTextArea';
import fileDownload from '../../../../utils/fileDownload';


const { TextArea } = Input;
function ProjectBody({
  application, disabled, onSave, onPrint, onUpload, isShowSuccessNotification, onClearSopd, onDeleteProject,
}) {
  const formRef = useRef();
  const [isInit, setIsInit] = useState(true);
  const [isShowOtherState, setIsShowOtherState] = useState(false);
  const [isShowModal, setIsShowModal] = useState(isShowSuccessNotification);
  const [formValues, setFormValues] = useState(application);

  const isDZM = getUserRole() === 'expertDZM';
  const isAdmin = getUserRole() === 'bigAdmin';

  const DELAY_ML = 3000;

  useEffect(() => {
    setIsShowOtherState(application.isValueOtherText);
  }, []);

  const onCheckBoxChange = (value, field) => {
    if (field === 'isValueOther') {
      setIsShowOtherState(value);
      if (!value) {
        formRef.current.setFieldsValue({ isValueOtherText: '' });
      }
    }
  };

  const save = () => {
    const values = formRef.current.getFieldsValue();
    onSave(values, false);
  };

  useEffect(() => {
    if (isInit) {
      setIsInit(false);
    } else {
      const autoSaveTimer = setTimeout(save, DELAY_ML);
      return () => clearTimeout(autoSaveTimer);
    }
  }, [formValues]);

  const print = () => {
    const values = formRef.current.getFieldsValue();
    const errorFields = validateForm(values, isDZM);
    if (errorFields.length > 0) {
      notification.error({ message: 'Экспертное заключение не может быть распечатано по причине:', description: <div>{errorFields.map((field) => <p>{field}</p>)}</div> });
      return;
    }
    onSave(values, true);
    onPrint();
  };

  const addFilesToFolder = async (files, folder) => {
    // eslint-disable-next-line no-unused-vars
    for (const [index, fileObj] of files.entries()) {
      const file = await fetch(fileObj.urlLink)
        .then((r) => r.blob()).then((blobFile) => new File([blobFile], fileObj.name, { type: blobFile.type }));
      folder.file(`${fileObj.name}.pdf`, file, { base64: true });
    }
  };

  const onDownloadAllFiles = async () => {
    const parentZip = new JSZip();
    const files = additionalFiles.map((file) => {
      const urlLink = application[file.field]?.urlLink;
      const { name } = file;
      return { urlLink, name };
    });
    await addFilesToFolder(files, parentZip);

    parentZip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `Файлы заявки №${application.displayID}.zip`);
    });
  };

  return (
    <div style={{ zIndex: 1 }}>

      <Modal title="Благодарим Вас!" open={isShowModal} footer={[<Button type="primary" onClick={() => setIsShowModal(false)}>Перейти к экспертизе</Button>]} maskClosable={false}>
        <p>
          Информируем, что регламентированный срок формирования экспертного заключения по Проекту -
          <b> 14 календарных дней</b>
        </p>
      </Modal>

      <Form ref={formRef} onValuesChange={(e) => setFormValues(e)}>
        <div className="application-main-wrapper">
          <div className="application-main-box-wrapper" style={{ marginBottom: '20px' }}>
            <div className="application-main-box-mask" />
            <div className="application-main-box-content-wrapper">
              <div style={{ zIndex: 10, display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '20px' }}>Файлы проекта</div>
                <Button type="primary" onClick={onDownloadAllFiles} style={{ marginRight: '10px' }}>Скачать все</Button>
              </div>
              {application && additionalFiles.map((file) => (
                <Row style={{ padding: '10px 0px', width: '100%' }} justify="space-between" key={file.name}>
                  <Col><span style={{ fontSize: '16px' }}>{file.name}</span></Col>
                  <Col>
                    <Row gutter={15}>
                      <Col><Button type="primary" onClick={() => fileDownload(application[file.field]?.urlLink, application[file.field]?.name)} style={{ marginRight: '10px' }}>Скачать</Button></Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </div>

        <div className="application-main-wrapper">

          <div className="application-main-box-wrapper" style={{ marginBottom: '20px', paddingBottom: '40px' }}>
            <div className="application-main-box-mask" />

            <Form.Item
              name="id"
              initialValue={application.id}
              hidden
            />

            {isAdmin && (
            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Дата направления материалов Проекта:</span>
              <Form.Item
                name="materialDateText"
                initialValue={application.materialDateText}
              >
                <DatePicker disabled={disabled} />
              </Form.Item>
            </div>
            )}

            {isAdmin && (
            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">Дата формирования экспертного заключения:</span>
              <Form.Item
                name="conclusionDateText"
                initialValue={application.conclusionDateText}
              >
                <DatePicker disabled={disabled} autoSize />
              </Form.Item>
            </div>
            )}

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold', fontSize: '16px' }}>1. Информация о научно-практическом Проекте</span>
              <span className="application-main-box-label">1.1. Цель Проекта:</span>
              <Form.Item
                name="projectGoal"
                initialValue={application.projectGoal}
              >
                <TextArea disabled={!isAdmin} autoSize />
              </Form.Item>
            </div>

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">1.2. Задачи Проекта:</span>
              <Form.Item
                name="projectTasks"
                initialValue={application.projectTasks}
              >
                <TextArea disabled={!isAdmin} autoSize />
              </Form.Item>
            </div>

            <ProjectTextArea
              title="2. Актуальность и значимость Проекта для системы здравоохранения и научно-практическая новизна"
              label="2.1. Актуальность результатов Проекта"
              fieldName="relevance"
              placeholder="Минимальный объем - 700 знаков (с пробелами)"
              defaultValue={application.relevance}
              isShowCounter
              hint=""
            />

            <ProjectTextArea
              title=""
              label="2.2. Научная новизна и значимость полученных результатов Проекта для системы здравоохранения"
              fieldName="novelty"
              placeholder="Минимальный объем - 700 знаков (с пробелами)"
              defaultValue={application.novelty}
              isShowCounter
              hint=""
            />

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">2.3. Потенциал практического применения результатов Проекта в сфере здравоохранения и медицины</span>
              <span className="project-hint">
                Можно выбрать несколько вариантов
              </span>
              <Col style={{ border: '1px solid #212121' }}>
                {checkBoxOptions.map((option) => (
                  <Row style={{ borderBottom: '1px solid #212121' }} key={option.name}>
                    <Col className="project-cell-label" style={{ wordBreak: 'keep-all' }}>
                      {option.name}
                    </Col>
                    <Col className="project-cell-checkbox">
                      <Form.Item
                        valuePropName="checked"
                        name={option.field}
                        initialValue={application[option.field]}
                      >
                        <Checkbox style={{ width: 'fit-content' }} onChange={(e) => onCheckBoxChange(e.target.checked, option.field)} />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Col>
            </div>

            {isShowOtherState && (
            <div className="application-main-box-content-wrapper">
              <Form.Item
                name="isValueOtherText"
                initialValue={application.isValueOtherText}
              >
                <TextArea disabled={disabled} autoSize />
              </Form.Item>
            </div>
            )}

            <ProjectTextArea
              title=""
              label="Аргументация выбранного(-ых) варианта(-ов) практического(-их) потенциала(-ов) внедрения результатов Проекта:"
              fieldName="isValueComment"
              placeholder="Минимальный объем - 500 знаков (с пробелами)"
              defaultValue={application.isValueComment}
              isShowCounter
              hint=""
            />

            <ProjectTextArea
              title=""
              label="2.4. Потенциальное влияние Проекта на существующую клиническую практику в городе Москве и/или в других городах Российской Федерации"
              fieldName="influence"
              placeholder="Минимальный объем - 500 знаков (с пробелами)"
              defaultValue={application.influence}
              isShowCounter
              hint=""
            />

            {/* {(isDZM || isAdmin) && ( */}
            <ProjectTextArea
              title=""
              label="2.5. Потенциальная медико-экономическая эффективность Проекта"
              fieldName="effectiveness"
              placeholder="Минимальный объем - 700 знаков (с пробелами)"
              defaultValue={application.effectiveness}
              isShowCounter
              hint=""
            />
            {/* )} */}

            <ProjectTextArea
              title=""
              label="2.6. Действия, объективно необходимые для внедрения / масштабирования внедрения результатов Проекта в практическую деятельность со стороны грантополучателя и/или Департамента здравоохранения города Москвы"
              // label={`${isDZM ? '2.6.' : '2.5.'} Действия, объективно необходимые для внедрения / масштабирования внедрения результатов Проекта в практическую деятельность со стороны грантополучателя и/или Департамента здравоохранения города Москвы`}
              fieldName="actions"
              placeholder="Привести описание дальнейших действий, необходимых для внедрения / масштабирования внедрения результатов работ по Проекту в практическую деятельность"
              defaultValue={application.actions}
              isShowCounter={false}
              hint=""
            />

            {/* {(isDZM || isAdmin) && ( */}
            <ProjectTextArea
              title=""
              label="2.7. Инфраструктурная база, рекомендуемая для масштабирования Проекта"
              fieldName="infrastructure"
              placeholder="Минимальный объем - 100 знаков (с пробелами)"
              defaultValue={application.infrastructure}
              isShowCounter
              hint=""
            />
            {/* )} */}

            <ProjectTextArea
              title="3. Информация о достижении запланированных результатов Проекта"
              label="3.1. Соответствие достижения заявленных в Соглашении результатов и показателей эффективности Проекта"
              fieldName="accordance"
              placeholder="Минимальный объем - 100 знаков (с пробелами)"
              defaultValue={application.accordance}
              isShowCounter
              hint="Необходимо проанализировать соответствие фактически полученных по итогам выполнения Проекта показателей эффективности плановым значениям"
            />

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label">3.2. Была ли в ходе работ достигнута цель Проекта?</span>
              <Col>
                <Form.Item
                  initialValue={application.goalAchieved}
                  name="goalAchieved"
                  noStyle
                >
                  <Radio.Group disabled={disabled}>
                    <Row style={{ border: '1px solid #212121' }}>
                      <Col className="project-cell-radio">
                        <Radio value="Да">Да</Radio>
                      </Col>
                      <Col className="project-cell-radio">
                        <Radio value="Нет">Нет</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </div>

            <ProjectTextArea
              title=""
              label="3.3. Оценка выполнения Проекта и достижения целей: критерии и выводы"
              fieldName="evaluation"
              placeholder="Рекомендуемый объем - 2000 знаков (с пробелами)"
              defaultValue={application.evaluation}
              isShowCounter
              hint="Пожалуйста, предоставьте пояснение критериев, на основании которых был сделан вывод о достижении или недостижении цели Проекта, учитывая успешность и полноту выполнения плана работ, достижение заявленных результатов и значимость полученных данных"
            />

            {/* <ProjectTextArea
              title="4. Вывод о целесообразности / нецелесообразности реализации Проекта"
              label=""
              fieldName="expediency"
              placeholder="Минимальный объем - 500 знаков (с пробелами)"
              defaultValue={application.expediency}
              isShowCounter
              hint=""
            /> */}

            <div className="application-main-box-content-wrapper">
              <span className="application-main-box-label" style={{ fontWeight: 'bold', fontSize: '16px' }}>5. Интегральная оценка успешности реализации проекта</span>
              <Col style={{ marginBottom: 20 }}>
                <Form.Item
                  initialValue={application.totalResultText}
                  name="totalResultText"
                  noStyle
                >
                  <Radio.Group disabled={disabled}>
                    <Row style={{ border: '1px solid #212121' }}>
                      {radioOptions.map((radio) => (
                        <Col
                          key={radio.value}
                          className="project-cell-radio"
                        >
                          <Radio value={radio.value}>{radio.value}</Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </div>

            <Row
              style={{
                position: 'fixed', bottom: '60px', zIndex: 100, left: '60px',
              }}
              gutter={15}
            >
              <Col>
                <Button onClick={save} type="primary" style={{ backgroundColor: '#237804', borderColor: '#237804' }}>
                  Сохранить
                </Button>
              </Col>
              <Col>
                <Button onClick={print} icon={<PrinterOutlined />} style={{ backgroundColor: '#e3a134', color: '#fff' }}>
                  Распечатать экспертное заключение
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={onUpload}>
                  Загрузить подписанную скан-копию
                </Button>
              </Col>
              {isAdmin && (
              <Col>
                <Popconfirm
                  title="Вы уверены, что хотите удалить откатить согласие?"
                  onConfirm={onClearSopd}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="danger">
                    Откатить согласие
                  </Button>
                </Popconfirm>
              </Col>
              )}
              {isAdmin && (
              <Col>
                <Popconfirm
                  title="Вы уверены, что хотите удалить проект?"
                  onConfirm={onDeleteProject}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="danger">
                    Удалить проект
                  </Button>
                </Popconfirm>
              </Col>
              )}
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
}

ProjectBody.propTypes = {
  application: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  isShowSuccessNotification: PropTypes.bool.isRequired,
  onClearSopd: PropTypes.func.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
};

export default ProjectBody;
