/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import {
  Form, Input, Select, InputNumber, Upload, Col, Row,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined,
} from '@ant-design/icons';
// import ShortTabPeopleModule from './ShortTabPeopleModule';
import scientificDirectionCatalogue from './scientificDirectionCatalogue';
import './ShortTab.css';
import organisations from '../../organisations';
// https://api.directual.com/fileUploaded/medtechgrants/81736099-eb7a-4890-8d06-be8e2975a141.docx


const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;
function ShortTab({
  applicationProp, disabled, onAddFile, onDeleteFile, files, formRef,
}) {
  const [projectTerm, setProjectTerm] = useState(null);
  const [fieldLength, setFieldLength] = useState({ projectAnnotation: 0 });
  const [fieldValue, setFieldValue] = useState({ projectName: '' });
  const [selectedScientificDirection, setSelectedScientificDirection] = useState(applicationProp.scientificDirection);

  useEffect(() => {
    setProjectTerm(applicationProp.projectTerm);
    setFieldLength({ projectAnnotation: applicationProp.projectAnnotation.length });
    setFieldValue({ projectName: applicationProp.projectName });
  }, []);

  const setPublicationsTotal = () => {
    const formValues = formRef.current.getFieldsValue();
    formRef.current.setFieldsValue({
      numberOfPublicationsTotal:
      Number(formValues.numberOfPublicationsVAC) + Number(formValues.numberOfPublicationsRINC),
    });
  };

  const onChangeOrganisation = (e) => {
    const selectedOrg = organisations.find((i) => i.fullName === e);
    formRef.current.setFieldsValue({ organisationShortName: selectedOrg.shortName, organisationINN: selectedOrg.inn });
  };

  return (
    <div className="shortTab-wrapper">

      <Form.Item
        name="id"
        initialValue={applicationProp.id}
        hidden
      />

      <div className="common-subTitle">Сведения об организации-Заявителе</div>

      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '350px' }}>Полное наименование организации</div>
          <Form.Item
            style={{ width: '100%' }}
            name="organisationFullName"
            initialValue={applicationProp.organisationFullName}
          >
            <Select
              disabled={disabled}
              optionFilterProp="children"
              showSearch
              onSelect={onChangeOrganisation}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
              {organisations.map((u) => <Option key={u.fullName} value={u.fullName}>{u.fullName}</Option>)}
            </Select>
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '350px' }}>Сокращенное наименование организации</div>
          <Form.Item
            style={{ width: '100%' }}
            name="organisationShortName"
            initialValue={applicationProp.organisationShortName}
          >
            <TextArea autoSize disabled minrows={2} />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9', width: '350px' }}>ИНН</div>
          <Form.Item
            style={{ width: '100%' }}
            name="organisationINN"
            initialValue={applicationProp.organisationINN}
          >
            <Input disabled />
          </Form.Item>
        </div>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">Научное направление Проекта</span>
        <div className="common-field-hint">Выберете из выпадающего списка только одно научное направление - то, которому более всего соответствует тема деятельности по проекту (основная часть мероприятий проекта)</div>
        <Form.Item
          name="scientificDirection"
          initialValue={applicationProp.scientificDirection}
        >
          <Select
            disabled={disabled}
            optionFilterProp="children"
            showSearch
            onChange={(e) => setSelectedScientificDirection(e)}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
          >
            {scientificDirectionCatalogue.map((u) => <Option key={u} value={u}>{u}</Option>)}
          </Select>
        </Form.Item>

        {selectedScientificDirection === 'Иное' && (
        <Form.Item
          style={{ width: '100%' }}
          name="customScientificDirection"
          initialValue={applicationProp.customScientificDirection}
        >
          <Input disabled={disabled} placeholder="Введите наименование (не более 5 слов)" style={{ marginTop: '10px' }} />
        </Form.Item>
        )}
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">
          Название научно-практического Проекта в сфере медицины
        </span>
        <div className="common-field-hint">Название проекта не должно содержать более 25 слов, его необходимо написать без кавычек с заглавной буквы и без точки в конце. Из названия проекта должна быть понятна его суть</div>
        <Form.Item
          name="projectName"
          initialValue={applicationProp.projectName}
        >
          <TextArea
            autoSize
            disabled={disabled}
            style={{ borderColor: fieldValue.projectName.split(' ').filter((el) => el.length > 3).length > 25 ? 'red' : 'inherit' }}
            minrows={2}
            onChange={(e) => setFieldValue({ ...fieldValue, projectName: e.target.value ? e.target.value : '' })}
          />
        </Form.Item>
      </div>

      <div className="common-subTitle" style={{ fontSize: '20px' }}>Краткие сведения о Проекте</div>

      <div className="common-field-wrapper">
        <span className="common-field-label">Срок реализации Проекта</span>
        <Form.Item
          name="projectTerm"
          initialValue={applicationProp.projectTerm}
        >
          <Select
            disabled={disabled}
            onSelect={(e) => setProjectTerm(e)}
          >
            {[{ id: '1', value: '1 год' }, { id: '2', value: '2 года' }, { id: '3', value: '3 года' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
      </div>
      {projectTerm && (
      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div className="common-table-row-cell">Дата окончания Проекта</div>
          <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9' }}>Количество этапов</div>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>{projectTerm === '1' ? '20.12.2025' : projectTerm === '2' ? '20.12.2026' : '20.12.2027'}</div>
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9', borderRight: '1px solid #d9d9d9' }}>{projectTerm}</div>
        </div>
      </div>
      )}

      <div className="common-subTitle">Планируемые показатели эффективности Проекта</div>
      <div className="common-field-hint">В данном разделе указывается количественная информация о запланированных результатах выполнения проекта. Следует указывать реально выполнимые показатели, т.к. подписание соглашения о предоставлении гранта является автоматическим принятием организацией обязательств по их достижению.</div>
      <div className="common-field-hint">Если по какому-либо из показателей эффективности результат не ожидается, то в соответствующей графе следует поставить цифру ноль</div>
      <div className="common-field-hint" style={{ fontWeight: 'bold' }}>ВАЖНО! Все заявленные показатели эффективности проекта должны быть достигнуты в срок до даты окончания проекта.</div>
      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div style={{ width: '100%' }}>
            <div className="common-table-row-cell" style={{ width: '100%' }}>1. Общее число планируемых публикаций по результатам реализации Проекта</div>
            <div style={{ width: '100%', borderLeft: '1px solid #d9d9d9', padding: '0px 0px 4px 4px' }}>
              <div className="common-field-hint">
                Под публикациями подразумеваются только статьи, опубликованные в соответствующих научных журналах.
              </div>
              <div className="common-field-hint">
                В статьях обязательно необходимо будет указать на источник финансирования и номер гранта, полученного от Правительства Москвы.
              </div>
              <div className="common-field-hint" style={{ fontWeight: 'bold' }}>
                ВАЖНО! Заявленные статьи не могут быть опубликованы/приняты к печати   ранее даты подписания соглашения.
              </div>
            </div>
          </div>
          <div className="common-table-row-cell" style={{ width: '10%', borderRight: '1px solid #d9d9d9' }}>
            <Form.Item
              name="numberOfPublicationsTotal"
              initialValue={applicationProp.numberOfPublicationsTotal || 0}
            >
              <InputNumber disabled style={{ height: '100%' }} />
            </Form.Item>
          </div>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '100%' }}>1.1. в научных журналах перечня ВАК с Импакт-фактором > 0,5 (на момент публикации материала)</div>
          <div className="common-table-row-cell" style={{ width: '10%', borderRight: '1px solid #d9d9d9' }}>
            <Form.Item
              name="numberOfPublicationsVAC"
              initialValue={applicationProp.numberOfPublicationsVAC}
            >
              <InputNumber disabled={disabled} onChange={setPublicationsTotal} />
            </Form.Item>
          </div>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ width: '100%' }}>1.2. в научных журналах, индексируемых РИНЦ</div>
          <div className="common-table-row-cell" style={{ width: '10%', borderRight: '1px solid #d9d9d9' }}>
            <Form.Item
              name="numberOfPublicationsRINC"
              initialValue={applicationProp.numberOfPublicationsRINC}
            >
              <InputNumber disabled={disabled} onChange={setPublicationsTotal} />
            </Form.Item>
          </div>
        </div>
        <div className="common-table-row">
          <div style={{
            display: 'flex', flexDirection: 'column', width: '100%',
          }}
          >
            <div className="common-table-row-cell" style={{ width: '100%' }}>2. Число результатов интеллектуальной деятельности, планируемых к получению по результатам реализации Проекта</div>
            <div style={{ width: '100%', borderLeft: '1px solid #d9d9d9', padding: '0px 0px 4px 4px' }}>
              <div className="common-field-hint" style={{ width: '100%' }}>Результаты интеллектуальной деятельности (РИД) – это зарегистрированные надлежащим образом в Федеральном институте промышленной собственности (ФИПС) патенты и свидетельства о регистрации. Указывается количество патентов и/или зарегистрированных заявок на патенты, которые будут подготовлены по итогам выполнения проекта.</div>
            </div>
          </div>
          <div className="common-table-row-cell" style={{ width: '10%', borderRight: '1px solid #d9d9d9' }}>
            <Form.Item
              name="numberOfRID"
              initialValue={applicationProp.numberOfRID}
            >
              <InputNumber disabled={disabled} />
            </Form.Item>
          </div>
        </div>
        <div className="common-table-row">
          <div style={{
            display: 'flex', flexDirection: 'column', width: '100%',
          }}
          >
            <div className="common-table-row-cell" style={{ width: '100%' }}>3. Количество планируемых мероприятий по демонстрации результатов реализации Проекта</div>
            <div style={{ width: '100%', borderLeft: '1px solid #d9d9d9', padding: '0px 0px 4px 4px' }}>
              <div className="common-field-hint" style={{ width: '100%', padding: '0px' }}>Под мероприятиями подразумевается участие с докладами и/или постерными докладами, заочное участие на всероссийских и региональных конференциях, симпозиумах, форумах, выставках регионального и всероссийского уровня, семинарах, лекциях и т.д</div>
            </div>
          </div>
          <div className="common-table-row-cell" style={{ width: '10%', borderRight: '1px solid #d9d9d9' }}>
            <Form.Item
              name="numberOfEvents"
              initialValue={applicationProp.numberOfEvents}
            >
              <InputNumber disabled={disabled} />
            </Form.Item>
          </div>
        </div>
        {/* <div className="common-table-row">
          <div className="common-table-row-cell">2.2. Ожидаемая аудитория мероприятий по демонстрации результатов реализации Проекта из числа профессионального сообщества и представителей здравоохранения города Москвы</div>
          <Form.Item
            style={{ width: '100%' }}
            name="numberOfEventsPeople"
            initialValue={applicationProp.numberOfEventsPeople}
          >
            <InputNumber disabled={disabled} />
          </Form.Item>
        </div> */}
        <div className="common-table-row">
          <div style={{
            display: 'flex', flexDirection: 'column', width: '100%',
          }}
          >
            <div className="common-table-row-cell" style={{ width: '100%' }}>
              4. Количество планируемых выступлений с докладами на
              <b> международных</b>
              {' '}
              научно-практических конференциях, симпозиумах, форумах
            </div>
            <div style={{ width: '100%', borderLeft: '1px solid #d9d9d9', padding: '0px 0px 4px 4px' }}>
              <div className="common-field-hint" style={{ width: '100%', padding: '0px' }}>Статус международной конференции, симпозиума, форума определяется наличием в программе мероприятия участников минимум из двух государств. Мероприятия могут проходить как в России, так и за рубежом.</div>
            </div>
          </div>
          <div className="common-table-row-cell" style={{ width: '10%', borderRight: '1px solid #d9d9d9' }}>
            <Form.Item
              name="numberOfPerformance"
              initialValue={applicationProp.numberOfPerformance}
            >
              <InputNumber disabled={disabled} />
            </Form.Item>
          </div>
        </div>
        <div className="common-table-row">
          <div style={{
            borderBottom: '1px solid #d9d9d9', display: 'flex', flexDirection: 'column', width: '100%',
          }}
          >
            <div className="common-table-row-cell" style={{ width: '100%' }}>5. Общее количество пациентов, предусмотренных для выполнения Проекта</div>
            <div style={{ width: '100%', borderLeft: '1px solid #d9d9d9', padding: '0px 0px 4px 4px' }}>
              <div className="common-field-hint" style={{ width: '100%', padding: '0px 2px' }}>(при наличии, в ином случае проставляется «0»)</div>
            </div>
          </div>
          <div className="common-table-row-cell" style={{ width: '10%', borderRight: '1px solid #d9d9d9', borderBottom: '1px solid #d9d9d9' }}>
            <Form.Item
              name="numberOfPatienceTotal"
              initialValue={applicationProp.numberOfPatienceTotal}
            >
              <InputNumber disabled={disabled} />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">Аннотация Проекта (от 600 до 800 печатных знаков с пробелами)</span>
        <span className="common-field-hint">Кратко опишите цели проекта, содержание планируемых работ и ожидаемые результаты</span>
        <span className="common-field-hint" style={{ fontWeight: 'bold' }}>Это одно из самых важных полей заявки, поскольку изложенная здесь информация позволяет понять идею проекта и составить о нем общее представление для дальнейшего рассмотрения.</span>
        <Form.Item
          name="projectAnnotation"
          initialValue={applicationProp.projectAnnotation}
        >
          <TextArea
            style={{ borderColor: fieldLength.projectAnnotation === 800 ? 'red' : 'inherit' }}
            autoSize
            disabled={disabled}
            minrows={4}
            maxLength={800}
            onChange={(e) => setFieldLength({ ...fieldLength, projectAnnotation: e.target.value ? e.target.value.length : 0 })}
          />
        </Form.Item>
        <span className="common-field-hint" style={{ marginTop: '0px', color: fieldLength.projectAnnotation < 600 || fieldLength.projectAnnotation > 800 ? 'red' : 'green' }}>{`${fieldLength.projectAnnotation} из 800`}</span>
      </div>

      <div className="common-subTitle" style={{ fontSize: '18px' }}>Краткие сведения о научном руководителе Проекта и контактных лицах</div>
      <span className="common-field-hint">В целях оперативного решения вопросов по заявке и по Проекту в данном разделе указываются актуальные контактные данные лиц, участвующих в проекте.</span>
      <div className="common-subTitle">Сведения о научном руководителе Проекта</div>
      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div className="common-table-row-cell">ФИО</div>
          <Form.Item
            style={{ width: '100%' }}
            name="supervisorFullName"
            initialValue={applicationProp.supervisorFullName}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Должность</div>
          <Form.Item
            style={{ width: '100%' }}
            name="supervisorPosition"
            initialValue={applicationProp.supervisorPosition}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Ученая степень</div>
          <Form.Item
            style={{ width: '100%' }}
            name="supervisorDegree"
            initialValue={applicationProp.supervisorDegree}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Ученое звание</div>
          <Form.Item
            style={{ width: '100%' }}
            name="supervisorTitle"
            initialValue={applicationProp.supervisorTitle}
          >
            <Input disabled={disabled} placeholder="Введите текст. В случае отсутствия, пожалуйста, укажите слово 'нет'" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Телефон</div>
          <Form.Item
            style={{ width: '100%' }}
            name="supervisorPhone"
            initialValue={applicationProp.supervisorPhone}
          >
            <InputMask
              mask="8 (999) 999-99-99"
              maskChar=" "
              className="applicationStep2-phone-input"
              placeholder="8 (  ) - - (укажите мобильный номер телефона)"
              disabled={disabled}
              inputMode="numeric"
            />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
          <Form.Item
            style={{ width: '100%' }}
            name="supervisorEmail"
            initialValue={applicationProp.supervisorEmail}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
      </div>
      <div style={{ fontStyle: 'italic', marginTop: '-20px' }}>
        Скачайте, заполните и подпишите Согласие на обработку персональных данных. Шаблон доступен по
        {' '}
        <a href="https://api.directual.com/fileUploaded/medtechgrants/81736099-eb7a-4890-8d06-be8e2975a141.docx">ссылке</a>
      </div>
      <Dragger
        showUploadList={false}
        onChange={(file) => onAddFile(file, 'supervisorSOPDFiles_ids')}
        disabled={disabled}
        multiple
        action={null}
        accept=".pdf"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
      </Dragger>
      {files.supervisorSOPDFiles_ids.map((f) => (
        <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
          {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'supervisorSOPDFiles_ids')} /></Col>}
          <Col>{f.name}</Col>
        </Row>
      ))}

      <div className="common-subTitle" style={{ marginTop: '20px' }}>Сведения о руководителе организации-Заявителя</div>
      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div className="common-table-row-cell">ФИО</div>
          <Form.Item
            style={{ width: '100%' }}
            name="directorFullName"
            initialValue={applicationProp.directorFullName}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Должность</div>
          <Form.Item
            style={{ width: '100%' }}
            name="directorPosition"
            initialValue={applicationProp.directorPosition}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Телефон</div>
          <Form.Item
            style={{ width: '100%' }}
            name="directorPhone"
            initialValue={applicationProp.directorPhone}
          >
            <InputMask
              mask="8 (999) 999-99-99"
              maskChar=" "
              className="applicationStep2-phone-input"
              placeholder="8 (  ) - - "
              disabled={disabled}
              inputMode="numeric"
            />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
          <Form.Item
            style={{ width: '100%' }}
            name="directorEmail"
            initialValue={applicationProp.directorEmail}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
      </div>
      <div style={{ fontStyle: 'italic', marginTop: '-20px' }}>
        В случае указания мобильного номера телефона руководителя скачайте, заполните и подпишите Согласие на обработку персональных данных.
        Шаблон доступен по
        {' '}
        <a href="https://api.directual.com/fileUploaded/medtechgrants/81736099-eb7a-4890-8d06-be8e2975a141.docx">ссылке</a>
      </div>
      <Dragger
        showUploadList={false}
        onChange={(file) => onAddFile(file, 'directorSOPDFiles_ids')}
        disabled={disabled}
        multiple
        action={null}
        accept=".pdf"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
      </Dragger>
      {files.directorSOPDFiles_ids?.map((f) => (
        <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
          {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'directorSOPDFiles_ids')} /></Col>}
          <Col>{f.name}</Col>
        </Row>
      ))}

      <div className="common-subTitle" style={{ marginTop: '20px' }}>Сведения об ответственном контактном лице по заявке и Проекту (по общим вопросам)</div>
      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div className="common-table-row-cell">ФИО</div>
          <Form.Item
            style={{ width: '100%' }}
            name="contactCommonFullName"
            initialValue={applicationProp.contactCommonFullName}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Должность</div>
          <Form.Item
            style={{ width: '100%' }}
            name="contactCommonPosition"
            initialValue={applicationProp.contactCommonPosition}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Телефон</div>
          <Form.Item
            style={{ width: '100%' }}
            name="contactCommonPhone"
            initialValue={applicationProp.contactCommonPhone}
          >
            <InputMask
              mask="8 (999) 999-99-99"
              maskChar=" "
              className="applicationStep2-phone-input"
              placeholder="8 (  ) - - (укажите мобильный номер телефона)"
              disabled={disabled}
              inputMode="numeric"
            />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
          <Form.Item
            style={{ width: '100%' }}
            name="contactCommonEmail"
            initialValue={applicationProp.contactCommonEmail}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
      </div>
      <div style={{ fontStyle: 'italic', marginTop: '-20px' }}>
        Скачайте, заполните и подпишите Согласие на обработку персональных данных. Шаблон доступен по
        {' '}
        <a href="https://api.directual.com/fileUploaded/medtechgrants/81736099-eb7a-4890-8d06-be8e2975a141.docx">ссылке</a>
      </div>
      <Dragger
        showUploadList={false}
        onChange={(file) => onAddFile(file, 'contactCommonSOPDFiles_ids')}
        disabled={disabled}
        multiple
        action={null}
        accept=".pdf"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
      </Dragger>
      {files.contactCommonSOPDFiles_ids?.map((f) => (
        <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
          {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'contactCommonSOPDFiles_ids')} /></Col>}
          <Col>{f.name}</Col>
        </Row>
      ))}

      <div className="common-subTitle" style={{ marginTop: '20px' }}>Сведения об ответственном контактном лице, отвечающем за предоставление финансовых данных по заявке и Проекту (смета, финансовый отчет)</div>
      <div className="common-table-wrapper">
        <div className="common-table-row">
          <div className="common-table-row-cell">ФИО</div>
          <Form.Item
            style={{ width: '100%' }}
            name="contactFinanceFullName"
            initialValue={applicationProp.contactFinanceFullName}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Должность</div>
          <Form.Item
            style={{ width: '100%' }}
            name="contactFinancePosition"
            initialValue={applicationProp.contactFinancePosition}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell">Телефон</div>
          <Form.Item
            style={{ width: '100%' }}
            name="contactFinancePhone"
            initialValue={applicationProp.contactFinancePhone}
          >
            <InputMask
              mask="8 (999) 999-99-99"
              maskChar=" "
              className="applicationStep2-phone-input"
              placeholder="8 (  ) - - (укажите мобильный номер телефона)"
              disabled={disabled}
              inputMode="numeric"
            />
          </Form.Item>
        </div>
        <div className="common-table-row">
          <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
          <Form.Item
            style={{ width: '100%' }}
            name="contactFinanceEmail"
            initialValue={applicationProp.contactFinanceEmail}
          >
            <Input disabled={disabled} placeholder="Введите текст" />
          </Form.Item>
        </div>
      </div>
      <div style={{ fontStyle: 'italic', marginTop: '-20px' }}>
        Скачайте, заполните и подпишите Согласие на обработку персональных данных. Шаблон доступен по
        {' '}
        <a href="https://api.directual.com/fileUploaded/medtechgrants/81736099-eb7a-4890-8d06-be8e2975a141.docx">ссылке</a>
      </div>
      <Dragger
        showUploadList={false}
        onChange={(file) => onAddFile(file, 'contactFinanceSOPDFiles_ids')}
        disabled={disabled}
        multiple
        action={null}
        accept=".pdf"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
      </Dragger>
      {files.contactFinanceSOPDFiles_ids?.map((f) => (
        <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
          <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
          {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'contactFinanceSOPDFiles_ids')} /></Col>}
          <Col>{f.name}</Col>
        </Row>
      ))}
      {/* <ShortTabPeopleModule
        personsParticipateProp={applicationProp.applications2ShortTabPeople_ids || []}
        applicationId={applicationProp?.id}
        disabled={disabled}
        updateOriginalObject={updateOriginalObject}
      /> */}
      <div className="common-subTitle" style={{ fontSize: '18px', marginTop: '20px' }}>Предусмотрены ли в Проекте научно-исследовательские работы, которые будут выполнять организации-Соисполнители?</div>

      <div className="common-field-wrapper">
        <Form.Item
          name="isPersonsParticipate"
          initialValue={applicationProp.isPersonsParticipate}
        >
          <Select
            disabled={disabled}
          >
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
      </div>
      {/* {isPersonsParticipate && isPersonsParticipate === 'Нет' && <div>В рамках реализации Проекта не предусмотрено выполнение НИР соисполнителями.</div>}
      {isPersonsParticipate && isPersonsParticipate === 'Да'
      && (
      <ShortTabPeopleModule
        personsParticipateProp={applicationProp.applications2ShortTabPeople_ids}
        applicationId={applicationProp.id}
        disabled={disabled}
        updateOriginalObject={updateOriginalObject}
      />
      )} */}

    </div>
  );
}

ShortTab.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  files: PropTypes.shape().isRequired,
  formRef: PropTypes.shape().isRequired,
  // updateOriginalObject: PropTypes.func.isRequired,
};

export default ShortTab;
