/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Checkbox, Modal, Select, Upload, InputNumber,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined,
} from '@ant-design/icons';
import { uploadFile, editApplications2PersonsTabPeople, editApplications2 } from '../../../../utils/http';


const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;
const rowsCatalogue = [
  {
    number: 1,
    period: 'С даты заключения Договора до 30.06.2025 г.',
  },
  {
    number: 2,
    period: 'С 30.06.2025 до 20.12.2025 г.',
  },
  {
    number: 3,
    period: 'С 10.01.2026 до 30.06.2026 г.',
  },
  {
    number: 4,
    period: 'С 01.07.2026 до 20.12.2026 г.',
  },
  {
    number: 5,
    period: 'С 10.01.2027 до 30.06.2027 г.',
  },
  {
    number: 6,
    period: 'С 01.07.2027 до 20.12.2027 г.',
  },
];
const fieldsWithFiles = ['directorSOPDFiles_ids', 'contactCommonSOPDFiles_ids'];
function PersonsTabPeopleModule({
  elementsArrayProp, applicationId, disabled, projectTerm, updateOriginalObject, updateOriginalObjectMass, applicationProp,
}) {
  const [elementsArray, setElementsArray] = useState([]);
  const [objectInEditId, setObjectInEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const formRef = useRef(null);
  const [files, setFiles] = useState({});
  const [confirmEmail, setConfirmEmail] = useState(null);
  const [isMoscow, setIsMoscow] = useState(null);
  const [stepFieldsDisabled, setStepFieldsDisabled] = useState({
    isStep1: true,
    isStep2: true,
    isStep3: true,
    isStep4: true,
    isStep5: true,
    isStep6: true,
  });
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const rows = [];

  useEffect(() => {
    setElementsArray(elementsArrayProp);
    setIsFormDisabled(disabled);
  }, []);

  const onEditClick = (p) => {
    const filesObj = {};
    fieldsWithFiles.forEach((field) => { filesObj[field] = p[field]; });
    setFiles(filesObj);
    setIsMoscow(p.isMoscow);
    setObjectInEditId(p.id);
    setConfirmEmail(p.confirmEmail_id);
    setStepFieldsDisabled({
      isStep1: !p.isStep1,
      isStep2: !p.isStep2,
      isStep3: !p.isStep3,
      isStep4: !p.isStep4,
      isStep5: !p.isStep5,
      isStep6: !p.isStep6,
    });
    if (formRef.current) {
      formRef.current.setFieldsValue({ stepTotal: p.stepTotal });
    }
  };


  const updateFinance = (updatedArray) => {
    let peopleTotal = 0;
    let people2023 = 0;
    let people2024 = 0;
    let people2025 = 0;

    const years = ['2023', '2024', '2025'];
    updatedArray.forEach((person) => {
      years.forEach((year) => {
        if (year === '2023') {
          people2023 += (person.step1Sum || 0) + (person.step2Sum || 0);
        }
        if (year === '2024') {
          people2024 += (person.step3Sum || 0) + (person.step4Sum || 0);
        }
        if (year === '2025') {
          people2025 += (person.step5Sum || 0) + (person.step6Sum || 0);
        }
      });
    });
    people2023 *= 100;
    people2024 *= 100;
    people2025 *= 100;
    peopleTotal = people2023 + people2024 + people2025;
    const objectToUpdate = {
      peopleTotalMajor: Math.trunc(peopleTotal / 100) === 0 && peopleTotal < 0 ? `-0${Math.trunc(peopleTotal / 100)}` : Math.trunc(peopleTotal / 100),
      peopleTotalMinor: Number((peopleTotal % 100).toFixed(0)),
      people2023Major: Math.trunc(people2023 / 100) === 0 && people2023 < 0 ? `-0${Math.trunc(people2023 / 100)}` : Math.trunc(people2023 / 100),
      people2023Minor: Number((people2023 % 100).toFixed(0)),
      people2024Major: Math.trunc(people2024 / 100) === 0 && people2024 < 0 ? `-0${Math.trunc(people2024 / 100)}` : Math.trunc(people2024 / 100),
      people2024Minor: Number((people2024 % 100).toFixed(0)),
      people2025Major: Math.trunc(people2025 / 100) === 0 && people2025 < 0 ? `-0${Math.trunc(people2025 / 100)}` : Math.trunc(people2025 / 100),
      people2025Minor: Number((people2025 % 100).toFixed(0)),
    };

    const financeSum = parseFloat(`${applicationProp.fotTotalMajor || 0}.${(`${applicationProp.fotTotalMinor}`).length === 1 ? `0${applicationProp.fotTotalMinor}` : applicationProp.fotTotalMinor}`) * 100
    + parseFloat(`${applicationProp.materialTotalMajor || 0}.${(`${applicationProp.materialTotalMinor}`).length === 1 ? `0${applicationProp.materialTotalMinor}` : applicationProp.materialTotalMinor}`) * 100
    + parseFloat(`${applicationProp.equipmentTotalMajor || 0}.${(`${applicationProp.equipmentTotalMinor}`).length === 1 ? `0${applicationProp.equipmentTotalMinor}` : applicationProp.equipmentTotalMinor}`) * 100
    + parseFloat(`${applicationProp.otherTotalMajor || 0}.${(`${applicationProp.otherTotalMinor}`).length === 1 ? `0${applicationProp.otherTotalMinor}` : applicationProp.otherTotalMinor}`) * 100
    + parseFloat(`${applicationProp.paperTotalMajor || 0}.${(`${applicationProp.paperTotalMinor}`).length === 1 ? `0${applicationProp.paperTotalMinor}` : applicationProp.paperTotalMinor}`) * 100
    + parseFloat(`${objectToUpdate.peopleTotalMajor || 0}.${(`${objectToUpdate.peopleTotalMinor}`).length === 1 ? `0${objectToUpdate.peopleTotalMinor}` : objectToUpdate.peopleTotalMinor}`) * 100;

    objectToUpdate.financeTotalMajor = Math.trunc(financeSum / 100) === 0 && financeSum < 0 ? `-0${Math.trunc(financeSum / 100)}` : Math.trunc(financeSum / 100);
    objectToUpdate.financeTotalMinor = Number((financeSum % 100).toFixed(0));
    const fields = ['fot', 'material', 'equipment', 'other', 'paper'];
    fields.forEach((f) => {
      objectToUpdate[`${f}Percent`] = ((applicationProp[`${f}TotalMajor`] / objectToUpdate.financeTotalMajor) * 100 || 0).toFixed(2);
    });
    objectToUpdate.peoplePercent = ((objectToUpdate.peopleTotalMajor / objectToUpdate.financeTotalMajor) * 100 || 0).toFixed(2);

    editApplications2({ id: applicationId, ...objectToUpdate })
      .then(() => {
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObjectMass({ ...objectToUpdate, applications2PersonsTabPeople_ids: updatedArray });
        setObjectInEditId(null);
        notification.success({ message: 'Успешно' });
      });
  };

  const onAddClick = () => {
    setLoading(true);
    editApplications2PersonsTabPeople({ applications2_id: applicationId })
      .then((id) => {
        setObjectInEditId(id);
        const updatedArray = [...elementsArray, { id }];
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject('applications2PersonsTabPeople_ids', updatedArray);
      });
  };

  const onDeleteClick = (id) => {
    setLoading(true);
    editApplications2PersonsTabPeople({ id, isDeleted: true })
      .then(() => {
        const updatedArray = elementsArray.filter((p) => p.id !== id);
        updateFinance(updatedArray);
        updateOriginalObject('applications2PersonsTabPeople_ids', updatedArray);
      });
  };

  const onCancelClick = () => {
    const values = formRef.current.getFieldsValue();
    const valuesKeys = Object.keys(values);
    let isEmpty = true;
    valuesKeys.forEach((k) => {
      if (values[k]) {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      onDeleteClick(objectInEditId);
    } else {
      setObjectInEditId(null);
    }
  };

  const onSaveClick = () => {
    const values = formRef.current.getFieldsValue();

    setLoading(true);
    const filesToSave = {};
    fieldsWithFiles.forEach((field) => { filesToSave[field] = files[field]?.map((f) => f.id).join(','); });
    editApplications2PersonsTabPeople({
      id: objectInEditId,
      ...values,
      ...filesToSave,
      confirmEmail_id: confirmEmail?.id,
    })
      .then(() => {
        const updatedArray = elementsArray.map((p) => (p.id === objectInEditId ? {
          id: objectInEditId,
          ...values,
          ...files,
          confirmEmail_id: confirmEmail,
        } : p));
        updateFinance(updatedArray);
        updateOriginalObject('applications2PersonsTabPeople_ids', updatedArray);
      });
  };

  const onExpand = (p) => {
    setIsMoscow(p.isMoscow);
    setObjectInEditId(p.id);
    setConfirmEmail(p.confirmEmail_id);
    setIsExpanded(true);
    setIsFormDisabled(true);
  };

  const onCollapse = () => {
    setObjectInEditId(null);
    setIsExpanded(false);
    setIsFormDisabled(disabled);
  };

  const onRemoveFile = () => {
    setConfirmEmail(null);
    const form = new FormData();
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    form.append('file', {});
    uploadFile(form, headers)
      .then((file) => setConfirmEmail(file));
  };

  const onUploadFile = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => setConfirmEmail(file));
    }
  };

  const onCheckBoxChange = (fieldNumber, e) => {
    if (!e.target.checked) {
      formRef.current.setFieldsValue({ [`step${fieldNumber}Work`]: '', [`step${fieldNumber}Result`]: '', [`step${fieldNumber}Sum`]: '' });
      setStepFieldsDisabled({ ...stepFieldsDisabled, [`isStep${fieldNumber}`]: true });
    } else {
      setStepFieldsDisabled({ ...stepFieldsDisabled, [`isStep${fieldNumber}`]: false });
    }
  };

  const onChangeSum = () => {
    const values = formRef.current.getFieldsValue();
    const totalSum = (values.step1Sum || 0) + (values.step2Sum || 0) + (values.step3Sum || 0) + (values.step4Sum || 0) + (values.step5Sum || 0) + (values.step6Sum || 0);
    formRef.current.setFieldsValue({ stepTotal: totalSum });
  };

  const onDeleteFile = (file, field) => setFiles({ ...files, [field]: files[field].filter((f) => f.id !== file.id) });

  const onAddFile = (info, field) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          setFiles({ ...files, [field]: files[field] ? [...files[field], file] : [file] });
        });
    }
  };


  // if (projectTerm === '1' || !stepFieldsDisabled.isStep1) {
  //   rows.push({
  //     number: '1',
  //     period: 'С даты заключения Договора до 20.12.2023 г.',
  //   });
  // }
  for (let i = 1; i < 7; i += 1) {
    if (!stepFieldsDisabled[`isStep${i}`]) {
      rows.push(rowsCatalogue.find((c) => c.number === i));
    }
  }

  // const isShowBlock = isMoscow === 'Нет';
  const isShowBlock = true;
  return (
    <div
      style={{
        margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
      }}
      className="personsTabPeopleModule-wrapper"
    >
      <span style={{ fontSize: '24px', color: '#212121' }}>Соисполнители</span>
      {elementsArray.map((p, i) => (
        <Card
          key={p.id}
          style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
          bodyStyle={{
            borderLeft: objectInEditId === p.id ? '4px solid #1890FF' : '',
            borderRadius: objectInEditId === p.id ? '5px' : '',
          }}
        >
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                {p.fullName || `Соисполнитель №${i + 1}`}
              </Col>
              <Col>
                <Row align="middle" gutter={10}>
                  {!objectInEditId && (<Col><Button type="primary" onClick={() => onEditClick(p)} disabled={isFormDisabled}>Редактировать</Button></Col>)}
                  {!objectInEditId && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить Соисполнителя?"
                        onConfirm={() => onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger" disabled={isFormDisabled}>Удалить</Button>
                      </Popconfirm>
                    </Col>
                  )}
                  {!objectInEditId && (<Col><Button onClick={() => onExpand(p)}>Показать</Button></Col>)}
                </Row>
              </Col>
            </Row>
            {objectInEditId === p.id
                && (
                  <Modal title="Редактирование соисполнителя" visible footer={null} width="90vw" maskClosable={false} closable={false} className="personsTabPeopleModule-modal-wrapper">
                    <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row>
                    <Form initialValues={{ remember: true }} ref={formRef} style={{ marginTop: '20px' }}>
                      <Col>

                        <div className="common-table-wrapper">
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Вид организации</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="type"
                              initialValue={p.type}
                            >
                              <Select disabled={isFormDisabled}>
                                {[{ id: 'Университет', value: 'Университет' },
                                  { id: 'Поликлиника/больница', value: 'Поликлиника/больница' },
                                  { id: 'Юридическое лицо (ООО, ЗАО, ОАО и прочее)', value: 'Юридическое лицо (ООО, ЗАО, ОАО и прочее)' },
                                  { id: 'Иное', value: 'Иное' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Организация-Соисполнитель подведомственна Департаменту здравоохранения г. Москвы</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="isMoscow"
                              initialValue={p.isMoscow}
                            >
                              <Select disabled={isFormDisabled} onChange={setIsMoscow}>
                                {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Полное наименование организации (в соответствии с учредительными документами)</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="fullName"
                              initialValue={p.fullName}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Сокращенное наименование организации</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="shortName"
                              initialValue={p.shortName}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">ОГРН</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="ogrn"
                              initialValue={p.ogrn}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">ИНН</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="inn"
                              initialValue={p.inn}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>Юридический адрес</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="legalAddress"
                              initialValue={p.legalAddress}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="common-subTitle">Сведения о руководителе организации-Соисполнителя</div>
                        <div className="common-table-wrapper">
                          <div className="common-table-row">
                            <div className="common-table-row-cell">ФИО</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="directorFullName"
                              initialValue={p.directorFullName}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Должность</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="directorPosition"
                              initialValue={p.directorPosition}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Телефон</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="directorPhone"
                              initialValue={p.directorPhone}
                            >
                              <InputMask
                                mask="8 (999) 999-99-99"
                                maskChar=" "
                                className="applicationStep2-phone-input"
                                placeholder="8 (  ) - -"
                                disabled={disabled}
                                inputMode="numeric"
                              />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="directorEmail"
                              initialValue={p.directorEmail}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                        </div>
                        <div style={{ fontStyle: 'italic', marginTop: '-20px' }}>
                          В случае указания мобильного номера телефона руководителя скачайте, заполните и подпишите Согласие на обработку персональных данных. Шаблон доступен по
                          {' '}
                          <a href="https://api.directual.com/fileUploaded/medtechgrants/81736099-eb7a-4890-8d06-be8e2975a141.docx">ссылке</a>
                        </div>
                        <Dragger
                          showUploadList={false}
                          onChange={(file) => onAddFile(file, 'directorSOPDFiles_ids')}
                          disabled={disabled}
                          multiple
                          action={null}
                          accept=".pdf"
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                        </Dragger>
                        {files.directorSOPDFiles_ids?.map((f) => (
                          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
                            {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'directorSOPDFiles_ids')} /></Col>}
                            <Col>{f.name}</Col>
                          </Row>
                        ))}


                        <div className="common-subTitle">Сведения об ответственном контактном лице от Соисполнителя</div>
                        <div className="common-table-wrapper">
                          <div className="common-table-row">
                            <div className="common-table-row-cell">ФИО</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="contactCommonFullName"
                              initialValue={p.contactCommonFullName}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Должность</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="contactCommonPosition"
                              initialValue={p.contactCommonPosition}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Телефон</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="contactCommonPhone"
                              initialValue={p.contactCommonPhone}
                            >
                              <InputMask
                                mask="8 (999) 999-99-99"
                                maskChar=" "
                                className="applicationStep2-phone-input"
                                placeholder="8 (  ) - - (укажите мобильный номер телефона)"
                                disabled={disabled}
                                inputMode="numeric"
                              />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>E-mail</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="contactCommonEmail"
                              initialValue={p.contactCommonEmail}
                            >
                              <TextArea disabled={isFormDisabled} placeholder="Введите текст" />
                            </Form.Item>
                          </div>
                        </div>
                        <div style={{ fontStyle: 'italic', marginTop: '-20px' }}>
                          Скачайте, заполните и подпишите Согласие на обработку персональных данных. Шаблон доступен по
                          {' '}
                          <a href="https://api.directual.com/fileUploaded/medtechgrants/81736099-eb7a-4890-8d06-be8e2975a141.docx">ссылке</a>
                        </div>
                        <Dragger
                          showUploadList={false}
                          onChange={(file) => onAddFile(file, 'contactCommonSOPDFiles_ids')}
                          disabled={disabled}
                          multiple
                          action={null}
                          accept=".pdf"
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                        </Dragger>
                        {files.contactCommonSOPDFiles_ids?.map((f) => (
                          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
                            {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'contactCommonSOPDFiles_ids')} /></Col>}
                            <Col>{f.name}</Col>
                          </Row>
                        ))}

                        <div className="common-field-wrapper">
                          <span className="common-field-label">4.1. Тема научно-исследовательских работ (НИР) Соисполнителя:</span>
                          <Form.Item
                            name="theme"
                            initialValue={p.theme}
                          >
                            <TextArea autoSize disabled={isFormDisabled} placeholder="Введите текст" />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">4.2. Цель выполнения НИР</span>
                          <Form.Item
                            name="goal"
                            initialValue={p.goal}
                          >
                            <TextArea autoSize disabled={isFormDisabled} placeholder="Введите текст" />
                          </Form.Item>
                        </div>

                        <div className="common-field-label" style={{ marginBottom: '20px' }}>{`${isShowBlock ? '4.3.' : '4.3.'} Объем финансирования работ соисполнителя за счет средств гранта`}</div>

                        <div className="common-table-wrapper">
                          <div className="common-table-row">
                            <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>Срок реализации Проекта</div>
                            <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', borderBottom: '1px solid #d9d9d9' }}>
                              { projectTerm === '1' ? '2025 г.' : projectTerm === '2' ? '2025 – 2026 гг.' : '2025 – 2027 гг.' }
                            </div>
                          </div>
                        </div>

                        <Col>
                          <span className="common-field-hint" style={{ marginBottom: '10px' }}>Отметьте периоды, на которых предусмотрено выполнение НИР Соисполнителями</span>
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>с даты заключения Договора – 30.06.2025 г.</Col>
                            <Form.Item
                              name="isStep1"
                              initialValue={p.isStep1}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('1', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>30.06.2025 – 20.12.2025 г.</Col>
                            <Form.Item
                              name="isStep2"
                              initialValue={p.isStep2}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('2', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>10.01.2026 – 30.06.2026 г.</Col>
                            <Form.Item
                              name="isStep3"
                              initialValue={p.isStep3}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('3', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>01.07.2026 – 20.12.2026 г.</Col>
                            <Form.Item
                              name="isStep4"
                              initialValue={p.isStep4}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('4', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          {projectTerm === '3' && (
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>10.01.2027 – 30.06.2027 г.</Col>
                            <Form.Item
                              name="isStep5"
                              initialValue={p.isStep5}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('5', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          )}
                          {projectTerm === '3' && (
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>01.07.2027 – 20.12.2027 г.</Col>
                            <Form.Item
                              name="isStep6"
                              initialValue={p.isStep6}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('6', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          )}
                        </Col>

                        {/* <div className="common-field-wrapper">
                          <span className="common-subTitle">8.3. Номера этапа(-ов) реализации проекта, в рамках которых планируется участие организации-Соисполнителя:</span>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {Array(Number(projectTerm)).fill(0).map((e, ii) => (
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={ii}>
                                <Form.Item
                                  valuePropName="checked"
                                  name={`isStep${ii + 1}`}
                                  initialValue={p[`isStep${ii + 1}`]}
                                >
                                  <Checkbox style={{ width: 'fit-content', marginRight: '10px' }} />
                                </Form.Item>
                                <div>{`Этап ${ii + 1}`}</div>
                              </div>
                            ))}
                          </div>
                        </div> */}

                        {/* {isShowBlock && <div className="common-subTitle">8.4. Техническое задание на выполнение НИР:</div>} */}

                        {/* <div className="common-field-wrapper">
                          <span className="common-field-label">5.2. Техническое задание на выполнение НИР</span>
                          <Form.Item
                            name="task"
                            initialValue={p.task}
                          >
                            <TextArea autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div> */}

                        {/* {isShowBlock && (
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Цель выполнения НИР</span>
                          <Form.Item
                            name="goal"
                            initialValue={p.goal}
                          >
                            <TextArea autoSize disabled={isFormDisabled} placeholder="Введите текст" />
                          </Form.Item>
                        </div>
                        )} */}

                        {/* {isShowBlock && (
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Перечень результатов, подлежащих получению по окончанию выполнения НИР</span>
                          <Form.Item
                            name="results"
                            initialValue={p.results}
                          >
                            <TextArea autoSize disabled={isFormDisabled} placeholder="Введите текст" />
                          </Form.Item>
                        </div>
                        )} */}

                        {/* {isShowBlock && (
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Требования к выполняемым работам</span>
                          <Form.Item
                            name="requirements"
                            initialValue={p.requirements}
                          >
                            <TextArea autoSize disabled={isFormDisabled} placeholder="Введите текст" />
                          </Form.Item>
                        </div>
                        )} */}

                        {/* {isShowBlock && (
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Технические требования к научно-техническим результатам НИР</span>
                          <Form.Item
                            name="resultsRequirements"
                            initialValue={p.resultsRequirements}
                          >
                            <TextArea autoSize disabled={isFormDisabled} placeholder="Введите текст" />
                          </Form.Item>
                        </div>
                        )} */}

                        {/* {isShowBlock && (
                        <div className="common-field-wrapper">
                          <span className="common-field-label">Требования к разрабатываемой документации</span>
                          <Form.Item
                            name="docsRequirements"
                            initialValue={p.docsRequirements}
                          >
                            <TextArea autoSize disabled={isFormDisabled} placeholder="Введите текст" />
                          </Form.Item>
                        </div>
                        )} */}

                        {/* <div className="common-subTitle" style={{ marginBottom: '20px' }}>{`${isShowBlock ? '8.5.' : '8.5.'} Объем финансирования работ соисполнителя за счет средств гранта`}</div> */}

                        {/* {projectTerm && (
                        <div className="common-table-wrapper">
                          <div className="common-table-row">
                            <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>Срок реализации Проекта</div>
                            <div className="common-table-row-cell" style={{ borderRight: '1px solid #d9d9d9', borderBottom: '1px solid #d9d9d9' }}>
                              { projectTerm === '1' ? '2023 г.' : projectTerm === '2' ? '2023 – 2024 гг.' : '2023 – 2025 гг.' }
                            </div>
                          </div>
                        </div>
                        )} */}

                        {/* {projectTerm !== '1' && (
                        <Col>
                          <span className="common-field-hint" style={{ marginBottom: '10px' }}>Отметьте периоды, на которых предусмотрено выполнение НИР Соисполнителями</span>
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>с даты заключения Договора – 20.12.2023 г.</Col>
                            <Form.Item
                              name="isStep1"
                              initialValue={p.isStep1}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('1', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>10.01.2024 – 30.06.2024 г.</Col>
                            <Form.Item
                              name="isStep2"
                              initialValue={p.isStep2}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('2', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>01.07.2024 – 20.12.2024 г.</Col>
                            <Form.Item
                              name="isStep3"
                              initialValue={p.isStep3}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('3', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          {projectTerm === '3' && (
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>10.01.2025 – 30.06.2025 г.</Col>
                            <Form.Item
                              name="isStep4"
                              initialValue={p.isStep4}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('4', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          )}
                          {projectTerm === '3' && (
                          <Row style={{ alignItems: 'center', margin: '-10px 0px' }}>
                            <Col style={{ marginRight: '10px' }}>01.07.2025 – 20.12.2025 г.</Col>
                            <Form.Item
                              name="isStep5"
                              initialValue={p.isStep5}
                              valuePropName="checked"
                            >
                              <Checkbox onChange={(e) => onCheckBoxChange('5', e)} disabled={isFormDisabled} />
                            </Form.Item>
                          </Row>
                          )}
                        </Col>
                        )} */}

                        {rows.length > 0 && <div className="common-field-hint" style={{ marginTop: '20px' }}>Внесите информацию во все незаполненные ячейки таблицы по каждому этапу выполнения работ соисполнителем</div>}
                        {rows.length > 0 && (
                        <div className="common-table-wrapper">
                          <div className="common-table-row">
                            <div className="common-table-row-cell" style={{ fontWeight: 'bold', textAlign: 'center', width: '15%' }}>Наименование этапа</div>
                            <div className="common-table-row-cell" style={{ fontWeight: 'bold', textAlign: 'center', width: '15%' }}>Работы, которые подлежат выполнению соисполнителем</div>
                            <div className="common-table-row-cell" style={{ fontWeight: 'bold', textAlign: 'center', width: '15%' }}>Отчетный период по этапу (начало-окончание)</div>
                            <div className="common-table-row-cell" style={{ fontWeight: 'bold', textAlign: 'center', width: '15%' }}>Ожидаемый результат по результатам завершения работ по этапу</div>
                            <div className="common-table-row-cell" style={{ fontWeight: 'bold', textAlign: 'center', width: '15%' }}>Перечень разрабатываемых документов</div>
                            <div className="common-table-row-cell" style={{ fontWeight: 'bold', borderRight: '1px solid #d9d9d9', width: '15%' }}>Объем финансирования, руб.</div>
                          </div>
                          {rows.map((row, index) => (
                            <div className="common-table-row">
                              <div className="common-table-row-cell" style={{ width: '15%' }}>{`Этап ${index + 1}`}</div>
                              <Form.Item
                                style={{ width: '15%' }}
                                name={`step${row.number}Work`}
                                initialValue={p[`step${row.number}Work`]}
                              >
                                <TextArea autoSize disabled={disabled} />
                              </Form.Item>
                              <div className="common-table-row-cell" style={{ width: '15%' }}>{row.period}</div>
                              <Form.Item
                                style={{ width: '15%' }}
                                name={`step${row.number}Result`}
                                initialValue={p[`step${row.number}Result`]}
                              >
                                <TextArea autoSize disabled={disabled} />
                              </Form.Item>
                              {isMoscow === 'Да' && (
                              <div
                                className="common-table-row-cell"
                                style={{ width: '15%' }}
                              >
                                {rows.length > index + 1 ? 'Финансовый отчет' : 'Финансовый отчет, Аннотированный отчет'}
                              </div>
                              )}
                              {(isMoscow === 'Нет' || !isMoscow) && (
                              <div
                                className="common-table-row-cell"
                                style={{ width: '15%' }}
                              >
                                Отчет о НИР в соответствии с ГОСТ 7.32-2017
                              </div>
                              )}
                              <div
                                className="common-table-row-cell"
                                style={{ width: '15%', borderRight: '1px solid #d9d9d9' }}
                              >
                                <Form.Item
                                  style={{ width: '100%' }}
                                  name={`step${row.number}Sum`}
                                  initialValue={p[`step${row.number}Sum`]}
                                >
                                  <InputNumber disabled={disabled} onChange={onChangeSum} />
                                </Form.Item>
                              </div>
                            </div>
                          ))}

                          <div className="common-table-row" style={{ borderBottom: '1px solid #d9d9d9' }}>
                            <div className="common-table-row-cell" style={{ fontWeight: 'bold', width: '75%', textAlign: 'right' }}>Итого</div>
                            <Form.Item
                              name="stepTotal"
                              initialValue={p.stepTotal}
                              style={{ width: '15%' }}
                            >
                              <Input disabled style={{ width: '100%' }} />
                            </Form.Item>
                          </div>
                        </div>
                        )}

                        <div className="common-field-wrapper" style={{ marginTop: '10px' }}>
                          <span className="common-field-label">{`${isShowBlock ? '4.4.' : '4.4.'} Документы, подтверждающие согласие Соисполнителя выполнять работы НИР в рамках Проекта`}</span>
                          <span className="common-field-hint">
                            необходимо прикрепить скан подтверждающего письма от руководителя организации-Соисполнителя (
                            <a href="https://api.directual.com/fileUploaded/medtechgrants/624cf350-393f-4f20-86a2-7ec355f62483.docx" target="_blanck">шаблон</a>
                            )
                          </span>
                          <Dragger
                            showUploadList={false}
                            onChange={(file) => onUploadFile(file)}
                            disabled={loading}
                            multiple
                            action={null}
                            accept=".pdf"
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                          </Dragger>
                          {confirmEmail?.name && (
                          <Row style={{ margin: '5px 0px' }} gutter={12}>
                            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(confirmEmail.urlLink)} /></Col>
                            {!loading && !isFormDisabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onRemoveFile()} /></Col>}
                            <Col>{confirmEmail.name}</Col>
                          </Row>
                          )}
                        </div>

                      </Col>
                    </Form>
                    <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row>
                  </Modal>
                )}
          </Col>
        </Card>
      ))}
      {!objectInEditId && <Button type="primary" onClick={onAddClick} style={{ marginTop: '20px', width: '300px', fontSize: '16px' }} disabled={isFormDisabled}>Добавить соисполнителя</Button>}
    </div>
  );
}

PersonsTabPeopleModule.propTypes = {
  elementsArrayProp: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  projectTerm: PropTypes.string,
  updateOriginalObject: PropTypes.func.isRequired,
  updateOriginalObjectMass: PropTypes.func.isRequired,
  applicationProp: PropTypes.shape().isRequired,
};

PersonsTabPeopleModule.defaultProps = {
  projectTerm: null,
};

export default PersonsTabPeopleModule;
