/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, Radio, DatePicker,
} from 'antd';
import { editApplicationStep1Events, editApplicationStepReport } from '../../../../../../utils/http';


const { TextArea } = Input;
class ReportEventsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportInEditID: null,
      publications: this.props.publications,
      applicationId: this.props.applicationId,
      loading: false,
      isInView: false,
    };
    this.formRef = React.createRef();
  }

  onEditClick = (p) => {
    this.setState({
      reportInEditID: p.id,
    });
  }

  onAddClick = () => {
    const { applicationId, publications } = this.state;
    const { fieldToSave } = this.props;
    editApplicationStepReport({ id: applicationId, annotationIsPresented: 'Да' });
    editApplicationStep1Events({ applicationStepReport_id: applicationId, field: fieldToSave })
      .then((res) => {
        this.props.onChangeAmount(publications.length + 1);
        this.setState((prevState) => ({
          reportInEditID: res.result[0].id,
          publications: [...prevState.publications, { id: res.result[0].id }],
        }));
      });
  }

  onDeleteClick = (id) => {
    const { publications } = this.state;
    editApplicationStep1Events({ id, isDeleted: true })
      .then(() => {
        this.props.onChangeAmount(publications.length - 1);
        this.setState((prevState) => ({
          reportInEditID: null,
          publications: prevState.publications.filter((p) => p.id !== id),
        }));
      });
  }

  onCancelClick = () => {
    this.setState({ reportInEditID: null });
  }

  onSaveClick = () => {
    const { reportInEditID } = this.state;
    this.setState({ loading: true });
    const values = this.formRef.current.getFieldsValue();
    editApplicationStep1Events({
      id: reportInEditID, ...values,
    })
      .then(() => {
        this.setState((prevState) => ({
          loading: false,
          reportInEditID: null,
          publications: prevState.publications.map((p) => (p.id === reportInEditID ? {
            id: reportInEditID, ...values,
          } : p)),
        }));
        notification.success({ message: 'Успешно' });
      });
  }


  render() {
    const {
      reportInEditID, publications, loading, isInView,
    } = this.state;
    const { label } = this.props;
    return (
      <div style={{
        margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
      }}
      >
        <span style={{ fontSize: '24px', color: '#212121' }}>{label}</span>
        {publications.map((p, i) => (
          <Card
            key={p.id}
            style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
            bodyStyle={{
              borderLeft: reportInEditID === p.id ? '4px solid #1890FF' : '',
              borderRadius: reportInEditID === p.id ? '5px' : '',
            }}
          >
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  {`Мероприятие №${i + 1}`}
                </Col>
                <Col>
                  <Row align="middle" gutter={10}>
                    {!reportInEditID && (<Col><Button type="primary" onClick={() => this.onEditClick(p)}>Редактировать</Button></Col>)}
                    {!reportInEditID && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить мероприятие?"
                        onConfirm={() => this.onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger">Удалить</Button>
                      </Popconfirm>
                    </Col>
                    )}
                    {!reportInEditID && (<Col><Button onClick={() => this.setState({ reportInEditID: p.id, isInView: true })}>Развернуть</Button></Col>)}
                    {reportInEditID === p.id && isInView && (<Col><Button onClick={() => this.setState({ reportInEditID: null, isInView: false })}>Свернуть</Button></Col>)}
                    {reportInEditID === p.id && !isInView && (<Col><Button type="primary" onClick={this.onSaveClick}>Сохранить</Button></Col>)}
                    {reportInEditID === p.id && !isInView && (<Col><Button onClick={this.onCancelClick}>Отменить</Button></Col>)}
                  </Row>
                </Col>
              </Row>
              {reportInEditID === p.id
                && (
                <Form
                  initialValues={{ remember: true }}
                  ref={this.formRef}
                >
                  <Col>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.1. Наименование мероприятия</span>
                      <Form.Item
                        name="name"
                        initialValue={p.name}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.2 Дата начала мероприятия</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите дату начала мероприятия, используя календарь</span>
                      <Form.Item
                        name="dateStart"
                        initialValue={p.dateStart}
                      >
                        <DatePicker style={{ width: '100%' }} disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.3 Дата окончания мероприятия</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите дату окончания мероприятия, используя календарь</span>
                      <Form.Item
                        name="dateEnd"
                        initialValue={p.dateEnd}
                      >
                        <DatePicker style={{ width: '100%' }} disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.4 Город проведения мероприятия</span>
                      <Form.Item
                        name="city"
                        initialValue={p.city}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.5 Форма представления результатов, полученных при выполнении Проекта</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Пленарный доклад, стендовый доклад, устное выступление и т.п.</span>
                      <Form.Item
                        name="resultsForm"
                        initialValue={p.resultsForm}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">В случае личного представления результатов членом научного коллектива, укажите ФИО докладчика – члена коллектива специалистов</span>
                      <Form.Item
                        name="resultsFIO"
                        initialValue={p.resultsFIO}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.6 Мероприятие является международным</span>
                      <span style={{ fontStyle: 'italic', margin: '-5px 0px 5px' }}>Выберите вариант ответа</span>
                      <Form.Item
                        name="isInternational"
                        initialValue={p.isInternational}
                      >
                        <Radio.Group
                          options={[
                            { label: 'Да', value: 'Да' },
                            { label: 'Нет', value: 'Нет' },
                          ]}
                          disabled={loading}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                    <div className="application-main-box-content-wrapper">
                      <span className="application-main-box-label">17.7 Укажите примерное количество участников мероприятия, имевших возможность ознакомиться с результатами выполнения Проекта, представленными в ходе данного мероприятия</span>
                      <Form.Item
                        name="participants"
                        initialValue={p.participants}
                      >
                        <TextArea disabled={loading} placeholder="Необходимо заполнить" />
                      </Form.Item>
                    </div>
                  </Col>
                </Form>
                )}
            </Col>
          </Card>
        ))}
        {!reportInEditID && <Button type="primary" onClick={this.onAddClick} style={{ marginTop: '20px' }}>Добавить мероприятие</Button>}
      </div>
    );
  }
}

ReportEventsModule.propTypes = {
  publications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  fieldToSave: PropTypes.string,
  onChangeAmount: PropTypes.func.isRequired,
  label: PropTypes.func,
};

ReportEventsModule.defaultProps = {
  fieldToSave: '',
  label: 'Научные мероприятия',
};

export default ReportEventsModule;
