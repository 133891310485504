/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Row, Col, Upload, Checkbox, Select,
  InputNumber,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined,
} from '@ant-design/icons';
// import expectedResultsCatalogue from './expectedResultsCatalogue';
import InfoTabMaterialModule from './InfoTabMaterialModule';
import InfoTabAnalogModule from './InfoTabAnalogModule';
import InfoTabTaskModule from './InfoTabTaskModule';
import './InfoTab.css';
// import organisations from '../../organisations';


const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;
const defaultResultTypes = ['Фундаментальное исследование', 'Новая методика/алгоритм лечения/диагностика', 'Медицинское изделие', 'Информационная технология'];
function InfoTab({
  applicationProp, disabled, onAddFile, onDeleteFile, files, formRef, updateOriginalObject,
}) {
  const [fieldLength, setFieldLength] = useState({
    work: 0,
    state: 0,
    epidemic: 0,
    practice: 0,
    medicalEffect: 0,
    economicEffect: 0,
    competitor: 0,
    economyCheaperReason: 0,
  });

  const [resultType, setResultType] = useState(applicationProp.resultType);
  const [resultSubType, setResultSubType] = useState(applicationProp.resultSubType);
  const [resultValues, setResultValues] = useState(applicationProp.resultValues);
  const [isPowerPeople, setIsPowerPeople] = useState(applicationProp.isPowerPeople);
  const [isPartnerExist, setIsPartnerExist] = useState(applicationProp.isPartnerExist);
  const [isRegPaper, setIsRegPaper] = useState(applicationProp.isRegPaper);
  const [isRegPaperDuringGrant, setIsRegPaperDuringGrant] = useState(applicationProp.isRegPaperDuringGrant);
  const [isHelpPlace, setIsHelpPlace] = useState(applicationProp.isHelpPlace);
  const [isNoAnalogs, setIsNoAnalogs] = useState(applicationProp.isNoAnalogs);

  useEffect(() => {
    setFieldLength({
      work: applicationProp.work.length,
      state: applicationProp.state.length,
      epidemic: applicationProp.epidemic.length,
      practice: applicationProp.practice.length,
      medicalEffect: applicationProp.medicalEffect.length,
      economicEffect: applicationProp.economicEffect.length,
      competitor: applicationProp.competitor.length,
      economyCheaperReason: applicationProp.economyCheaperReason.length,
    });
  }, []);

  const onChangeResultType = (e) => {
    setResultType(e);
    if (e === 'Фундаментальное исследование' || e === 'Новая методика/алгоритм лечения/диагностика') {
      setResultSubType('');
      setResultValues(e);
      formRef.current.setFieldsValue({ resultSubType: '', resultValues: e, resultType: e });
    } else {
      setResultSubType('');
      setResultValues('');
      formRef.current.setFieldsValue({ resultSubType: '', resultValues: '', resultType: e });
    }
  };

  const onChangeResultSubType = (e) => {
    setResultSubType(e);
    if (e !== 'Прочее' && e !== 'Омиксные технологии, в том числе генетические') {
      setResultValues(e);
      formRef.current.setFieldsValue({ resultSubType: e, resultValues: e });
    } else {
      setResultValues('');
      formRef.current.setFieldsValue({ resultSubType: e, resultValues: '' });
    }
  };

  const onChangeResultValue = (e) => {
    setResultValues(e);
    formRef.current.setFieldsValue({ resultValues: e });
  };

  const onResultCheckBoxChange = (checked, field) => {
    if (field === 'Система поддержки принятия врачебных решений (СППВР)' || field === 'Искусственный интеллект') {
      if (checked) {
        if (!resultValues.includes('Система поддержки принятия врачебных решений (СППВР)') && !resultValues.includes('Искусственный интеллект')) {
          const val = `;${field}`;
          setResultValues(val);
          setResultSubType('');
          formRef.current.setFieldsValue({ resultSubType: '', resultValues: val });
        } else {
          const val = `${resultValues};${field}`;
          setResultValues(val);
          setResultSubType('');
          formRef.current.setFieldsValue({ resultSubType: '', resultValues: val });
        }
      } else {
        const val = resultValues.replace(`;${field}`, '');
        setResultValues(val);
        setResultSubType('');
        formRef.current.setFieldsValue({ resultSubType: '', resultValues: val });
      }
    }

    if (field === 'Другое') {
      if (checked) {
        setResultValues('');
        setResultSubType('Другое');
        formRef.current.setFieldsValue({ resultSubType: 'Другое', resultValues: '' });
      } else {
        setResultValues('');
        setResultSubType('');
        formRef.current.setFieldsValue({ resultSubType: '', resultValues: '' });
      }
    }

    if (field === 'Приложение для гаджетов/ПО') {
      if (checked) {
        const val = `;${field}`;
        setResultValues(val);
        setResultSubType('');
        formRef.current.setFieldsValue({ resultSubType: '', resultValues: val });
      } else {
        setResultValues('');
        setResultSubType('');
        formRef.current.setFieldsValue({ resultSubType: '', resultValues: '' });
      }
    }
  };

  const onChangePowerPeople = (checked) => {
    formRef.current.setFieldsValue({ isPowerPeople: checked });

    if (checked) {
      formRef.current.setFieldsValue({ power: 'Статические расчеты выборки пациентов для данного проекта не применимы' });
      formRef.current.setFieldsValue({ powerPeopleReason: '' });
    } else {
      formRef.current.setFieldsValue({ power: '' });
      formRef.current.setFieldsValue({ powerPeopleReason: '' });
    }

    setIsPowerPeople(checked);
  };

  const onIsNoAnalogsChange = (checked) => {
    formRef.current.setFieldsValue({ isNoAnalogs: checked });
    setIsNoAnalogs(checked);
  };

  // const onExpectedResultsChange = () => {
  //   const values = formRef.current.getFieldsValue();
  //   const formObjects = [];
  //   expectedResultsCatalogue.forEach((e) => {
  //     const obj = {
  //       id: values[`${e.type}-applications2ExpectedResults_ids-id`],
  //       type: values[`${e.type}-applications2ExpectedResults_ids-type`],
  //       name: values[`${e.type}-applications2ExpectedResults_ids-name`],
  //       value: values[`${e.type}-applications2ExpectedResults_ids-value`],
  //     };
  //     formObjects.push(obj);
  //   });
  //   const formObjectsWithCheck = formObjects.filter((e) => e.value).map((e) => {
  //     const part = applicationProp.applications2ExpectedResults_ids.find((r) => r.id === e.id);
  //     return ({
  //       ...part, id: e.id, type: e.type, name: e.name,
  //     });
  //   });
  //   updateOriginalObject('applications2ExpectedResults_ids', formObjectsWithCheck);
  // };

  const resultValuesToWorkWith = [];
  const resultValuesAsArray = resultValues.split(';');
  if (resultValuesAsArray.length === 1 && resultValuesAsArray[0] !== '') {
    resultValuesToWorkWith.push(resultValuesAsArray[0]);
  }
  if (resultValuesAsArray.length === 2) {
    resultValuesToWorkWith.push(resultValuesAsArray[1]);
  }
  if (resultValuesAsArray.length === 3) {
    resultValuesToWorkWith.push(resultValuesAsArray[1]);
    resultValuesToWorkWith.push(resultValuesAsArray[2]);
  }

  const additionalFieldName = resultType === 'Медицинское изделие' ? resultValues : null;

  const onUpdateList = (field, array) => {
    updateOriginalObject(field, array);
  };

  return (
    <div className="infoTab-wrapper">

      <Form.Item
        name="id"
        initialValue={applicationProp.id}
        hidden
      />

      <Form.Item
        name="resultType"
        initialValue={applicationProp.resultType}
        hidden
      />
      <Form.Item
        name="resultSubType"
        initialValue={applicationProp.resultSubType}
        hidden
      />
      <Form.Item
        name="resultValues"
        initialValue={applicationProp.resultValues}
        hidden
      />

      <div className="common-subTitle">1. Обоснование целесообразности реализации Проекта в сфере столичного здравоохранения</div>

      <div className="common-field-wrapper">
        <span className="common-field-label">1.1 Цель Проекта</span>
        <span className="common-field-hint">Целью проекта должно являться решение выявленной проблемы.</span>
        <span className="common-field-hint">Стоит избегать общих фраз и формулировать цель максимально конкретно.</span>
        <Form.Item
          name="goal"
          initialValue={applicationProp.goal}
        >
          <TextArea autoSize disabled={disabled} minrows={4} placeholder="Введите текст" />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">1.2 Задачи Проекта</span>
        <span className="common-field-hint">Указать какие практические задачи будут решены по итогам реализации Проекта</span>
        <InfoTabTaskModule
          elementsArrayProp={applicationProp.applications2InfoTabTasks_ids}
          applicationId={applicationProp.id}
          disabled={disabled}
          updateOriginalObject={(array) => onUpdateList('applications2InfoTabTasks_ids', array)}
        />
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">1.3 Имеющийся у коллектива специалистов научный задел по Проекту</span>
        <span className="common-field-hint">В данном разделе необходимо указать, какие у коллектива имеются разработки, методики, прототипы, публикации, базы данных, а также успешный опыт проведения научных исследований по теме Проекта</span>
        <Form.Item
          name="work"
          initialValue={applicationProp.work}
        >
          <TextArea autoSize disabled={disabled} minrows={4} onChange={(e) => setFieldLength({ ...fieldLength, work: e.target.value ? e.target.value.length : 0 })} placeholder="Введите текст" />
        </Form.Item>
        <span className="common-field-hint" style={{ marginTop: '0px' }}>(от 1500 печатных знаков с пробелами)</span>
        <span className="common-field-hint" style={{ marginTop: '0px' }}>{`${fieldLength.work} из 1500`}</span>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">1.4 Ожидаемые результаты</span>
        <span className="common-field-hint">Результатом выполненной работы по проекту будет являться конечный продукт или решение, разработанное и представленное участниками коллектива специалистов для решения поставленных задач и достижения цели. Это может быть новый продукт, новый метод лечения/диагностики или профилактики, улучшенный процесс, решение проблемы или любой другой конкретный результат, непосредственно связанный с целями и требованиями проекта. В данном разделе необходимо указать, что станет количественным и качественным результатом реализации проекта.</span>
        <Form.Item
          name="result"
          initialValue={applicationProp.result}
        >
          <TextArea autoSize disabled={disabled} minrows={4} placeholder="Введите текст" />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">1.5 Вид результата</span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Select style={{ width: '400px', height: '32px' }} onSelect={(e) => onChangeResultType(e)} defaultValue={resultType}>
            {[{ id: 'Фундаментальное исследование', value: 'Фундаментальное исследование' },
              { id: 'Новая методика/алгоритм лечения/диагностика', value: 'Новая методика/алгоритм лечения/диагностика' },
              { id: 'Медицинское изделие', value: 'Медицинское изделие' },
              { id: 'Информационная технология', value: 'Информационная технология' },
              { id: 'Другое', value: 'Другое' },
            ].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
          {resultType === 'Медицинское изделие' && (
          <Select style={{ width: '400px', marginLeft: '20px' }} onSelect={(e) => onChangeResultSubType(e)} defaultValue={resultSubType}>
            {[{ id: 'Тест-система', value: 'Тест-система' },
              { id: 'Омиксные технологии, в том числе генетические', value: 'Омиксные технологии, в том числе генетические' },
              { id: 'Прибор', value: 'Прибор' },
              { id: 'Новые материалы/импланты', value: 'Новые материалы/импланты' },
              { id: 'Прочее', value: 'Прочее' },
            ].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
          )}
          {resultSubType === 'Омиксные технологии, в том числе генетические' && (
          <Select style={{ width: '400px', marginLeft: '20px' }} onSelect={(e) => { setResultValues(e); formRef.current.setFieldsValue({ resultValues: e }); }} defaultValue={resultValues}>
            {[
              { id: 'Технология геномики', value: 'Технология геномики' },
              { id: 'Технология метаболомики', value: 'Технология метаболомики' },
              { id: '️Технология протеомики', value: 'Технология протеомики' },
              { id: 'Технология липидомики', value: 'Технология липидомики' },
              { id: 'Технология транскриптомики', value: 'Технология транскриптомики' },
            ].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
          )}
          {resultType === 'Информационная технология' && (
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '40px' }}>
            <Checkbox disabled={resultValues === ';Другое' || resultValues === ';Приложение для гаджетов/ПО'} defaultChecked={resultValues.includes('Система поддержки принятия врачебных решений (СППВР)')} onChange={(e) => onResultCheckBoxChange(e.target.checked, 'Система поддержки принятия врачебных решений (СППВР)')}>Система поддержки принятия врачебных решений (СППВР)</Checkbox>
            <Checkbox disabled={resultValues === ';Другое' || resultValues === ';Приложение для гаджетов/ПО'} defaultChecked={resultValues.includes('Искусственный интеллект')} onChange={(e) => onResultCheckBoxChange(e.target.checked, 'Искусственный интеллект')} style={{ marginLeft: '0px' }}>️Искусственный интеллект</Checkbox>
            <Checkbox disabled={resultValues.includes('Система поддержки принятия врачебных решений (СППВР)') || resultValues.includes('Искусственный интеллект') || resultValues === ';Другое'} defaultChecked={resultValues.includes('Приложение для гаджетов/ПО')} onChange={(e) => onResultCheckBoxChange(e.target.checked, 'Приложение для гаджетов/ПО')} style={{ marginLeft: '0px' }}>Приложение для гаджетов/ПО</Checkbox>
            <Checkbox disabled={resultValues.includes('Система поддержки принятия врачебных решений (СППВР)') || resultValues.includes('Искусственный интеллект') || resultValues === ';Приложение для гаджетов/ПО'} defaultChecked={resultValues.includes('Другое')} onChange={(e) => onResultCheckBoxChange(e.target.checked, 'Другое')} style={{ marginLeft: '0px' }}>Другое</Checkbox>
          </div>
          )}
          {resultType === 'Информационная технология' && resultSubType === 'Другое' && (
          <Input
            style={{
              width: '400px', marginLeft: '20px', height: '32px', marginTop: '55px',
            }}
            onChange={(e) => onChangeResultValue(e.target.value)}
            defaultValue={resultValues}
          />
          )}
          {resultType === 'Медицинское изделие' && resultSubType === 'Прочее' && <Input style={{ width: '400px', marginLeft: '20px' }} onChange={(e) => onChangeResultValue(e.target.value)} defaultValue={resultValues} placeholder="Введите наименование" />}
          {(resultType === 'Другое' || (resultType !== '' && !defaultResultTypes.includes(resultType))) && <Input style={{ width: '400px', marginLeft: '20px' }} onChange={(e) => onChangeResultValue(e.target.value)} defaultValue={resultValues} placeholder="Введите наименование" />}
        </div>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">1.6 Предлагаемые методы и подходы</span>
        <span className="common-field-hint">В данном разделе описываются все инструменты, необходимые для реализации Проекта (клинико-диагностические методы, лабораторные исследования, анкетирование, хирургические операции и тд)</span>
        <Form.Item
          name="method"
          initialValue={applicationProp.method}
        >
          <TextArea autoSize disabled={disabled} minrows={4} placeholder="Введите текст" />
        </Form.Item>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">1.7 Мощность исследования</span>
        {/* {(isPowerPeople !== 'Да') && <span className="common-field-hint">Привести статистический расчет необходимой для реализации исследования выборки пациентов</span>} */}

        {(isPowerPeople === null || (isPowerPeople !== null && !isPowerPeople)) && <span className="common-field-hint">Привести статистический расчет необходимой для реализации исследования выборки пациентов</span>}
        {(isPowerPeople === null || (isPowerPeople !== null && !isPowerPeople)) && (
        <Form.Item
          name="power"
          initialValue={applicationProp.power}
        >
          <TextArea autoSize disabled={disabled} minrows={4} placeholder="Введите текст" />
        </Form.Item>
        )}
        {/* <span className="common-field-label">Статические расчеты выборки пациентов для данного проекта не применимы</span> */}

        <Form.Item
          style={{ width: '100%' }}
          name="isPowerPeople"
          initialValue={applicationProp.isPowerPeople}
        >
          <Checkbox defaultChecked={isPowerPeople} onChange={(e) => onChangePowerPeople(e.target.checked)}>Статические расчеты выборки пациентов для данного проекта не применимы</Checkbox>
        </Form.Item>
        {isPowerPeople && (
        <Form.Item
          name="power"
          hidden
          initialValue="Статические расчеты выборки пациентов для данного проекта не применимы"
          value="Статические расчеты выборки пациентов для данного проекта не применимы"
        />
        )}
        {/* <Form.Item
          style={{ width: '100%' }}
          name="isPowerPeople"
          initialValue={applicationProp.isPowerPeople}
        >
          <Select disabled={disabled} onChange={(e) => setIsPowerPeople(e)}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }, { id: 'Сопоставима', value: 'Сопоставима' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item> */}
        {isPowerPeople && <span className="common-field-label">Поясните почему статистические расчеты выборки пациентов для данного проекта не применимы</span>}
        {isPowerPeople && (
        <Form.Item
          name="powerPeopleReason"
          initialValue={applicationProp.powerPeopleReason}
        >
          <TextArea autoSize disabled={disabled} minrows={4} />
        </Form.Item>
        )}
      </div>

      <div className="common-field-label">1.8. Материально-техническая и инфраструктурная базы, необходимые для выполнения Проекта:</div>

      <div className="common-field-label">1.8.1. Имеющиеся в учреждении:</div>
      <div className="common-field-hint">Перечислите оборудование</div>
      <InfoTabMaterialModule
        elementsArrayProp={applicationProp.applications2InfoTabMaterial_ids}
        applicationId={applicationProp.id}
        disabled={disabled}
        updateOriginalObject={(array) => onUpdateList('applications2InfoTabMaterial_ids', array)}
      />

      {/* <div className="common-field-label">1.8.2. Необходимые к приобретению:</div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ border: '1px solid #d9d9d9', width: '50px' }}>
            №
          </div>
          <div style={{ border: '1px solid #d9d9d9', width: '200px' }}>
            Наименование
          </div>
          <div style={{ border: '1px solid #d9d9d9', width: '200px' }}>
            Назначение
          </div>
        </div>
        {applicationProp?.applications2Costs_ids.filter((e) => !e.isTotal && e.type === 'equipment').map((e, i) => (
          <div style={{ display: 'flex', flexDirection: 'row' }} key={e.id}>
            <div style={{ border: '1px solid #d9d9d9', width: '50px' }}>
              {i + 1}
            </div>
            <div style={{ border: '1px solid #d9d9d9', width: '200px' }}>
              {e.name}
            </div>
            <div style={{ border: '1px solid #d9d9d9', width: '200px' }}>
              {e.description}
            </div>
          </div>
        ))}
      </div> */}

      <div className="common-field-wrapper" style={{ marginTop: '20px' }}>
        <span className="common-field-label">1.9. Риски реализации Проекта и пути их минимизации</span>
        <div className="common-field-hint">
          Опишите, какие риски могут возникнуть при реализации проекта, а также меры, предпринятые для обеспечения успешного завершения проекта.
          {' '}
          <br />
          Примеры рисков:
          <ul>
            <li>финансовые риски. Возможные потери или финансовые затраты из-за непредвиденных обстоятельств;</li>
            <li>технические риски. Связаны со сбоями в технической инфраструктуре, неисправностью оборудования, падением производительности и другими техническими проблемами;</li>
            <li>риски, касающиеся персонала. Означают возможность ухода специалистов, нехватку квалифицированных кадров, сложности с обучением новых сотрудников и другие проблемы в управлении персоналом;</li>
            <li>временные риски. Сопряжены с некоторыми задержками в реализации, несвоевременным поступлением необходимых ресурсов или информации, плохой организацией рабочего процесса, неправильным расчетом времени выполнения задач;</li>
            <li>изменение объемов работ. Это может свидетельствовать о неправильно оцененной продолжительности или о незавершенных работах, ошибках планирования, а также о других причинах;</li>
            <li>другие</li>
          </ul>
        </div>
        <Form.Item
          name="risk"
          initialValue={applicationProp.risk}
        >
          <TextArea autoSize disabled={disabled} minrows={4} placeholder="Введите текст" />
        </Form.Item>
      </div>

      <div className="common-subTitle">Актуальность Проекта для столичного здравоохранения и научно-практическая новизна </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">2.1. Эпидемиологическая актуальность проекта в городе Москве</span>
        <div className="common-field-hint">
          Обоснуйте актуальность реализации проекта, приведите статистические данные
          по заболеваемости в городе Москве с указанием ссылок на источники.
        </div>
        <div className="common-field-label" style={{ marginTop: '10px' }}>Какое количество пациентов нуждается в предлагаемом решении?</div>
        <div className="common-field-hint">
          Укажите статистические данные по количеству пациентов, которые нуждается
          в предлагаемом решении, и дайте ссылку на источник информации
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{
            border: '1px solid #d9d9d9', width: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex',
          }}
          >
            в Москве
          </div>
          <Form.Item
            name="epidemicMoscow"
            initialValue={applicationProp.epidemicMoscow}
          >
            <InputNumber disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '150px', borderRadius: '0px' }} placeholder="Введите число" />
          </Form.Item>
          <Form.Item
            name="epidemicMoscowLink"
            initialValue={applicationProp.epidemicMoscowLink}
          >
            <Input disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '300px' }} placeholder="Ссылка на источник информации" />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{
            border: '1px solid #d9d9d9', width: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex',
          }}
          >
            в России
          </div>
          <Form.Item
            name="epidemicRussia"
            initialValue={applicationProp.epidemicRussia}
          >
            <InputNumber disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '150px', borderRadius: '0px' }} placeholder="Введите число" />
          </Form.Item>
          <Form.Item
            name="epidemicRussiaLink"
            initialValue={applicationProp.epidemicRussiaLink}
          >
            <Input disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '300px' }} placeholder="Ссылка на источник информации" />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{
            border: '1px solid #d9d9d9', width: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex',
          }}
          >
            в Мире
          </div>
          <Form.Item
            name="epidemicWorld"
            initialValue={applicationProp.epidemicWorld}
          >
            <InputNumber disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '150px', borderRadius: '0px' }} placeholder="Введите число" />
          </Form.Item>
          <Form.Item
            name="epidemicWorldLink"
            initialValue={applicationProp.epidemicWorldLink}
          >
            <Input disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '300px' }} placeholder="Ссылка на источник информации" />
          </Form.Item>
        </div>

        {additionalFieldName && (
        <span className="common-field-label">
          Сколько
          <b>{` ${additionalFieldName}`}</b>
          {' '}
          необходимо для решения сложившейся ситуации`
        </span>
        )}

        {additionalFieldName && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{
            border: '1px solid #d9d9d9', width: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex',
          }}
          >
            в Москве
          </div>
          <Form.Item
            name="epidemicMoscowAdditional"
            initialValue={applicationProp.epidemicMoscowAdditional}
          >
            <InputNumber disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '150px', borderRadius: '0px' }} placeholder="Введите число" />
          </Form.Item>
        </div>
        )}
        {additionalFieldName && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{
            border: '1px solid #d9d9d9', width: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex',
          }}
          >
            в России
          </div>
          <Form.Item
            name="epidemicRussiaAdditional"
            initialValue={applicationProp.epidemicRussiaAdditional}
          >
            <InputNumber disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '150px', borderRadius: '0px' }} placeholder="Введите число" />
          </Form.Item>
        </div>
        )}

        <span className="common-field-label">Дайте обоснование расчетам (от 1000 печатных знаков с пробелами)</span>
        <Form.Item
          name="epidemic"
          initialValue={applicationProp.epidemic}
        >
          <TextArea autoSize disabled={disabled} minrows={4} onChange={(e) => setFieldLength({ ...fieldLength, epidemic: e.target.value ? e.target.value.length : 0 })} />
        </Form.Item>
        <span className="common-field-hint" style={{ marginTop: '0px' }}>{`${fieldLength.epidemic} из 1000`}</span>
        <span style={{ fontStyle: 'italic' }}>При необходимости представления дополнительных, в том числе иллюстрационных (таблиц, диаграмм и т.п.), материалов по Проекту загрузите файлы</span>
        <Dragger
          showUploadList={false}
          onChange={(file) => onAddFile(file, 'epidemicFiles_ids')}
          disabled={disabled}
          multiple
          action={null}
          accept=".docx,.doc,.pdf,.xlsx,.xlsm"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Выберите или перенесите файл в формате .docx/.doc/.pdf/.xlsx/.xlsm, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
        </Dragger>
        {files.epidemicFiles_ids.map((f) => (
          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
            {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'epidemicFiles_ids')} /></Col>}
            <Col>{f.name}</Col>
          </Row>
        ))}
      </div>
      <div className="common-field-wrapper">
        <span className="common-field-label">2.2. Краткий обзор состояния науки в отношении цели Проекта</span>
        <span className="common-field-hint">Краткий обзор литературы с указанием ссылок на источники, содержащие информацию о проводимых научных исследованиях по заданной тематике на текущий момент.</span>
        <Form.Item
          name="state"
          initialValue={applicationProp.state}
        >
          <TextArea autoSize disabled={disabled} minrows={4} onChange={(e) => setFieldLength({ ...fieldLength, state: e.target.value ? e.target.value.length : 0 })} />
        </Form.Item>
        <span className="common-field-hint" style={{ marginTop: '0px' }}>(от 2000 печатных знаков с пробелами)</span>
        <span className="common-field-hint" style={{ marginTop: '0px' }}>{`${fieldLength.state} из 2000`}</span>
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">2.3. Потенциальная медико-экономическая эффективность Проекта</span>
        <span className="common-field-hint">Провести сравнительный анализ в отношении существующих медицинских технологий (методов/технологий/лечебных процедур/препаратов и т.п.), применяемых при данной нозологии в г. Москве с точки зрения: а) медицинской эффективности; б) экономической эффективности. В чем преимущество предлагаемого Проекта перед ними.</span>
        <div className="common-field-wrapper">

          <span className="common-field-label">а) Медицинская эффективность</span>
          <span className="common-field-hint">
            В данном разделе необходимо указать степень достижения медицинского результата
            (к каким улучшениям приведет достижение поставленных задач Проекта в области профилактики, диагностики и лечения заболеваний). Например, в отношении одного конкретного больного – это выздоровление или улучшение состояния здоровья, восстановление утраченных функций отдельных органов и систем. В отношении методики/технологии – повышение качественных и количественных характеристик методики/технологии.
          </span>
          <Form.Item
            name="medicalEffect"
            initialValue={applicationProp.medicalEffect}
          >
            <TextArea autoSize disabled={disabled} minrows={4} onChange={(e) => setFieldLength({ ...fieldLength, medicalEffect: e.target.value ? e.target.value.length : 0 })} />
          </Form.Item>
          <span className="common-field-hint" style={{ marginTop: '0px' }}>(от 1000 печатных знаков с пробелами)</span>
          <span className="common-field-hint" style={{ marginTop: '0px' }}>{`${fieldLength.medicalEffect} из 1000`}</span>
          <span style={{ fontStyle: 'italic' }}>При необходимости представления дополнительных, в том числе иллюстрационных (таблиц, диаграмм и т.п.), материалов по Проекту загрузите файлы</span>
          <Dragger
            showUploadList={false}
            onChange={(file) => onAddFile(file, 'medicalEffectFiles_ids')}
            disabled={disabled}
            multiple
            action={null}
            accept=".docx,.doc,.pdf,.xlsx,.xlsm"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Выберите или перенесите файл в формате .docx/.doc/.pdf/.xlsx/.xlsm, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
          </Dragger>
          {files.medicalEffectFiles_ids.map((f) => (
            <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
              {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'medicalEffectFiles_ids')} /></Col>}
              <Col>{f.name}</Col>
            </Row>
          ))}
        </div>

        <span className="common-field-label">б) Экономическая эффективность</span>
        <span className="common-field-hint">В данном разделе необходимо привести расчеты экономического эффекта после внедрения разработки в медицинскую практику (например, новая методика может привести к снижению государственных социальных выплат при сокращении инвалидизации, снижению медицинских расходов на реабилитацию, снижению трудозатрат медицинских работников и т.п.)</span>
        <span className="common-field-hint">
          Какому количеству пациентов
          <b> В ГОД</b>
          {' '}
          поможет предлагаемое решение
        </span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{
            border: '1px solid #d9d9d9', width: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex',
          }}
          >
            в России
          </div>
          <Form.Item
            name="economyRussia"
            initialValue={applicationProp.economyRussia}
          >
            <InputNumber disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '150px', borderRadius: '0px' }} placeholder="Введите число" />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{
            border: '1px solid #d9d9d9', width: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex',
          }}
          >
            в Москве
          </div>
          <Form.Item
            name="economyMoscow"
            initialValue={applicationProp.economyMoscow}
          >
            <InputNumber disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '150px', borderRadius: '0px' }} placeholder="Введите число" />
          </Form.Item>
        </div>

        {additionalFieldName && (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
          <span
            style={{
              border: '1px solid #d9d9d9', alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '0px 5px', color: '#212121',
            }}
          >
            Предполагаемая стоимость
            <b style={{ margin: '0px 3px' }}>{` ${additionalFieldName} `}</b>
            за единицу
          </span>
          <Form.Item
            name="economyAdditional"
            initialValue={applicationProp.economyAdditional}
          >
            <InputNumber disabled={disabled} style={{ border: '1px solid #d9d9d9', width: '150px', borderRadius: '0px' }} placeholder="Введите число" />
          </Form.Item>
          <div style={{
            border: '1px solid #d9d9d9', width: '100px', alignItems: 'center', justifyContent: 'center', display: 'flex',
          }}
          >
            рублей
          </div>
        </div>
        )}

        {additionalFieldName && (
        <span className="common-field-label" style={{ marginTop: '20px' }}>
          Цена единицы
          <b>{` ${additionalFieldName}`}</b>
          {' '}
          меньше цены(-е) существующих аналогов
        </span>
        )}
        {additionalFieldName && (
        <Form.Item
          style={{ width: '100%' }}
          name="isEconomyCheaper"
          initialValue={applicationProp.isEconomyCheaper}
        >
          <Select disabled={disabled}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }, { id: 'Сопоставима', value: 'Сопоставима' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
        )}

        <span className="common-field-label">Дайте обоснования расчетам</span>
        <Form.Item
          style={{ width: '100%' }}
          name="economyCheaperReason"
          initialValue={applicationProp.economyCheaperReason}
        >
          <TextArea autoSize onChange={(e) => setFieldLength({ ...fieldLength, economyCheaperReason: e.target.value ? e.target.value.length : 0 })} disabled={disabled} minrows={4} />
        </Form.Item>

        <span className="common-field-hint" style={{ marginTop: '0px' }}>(от 1000 печатных знаков с пробелами)</span>
        <span className="common-field-hint" style={{ marginTop: '0px' }}>{`${fieldLength.economyCheaperReason} из 1000`}</span>
        <span style={{ fontStyle: 'italic' }}>При необходимости представления дополнительных, в том числе иллюстрационных (таблиц, диаграмм и т.п.), материалов по Проекту загрузите файлы</span>
        <Dragger
          showUploadList={false}
          onChange={(file) => onAddFile(file, 'economicEffectFiles_ids')}
          disabled={disabled}
          multiple
          action={null}
          accept=".docx,.doc,.pdf,.xlsx,.xlsm"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Выберите или перенесите файл в формате .docx/.doc/.pdf/.xlsx/.xlsm, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
        </Dragger>
        {files.economicEffectFiles_ids.map((f) => (
          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
            {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f, 'economicEffectFiles_ids')} /></Col>}
            <Col>{f.name}</Col>
          </Row>
        ))}
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">2.4. Тип продукта (-ов), который (-ые) будет получен по результатам реализации Проекта</span>

        {resultValuesToWorkWith.length > 0 && [1].map((e, i) => (
          <div className="common-field-wrapper" key={e}>
            <span className="common-field-label" style={{ fontWeight: 'bold' }}>{resultValuesToWorkWith.join(', ')}</span>
            <span className="common-field-label">Предполагается получение патента</span>
            <Form.Item
              style={{ width: '100%' }}
              name={`isPatent${i + 1}`}
              initialValue={applicationProp[`isPatent${i + 1}`]}
            >
              <Select disabled={disabled}>
                {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            </Form.Item>
            <span className="common-field-label">Планируется  получения международного патента</span>
            <Form.Item
              style={{ width: '100%' }}
              name={`isWorldPatent${i + 1}`}
              initialValue={applicationProp[`isWorldPatent${i + 1}`]}
            >
              <Select disabled={disabled}>
                {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            </Form.Item>
            <span className="common-field-label">Нужна ли помощь Центра в получении патента</span>
            <Form.Item
              style={{ width: '100%' }}
              name={`isHelpPatent${i + 1}`}
              initialValue={applicationProp[`isHelpPatent${i + 1}`]}
            >
              <Select disabled={disabled}>
                {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            </Form.Item>

            {additionalFieldName && (<span className="common-field-label" style={{ marginTop: '20px' }}>Нужна ли помощь в подборе производственной площадки?</span>)}
            {additionalFieldName && (
            <Form.Item
              style={{ width: '100%' }}
              name="isHelpPlace"
              initialValue={applicationProp.isHelpPlace}
            >
              <Select disabled={disabled} onChange={(p) => setIsHelpPlace(p)}>
                {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            </Form.Item>
            )}

            {isHelpPlace === 'Нет' && (
            <span className="common-field-label">
              Какая организация будет производить
              <b>{` ${resultValuesToWorkWith}`}</b>
            </span>
            )}
            {isHelpPlace === 'Нет' && (
            <Form.Item
              style={{ width: '100%' }}
              name={`orgProducer${i + 1}`}
              initialValue={applicationProp[`orgProducer${i + 1}`]}
            >
              <Input disabled={disabled} placeholder="Наименование организации" />
            </Form.Item>
            )}

          </div>
        ))}

        <span className="common-field-label" style={{ marginTop: '20px' }}>Планируется ли получение регистрационного удостоверения?</span>
        <Form.Item
          style={{ width: '100%' }}
          name="isRegPaper"
          initialValue={applicationProp.isRegPaper}
        >
          <Select disabled={disabled} onChange={(e) => setIsRegPaper(e)}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>

        {isRegPaper === 'Да' && (<span className="common-field-label" style={{ marginTop: '0px' }}>Получение регистрационного удостоверения планируется в ходе выполнения работ по гранту?</span>)}
        {isRegPaper === 'Да' && (
        <Form.Item
          style={{ width: '100%' }}
          name="isRegPapeDuringGrant"
          initialValue={applicationProp.isRegPapeDuringGrant}
        >
          <Select disabled={disabled} onChange={(e) => setIsRegPaperDuringGrant(e)}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
        )}

        {isRegPaperDuringGrant === 'Да' && (<span className="common-field-label" style={{ marginTop: '0px' }}>Нужна помощь Центра в получении регистрационного удостоверения?</span>)}
        {isRegPaperDuringGrant === 'Да' && (
        <Form.Item
          style={{ width: '100%' }}
          name="isRegPapeDuringGrantHelpCenter"
          initialValue={applicationProp.isRegPapeDuringGrantHelpCenter}
        >
          <Select disabled={disabled}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
        )}

        {isRegPaper === 'Да' && (<span className="common-field-label" style={{ marginTop: '20px' }}>Кому будут принадлежать права на регистрационное удостоверение?</span>)}
        {isRegPaper === 'Да' && (
        <Form.Item
          style={{ width: '100%' }}
          name="regPaperRightsOwner"
          initialValue={applicationProp.regPaperRightsOwner}
        >
          <Input disabled={disabled} placeholder="Введите наименование" />
        </Form.Item>
        )}

        {additionalFieldName && (
        <span className="common-field-label">
          Потребуется внесение изменений в клинические рекомендации для внедрения
          {' '}
          <b>{additionalFieldName}</b>
        </span>
        )}
        {additionalFieldName && (
        <Form.Item
          style={{ width: '100%' }}
          name="isClinicChangesRequired"
          initialValue={applicationProp.isClinicChangesRequired}
        >
          <Select disabled={disabled}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
        )}

        {additionalFieldName && (<span className="common-field-label">Планируйте ли вы заняться внесением изменений в клинические рекомендации?</span>)}
        {additionalFieldName && (
        <Form.Item
          style={{ width: '100%' }}
          name="isClinicChangesPlaned"
          initialValue={applicationProp.isClinicChangesPlaned}
        >
          <Select disabled={disabled}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
        )}
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">2.5. Наличие конкурирующих решений</span>
        <span className="common-field-hint">Перечислите наиболее близкие аналоги технологий. Перечислите научные группы/организации, которые проводят аналогичные разработки. В чем заключается преимущество предлагаемой технологии.</span>

        <Form.Item initialValue={applicationProp.isNoAnalogs} name="isNoAnalogs">
          <Checkbox disabled={disabled} onChange={() => onIsNoAnalogsChange(!isNoAnalogs)} checked={isNoAnalogs}>
            Аналогов и конкурирующих решений нет
          </Checkbox>
        </Form.Item>

        {!isNoAnalogs && (
          <InfoTabAnalogModule
            elementsArrayProp={applicationProp.applications2InfoTabAnalog_ids}
            applicationId={applicationProp.id}
            disabled={disabled}
            updateOriginalObject={(array) => onUpdateList('applications2InfoTabAnalog_ids', array)}
            additionalFieldName={additionalFieldName}
          />
        )}

        {additionalFieldName && (<span className="common-field-label">В чем преимущество перед аналогами</span>)}
        {additionalFieldName && (
        <Form.Item
          style={{ width: '100%' }}
          name="whyBetterAnalog"
          initialValue={applicationProp.whyBetterAnalog}
        >
          <TextArea autoSize disabled={disabled} minrows={4} placeholder="Введите текст (в чем преимущество медизделия перед аналогами)" />
        </Form.Item>
        )}
      </div>

      <div className="common-field-wrapper">
        <span className="common-field-label">2.6. Перспективы дальнейшего развития проекта</span>
        <span className="common-field-label">Планируется ли вывод результата работы на рынок</span>
        <Form.Item
          style={{ width: '100%' }}
          name="isMarketEntry"
          initialValue={applicationProp.isMarketEntry}
        >
          <Select disabled={disabled}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>

        {additionalFieldName && (<span className="common-field-label">Имеется ли индустриальный партнер для выведения медицинского изделия на рынок</span>)}
        {additionalFieldName && (
        <Form.Item
          style={{ width: '100%' }}
          name="isPartnerExist"
          initialValue={applicationProp.isPartnerExist}
        >
          <Select disabled={disabled} onChange={(e) => setIsPartnerExist(e)}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
        )}

        {additionalFieldName && isPartnerExist === 'Да' && <span className="common-field-label">Наименование организации в соответствии с уставными документами</span>}
        {additionalFieldName && isPartnerExist === 'Да' && (
          <Form.Item
            style={{ width: '100%' }}
            name="partnerFullName"
            initialValue={applicationProp.partnerFullName}
          >
            <Input disabled={disabled} placeholder="Введите наименование организации в соответствии с уставными документами" />
          </Form.Item>
        )}
        {additionalFieldName && isPartnerExist === 'Нет' && <span className="common-field-label">Нужна ли помощь в поиске индустриального партнера</span>}
        {additionalFieldName && isPartnerExist === 'Нет' && (
        <Form.Item
          style={{ width: '100%' }}
          name="isPartnerHelp"
          initialValue={applicationProp.isPartnerHelp}
        >
          <Select disabled={disabled}>
            {[{ id: 'Да', value: 'Да' }, { id: 'Нет', value: 'Нет' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
          </Select>
        </Form.Item>
        )}

        <span className="common-field-hint" style={{ marginTop: '20px' }}>В этом поле укажите, будете ли вы продолжать деятельность в рамках проекта, после того как закончатся средства гранта. Планируется ли вывод результата работ на рынок/внедрения в реальный сектор экономики и какие меры будут для этого приняты.</span>
        <Form.Item
          name="continueDescription"
          initialValue={applicationProp.continueDescription}
        >
          <TextArea autoSize disabled={disabled} minrows={4} placeholder="Введите текст" />
        </Form.Item>
      </div>

    </div>
  );
}

InfoTab.propTypes = {
  applicationProp: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  files: PropTypes.shape().isRequired,
  formRef: PropTypes.shape().isRequired,
  updateOriginalObject: PropTypes.func.isRequired,
};

export default InfoTab;
