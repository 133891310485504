/* eslint-disable prefer-template */
import applicationFields from '../pages/ApplicationStep2/Common/applicationFields';
import fieldsWithSums from '../pages/ApplicationStep2/Common/FinanceTab/fieldsWithSums';


const isEmpty = (value) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }
  return false;
};

const coexecutorsValidation = (application) => {
  const labels = [
    {
      label: 'Вид организации',
      key: 'type',
    },
    {
      label:
        'Организация-Соисполнитель подведомственна Департаменту здравоохранения г. Москвы',
      key: 'isMoscow',
    },
    {
      label:
        'Полное наименование организации (в соответствии с учредительными документами)',
      key: 'fullName',
    },
    {
      label: 'Сокращенное наименование организации',
      key: 'shortName',
    },
    {
      label: 'ОГРН',
      key: 'ogrn',
    },
    {
      label: 'ИНН',
      key: 'inn',
    },
    {
      label: 'Юридический адрес',
      key: 'legalAddress',
    },
    {
      label: 'ФИО',
      key: 'directorFullName',
    },
    {
      label: 'Должность',
      key: 'directorPosition',
    },
    {
      label: 'Телефон',
      key: 'directorPhone',
    },
    {
      label: 'E-mail',
      key: 'directorEmail',
    },
    {
      label: 'ФИО',
      key: 'contactCommonFullName',
    },
    {
      label: 'Должность',
      key: 'contactCommonPosition',
    },
    {
      label: 'Телефон',
      key: 'contactCommonPhone',
    },
    {
      label: 'E-mail',
      key: 'contactCommonEmail',
    },
    {
      label: 'Тема научно-исследовательских работ (НИР) Соисполнителя',
      key: 'theme',
    },
    {
      label: 'Цель выполнения НИР',
      key: 'goal',
    },
  ];

  const rowsCatalogue = [
    {
      number: 1,
      period: 'С даты заключения Договора до 30.06.2025 г.',
    },
    {
      number: 2,
      period: 'С 30.06.2025 до 20.12.2025 г.',
    },
    {
      number: 3,
      period: 'С 10.01.2026 до 30.06.2026 г.',
    },
    {
      number: 4,
      period: 'С 01.07.2026 до 20.12.2026 г.',
    },
    {
      number: 5,
      period: 'С 10.01.2027 до 30.06.2027 г.',
    },
    {
      number: 6,
      period: 'С 01.07.2027 до 20.12.2027 г.',
    },
  ];

  const coexecutors = application.applications2PersonsTabPeople_ids;
  const errors = [];

  if (application.isPersonsParticipate === 'Нет' && coexecutors?.length > 0) {
    errors.push('В окне "Предусмотрены ли в Проекте научно-исследовательские работы, которые будут выполнять организации-Соисполнители?" указано "Нет", однако в заявке указано ' + coexecutors.length + ' Соисполнителя(ей).');
    return errors;
  }

  if (application.isPersonsParticipate === 'Да' && !coexecutors?.length) {
    errors.push('Необходимо указать хотя бы одного соисполнителя');
  }

  coexecutors?.forEach((coexecutor, index) => {
    const rows = [];

    if (coexecutor.isStep1) {
      rows.push(rowsCatalogue[0]);
    }

    if (coexecutor.isStep2) {
      rows.push(rowsCatalogue[1]);
    }

    if (coexecutor.isStep3) {
      rows.push(rowsCatalogue[2]);
    }

    if (coexecutor.isStep4) {
      rows.push(rowsCatalogue[3]);
    }

    if (coexecutor.isStep5) {
      rows.push(rowsCatalogue[4]);
    }

    if (coexecutor.isStep6) {
      rows.push(rowsCatalogue[5]);
    }

    rows.forEach((row, i) => {
      if (isEmpty(coexecutor['step' + row.number + 'Work'])) {
        errors.push('Coисполнитель ' + (i + 1) + ' - Не заполнено поле "Работы, которые подлежат выполнению соисполнителем (этап ' + (i + 1) + ')"');
      }
      if (isEmpty(coexecutor['step' + row.number + 'Result'])) {
        errors.push('Coисполнитель ' + (i + 1) + ' - Не заполнено поле "Ожидаемый результат по результатам завершения работ по этапу (этап ' + (i + 1) + ')"');
      }
      if (isEmpty(coexecutor['step' + row.number + 'Sum'])) {
        errors.push('Coисполнитель ' + (i + 1) + ' - Не заполнено поле "Объем финансирования, руб. (этап ' + (i + 1) + ')"');
      }
    });

    const entriesToExclude = [
      'isStep1',
      'isStep2',
      'isStep3',
      'isStep4',
      'isStep5',
      'isStep6',
      'step1Sum',
      'step2Sum',
      'step3Sum',
      'step4Sum',
      'step5Sum',
      'step6Sum',
      'step1Result',
      'step2Result',
      'step3Result',
      'step4Result',
      'step5Result',
      'step6Result',
      'step1Work',
      'step2Work',
      'step3Work',
      'step4Work',
      'step5Work',
      'step6Work',
      'task',
      'results',
      'requirements',
      'resultsRequirements',
      'docsRequirements',
      'stepTotal',
    ];
    Object.entries(coexecutor).filter(([key, value]) => !entriesToExclude.includes(key)).forEach(([key, v]) => {
      if (!v) {
        const field = labels.find((l) => l.key === key)?.label;
        errors.push('Coисполнитель ' + (index + 1) + '- Не заполнено поле ' + field);
      }
    });

    if (isEmpty(coexecutor.directorPhone)) {
      errors.push('Coисполнитель ' + (index + 1) + '- Сведения о руководителе организации-Соисполнителя (не указан номер телефона)');
    }

    if (!isEmpty(coexecutor.directorPhone)) {
      const directorPhoneFormatted = coexecutor.directorPhone.replace(/[-+()\s]/g, '');

      if (directorPhoneFormatted[1] === '9' && coexecutor.directorSOPDFiles_ids?.length === 0) {
        errors.push('Coисполнитель ' + (index + 1) + '- СОПД руководителя организации-Заявителя (согласие)');
      }
    }

    if (isEmpty(coexecutor.contactCommonPhone)) {
      errors.push('Coисполнитель ' + (index + 1) + '- Сведения об ответственном контактном лице от Соисполнителя (не указан номер телефона)');
    }

    if (coexecutor.contactCommonSOPDFiles_ids?.length === 0) {
      errors.push('Coисполнитель ' + (index + 1) + '- СОПД ответственного контактного лице от Соисполнителя (согласие)');
    }

    if (!coexecutor.isStep1 && !coexecutor.isStep2 && !coexecutor.isStep3 && !coexecutor.isStep4 && !coexecutor.isStep5 && !coexecutor.isStep6) {
      errors.push('Coисполнитель ' + (index + 1) + '- Должен быть отмечен хотя бы один период (4.3)');
    }

    if (isEmpty(coexecutor.confirmEmail_id?.name)) {
      errors.push('Coисполнитель ' + (index + 1) + ' - Согласие Соисполнителя выполнять работы НИР в рамках Проекта');
    }
  });

  return errors;
};

const smetaValidation = (application) => {
  const validationChecks = {
    2023: [
      {
        field: 'salaryDoctorAmount2023',
        message: '5.1 - Количество врачей (2025)',
      },
      {
        field: 'salaryMiddleAmount2023',
        message: '5.1 - Количество cреднего медицинского персонала (2025)',
      },
      {
        field: 'salaryJuniorAmount2023',
        message: '5.1 - Количество младшего медицинского персонала (2025)',
      },
      {
        field: 'salaryOtherAmount2023',
        message: '5.1 - Количество прочих сотрудников (2025)',
      },
      {
        field: 'salarySupervisorDescription2023',
        message: '5.1 - Краткое описание функционала научного руководителя (2025)',
      },
      {
        field: 'salarySupervisorTotal2023',
        message: '5.1 - Годовой бюджет на оплату труда научного руководителя (2025)',
      },
      {
        qtyField: 'salaryDoctorAmount2023',
        field: 'salaryDoctorTotal2023',
        message: '5.1 - Годовой бюджет на оплату труда врачей (2025)',
      },
      {
        qtyField: 'salaryDoctorAmount2023',
        field: 'salaryDoctorDescription2023',
        message: '5.1 - Краткое описание функционала врачей (2025)',
      },
      {
        qtyField: 'salaryMiddleAmount2023',
        field: 'salaryMiddleDescription2023',
        message: '5.1 - Краткое описание функционала cреднего медицинского персонала (2025)',
      },
      {
        qtyField: 'salaryMiddleAmount2023',
        field: 'salaryMiddleTotal2023',
        message: '5.1 - Годовой бюджет на оплату труда среднего медицинского персонала (2025)',
      },
      {
        qtyField: 'salaryJuniorAmount2023',
        field: 'salaryJuniorDescription2023',
        message: '5.1 - Краткое описание функционала младшего медицинского персонала (2025)',
      },
      {
        qtyField: 'salaryJuniorAmount2023',
        field: 'salaryJuniorTotal2023',
        message: '5.1 - Годовой бюджет на оплату труда младшего медицинского персонала (2025)',
      },
      {
        qtyField: 'salaryOtherAmount2023',
        field: 'salaryOtherTotal2023',
        message: '5.1 - Годовой бюджет на оплату труда прочих сотрудников (2025)',
      },
      {
        qtyField: 'salaryOtherAmount2023',
        field: 'salaryOtherDescription2023',
        message: '5.1 - Краткое описание функционала прочих сотрудников (2025)',
      },
    ],
    2024: [
      {
        field: 'salaryDoctorAmount2024',
        message: '5.1 - Количество врачей (2026)',
      },
      {
        field: 'salaryMiddleAmount2024',
        message: '5.1 - Количество cреднего медицинского персонала (2026)',
      },
      {
        field: 'salaryJuniorAmount2024',
        message: '5.1 - Количество младшего медицинского персонала (2026)',
      },
      {
        field: 'salaryOtherAmount2024',
        message: '5.1 - Количество прочих сотрудников (2026)',
      },
      {
        field: 'salarySupervisorDescription2024',
        message: '5.1 - Краткое описание функционала научного руководителя (2026)',
      },
      {
        field: 'salarySupervisorTotal2024',
        message: '5.1 - Годовой бюджет на оплату труда научного руководителя (2026)',
      },
      {
        qtyField: 'salaryDoctorAmount2024',
        field: 'salaryDoctorTotal2024',
        message: '5.1 - Годовой бюджет на оплату труда врачей (2026)',
      },
      {
        qtyField: 'salaryDoctorAmount2024',
        field: 'salaryDoctorDescription2024',
        message: '5.1 - Краткое описание функционала врачей (2026)',
      },
      {
        qtyField: 'salaryMiddleAmount2024',
        field: 'salaryMiddleDescription2024',
        message: '5.1 - Краткое описание функционала cреднего медицинского персонала (2026)',
      },
      {
        qtyField: 'salaryMiddleAmount2024',
        field: 'salaryMiddleTotal2024',
        message: '5.1 - Годовой бюджет на оплату труда среднего медицинского персонала (2026)',
      },
      {
        qtyField: 'salaryJuniorAmount2024',
        field: 'salaryJuniorDescription2024',
        message: '5.1 - Краткое описание функционала младшего медицинского персонала (2026)',
      },
      {
        qtyField: 'salaryJuniorAmount2024',
        field: 'salaryJuniorTotal2024',
        message: '5.1 - Годовой бюджет на оплату труда младшего медицинского персонала (2026)',
      },
      {
        qtyField: 'salaryOtherAmount2024',
        field: 'salaryOtherDescription2024',
        message: '5.1 - Краткое описание функционала прочих сотрудников (2026)',
      },
      {
        qtyField: 'salaryOtherAmount2024',
        field: 'salaryOtherTotal2024',
        message: '5.1 - Годовой бюджет на оплату труда прочих сотрудников (2026)',
      },
    ],
    2025: [
      {
        field: 'salaryDoctorAmount2025',
        message: '5.1 - Количество врачей (2027)',
      },
      {
        field: 'salaryMiddleAmount2025',
        message: '5.1 - Количество cреднего медицинского персонала (2027)',
      },
      {
        field: 'salaryJuniorAmount2025',
        message: '5.1 - Количество младшего медицинского персонала (2027)',
      },
      {
        field: 'salaryOtherAmount2025',
        message: '5.1 - Количество прочих сотрудников (2027)',
      },
      {
        field: 'salarySupervisorDescription2025',
        message: '5.1 - Краткое описание функционала научного руководителя (2027)',
      },
      {
        field: 'salarySupervisorTotal2025',
        message: '5.1 - Годовой бюджет на оплату труда научного руководителя (2027)',
      },
      {
        qtyField: 'salaryDoctorAmount2025',
        field: 'salaryDoctorTotal2025',
        message: '5.1 - Годовой бюджет на оплату труда врачей (2027)',
      },
      {
        qtyField: 'salaryDoctorAmount2025',
        field: 'salaryDoctorDescription2025',
        message: '5.1 - Краткое описание функционала врачей (2027)',
      },
      {
        qtyField: 'salaryMiddleAmount2025',
        field: 'salaryMiddleDescription2025',
        message: '5.1 - Краткое описание функционала cреднего медицинского персонала (2027)',
      },
      {
        qtyField: 'salaryMiddleAmount2025',
        field: 'salaryMiddleTotal2025',
        message: '5.1 - Годовой бюджет на оплату труда среднего медицинского персонала (2027)',
      },
      {
        qtyField: 'salaryJuniorAmount2025',
        field: 'salaryJuniorDescription2025',
        message: '5.1 - Краткое описание функционала младшего медицинского персонала (2027)',
      },
      {
        qtyField: 'salaryJuniorAmount2025',
        field: 'salaryJuniorTotal2025',
        message: '5.1 - Годовой бюджет на оплату труда младшего медицинского персонала (2027)',
      },
      {
        qtyField: 'salaryOtherAmount2025',
        field: 'salaryOtherDescription2025',
        message: '5.1 - Краткое описание функционала прочих сотрудников (2027)',
      },
      {
        qtyField: 'salaryOtherAmount2025',
        field: 'salaryOtherTotal2025',
        message: '5.1 - Годовой бюджет на оплату труда прочих сотрудников (2027)',
      },
    ],
  };
  const errors = [];

  const years = [];
  if (['1', '2', '3'].includes(application.projectTerm)) {
    years.push('2023');
  }
  if (['2', '3'].includes(application.projectTerm)) {
    years.push('2024');
  }
  if (['3'].includes(application.projectTerm)) {
    years.push('2025');
  }


  // Зарпланая таблица
  years.forEach((year) => {
    fieldsWithSums.forEach((sumField) => {
      if (typeof application[`${sumField}${year}_major`] === 'object') {
        const check = validationChecks[year].find(({ field }) => field === `${sumField}${year}`);

        if (check.qtyField) {
          if (application[check.qtyField] > 0) {
            errors.push(check.message);
          }
        }

        if (!check.qtyField) {
          errors.push(check.message);
        }
      }
    });
  });

  years.forEach((year) => {
    validationChecks[year].forEach(({ qtyField, field, message }) => {
      if (qtyField) {
        if (application[qtyField] > 0 && (!application[field])) {
          errors.push(message);
        }
      }

      if (!qtyField) {
        if (typeof application[field] !== 'number' && !application[field]) {
          errors.push(message);
        }
      }
    });
  });


  // Лимиты сметы
  // fot: 70
  // material + equipment: 80
  // other: 40
  // paper: 20
  // people: 60

  if (application.fotPercent > 70 && !application.financeFiles_ids?.length) {
    errors.push('5. Смета расходов проекта. п1 - превышен лимит расходов');
  }

  if (application.materialPercent + application.equipmentPercent > 80 && !application.financeFiles_ids?.length) {
    errors.push('5. Смета расходов проекта. п2+п3 - превышен лимит расходов');
  }

  if (application.otherPercent > 40 && !application.financeFiles_ids?.length) {
    errors.push('5. Смета расходов проекта. п4 - превышен лимит расходов');
  }

  if (application.paperPercent > 20 && !application.financeFiles_ids?.length) {
    errors.push('5. Смета расходов проекта. п5 - превышен лимит расходов');
  }

  if (application.peoplePercent > 60 && !application.financeFiles_ids?.length) {
    errors.push('5. Смета расходов проекта. п6 - превышен лимит расходов');
  }

  if (application.fotPercent > 70 || (application.materialPercent + application.equipmentPercent > 80)
              || application.otherPercent > 40 || application.paperPercent > 20 || application.peoplePercent > 60) {
    if (!application.financeFiles_ids?.length) {
      errors.push('5. Смета расходов проекта. Письмо обоснование');
    }
  }

  if (!application.financeTotalMajor || application.financeTotalMajor === 0) {
    errors.push('6. Смета расходов проекта - 0 руб.');
  }

  const term = application.projectTerm || '0';
  for (let i = 1; i <= parseInt(term, 10); i += 1) {
    if (!application[`step${i}PlanWorkDescription`].length) {
      errors.push('6 - Не заполнено поле "Содержание выполняемых работ и мероприятий" (этап ' + (i) + ')');
    }

    if (!application[`step${i}PlanWorkResult`].length) {
      errors.push('6 - Не заполнено поле "Ожидаемый результат" (этап ' + (i) + ')');
    }
  }

  return errors;
};


export const approveValidation = (application) => {
  const smetTablesData = application.applications2Costs_ids.filter((el) => !el.isTotal);
  let isError = false;

  const tabs = [
    { name: 'shortTab', label: 'Краткая информация о Проекте:', errorFields: [] },
    { name: 'infoTab', label: 'Сведения о проекте:', errorFields: [] },
    {
      name: 'applications2InfoTabTasks_ids', label: 'Задачи Проекта', errorFields: [], isInner: true,
    },
    { name: 'authorTab', label: 'Сведения о Заявителе и команде Проекта:', errorFields: [] },
    { name: 'coexecutors', label: 'Сведения о соисполнителях:', errorFields: [] },
    { name: 'finance', label: 'Смета и план-график реализации проекта:', errorFields: [] },
  ];

  tabs.forEach((tab) => {
    // Сведения о проекте
    if (tab.name === 'infoTab') {
      if (isEmpty(application.resultType)) {
        isError = true;
        tab.errorFields.push('п 1.5 - не выбран вид результата');
      }

      if (application.resultType === 'Другое') {
        if (isEmpty(application.resultValues)) {
          isError = true;
          tab.errorFields.push('п 1.5 - не введено наименование');
        }
      }

      if (application.resultType === 'Информационная технология') {
        if (isEmpty(application.resultSubType) && isEmpty(application.resultValues)) {
          isError = true;
          tab.errorFields.push('п 1.5 - не введено наименование');
        }
        if (application.resultSubType === 'Другое') {
          if (isEmpty(application.resultValues)) {
            isError = true;
            tab.errorFields.push('п 1.5 - не введено наименование');
          }
        }
      }

      if ((application.isPowerPeople && isEmpty(application.powerPeopleReason)) || (!application.isPowerPeople && isEmpty(application.power))) {
        isError = true;
        tab.errorFields.push('1.7 Мощность исследования');
      }


      if (application.resultType === 'Медицинское изделие') {
        if (isEmpty(application.resultSubType)) {
          isError = true;
          tab.errorFields.push('п 1.5 - не выбран тип');
        }
        if (application.resultSubType === 'Прочее') {
          if (isEmpty(application.resultValues)) {
            isError = true;
            tab.errorFields.push('п 1.5 - не введено наименование');
          }
        }
        if (application.resultSubType === 'Омиксные технологии, в том числе генетические') {
          if (isEmpty(application.resultValues)) {
            isError = true;
            tab.errorFields.push('п 1.5 - не выбран подтип');
          }
        }

        if (!isEmpty(application.resultValues)) {
          if (isEmpty(application.epidemicMoscowAdditional)) {
            isError = true;
            tab.errorFields.push('п 2.1 - не введено в Москве');
          }
          if (isEmpty(application.epidemicRussiaAdditional)) {
            isError = true;
            tab.errorFields.push('п 2.1 - не введено в России');
          }
          if (isEmpty(application.economyAdditional)) {
            isError = true;
            tab.errorFields.push('п 2.3 - стоимость за единицу');
          }
          if (isEmpty(application.isEconomyCheaper)) {
            isError = true;
            tab.errorFields.push('п 2.3 - цена единицы меньше');
          }
          if (isEmpty(application.isHelpPlace)) {
            isError = true;
            tab.errorFields.push('п 2.4 - Нужна ли помощь в подборе производственной площадки?');
          }

          if (application.isHelpPlace === 'Нет') {
            if (isEmpty(application.orgProducer1)) {
              isError = true;
              tab.errorFields.push('п 2.4 - Какая организация будет производить?');
            }
          }

          if (isEmpty(application.isClinicChangesRequired)) {
            isError = true;
            tab.errorFields.push('п 2.4 - Потребуется внесение изменений в клинические рекомендации для внедрения');
          }
          if (isEmpty(application.isClinicChangesPlaned)) {
            isError = true;
            tab.errorFields.push('п 2.4 - Планируете ли вы заняться внесением изменений в клинические рекомендации');
          }
          if (isEmpty(application.whyBetterAnalog)) {
            isError = true;
            tab.errorFields.push('п 2.5 - В чем преимущество перед аналогами');
          }

          if (isEmpty(application.isPartnerExist)) {
            isError = true;
            tab.errorFields.push('п 2.6 - Имеется ли индустриальный партнер для выведения медицинского изделия на рынок');
          }

          if (application.isPartnerExist === 'Да') {
            if (isEmpty(application.partnerFullName)) {
              isError = true;
              tab.errorFields.push('п 2.6 - Наименование организации в соответствии с уставными документами');
            }
          }
          if (application.isPartnerExist === 'Нет') {
            if (isEmpty(application.isPartnerHelp)) {
              isError = true;
              tab.errorFields.push('п 2.6 - Нужна ли помощь в поиске индустриального партнера');
            }
          }
        }
      }

      if (application.resultType === 'Другое' && isEmpty(application.resultValues)) {
        isError = true;
        tab.errorFields.push('Вид результата должен быть заполнен');
      }

      if (application.resultValues) {
        if (!application.isPatent1?.length) {
          isError = true;
          tab.errorFields.push('п 2.4 - Предполагается ли получение патента?');
        }

        if (!application.isWorldPatent1?.length) {
          isError = true;
          tab.errorFields.push('п 2.4 - Планируется ли получения международного патента?');
        }

        if (!application.isHelpPatent1?.length) {
          isError = true;
          tab.errorFields.push('п 2.4 - Нужна ли помощь Центра в получении патента?');
        }
      }

      if (!application.isRegPaper?.length) {
        isError = true;
        tab.errorFields.push('п 2.4 - Планируется ли получение регистрационного удостоверения?');
      }

      if (application.isRegPaper === 'Да') {
        if (!application.isRegPapeDuringGrant?.length) {
          isError = true;
          tab.errorFields.push('п 2.4 - Получение регистрационного удостоверения планируется в ходе выполнения работ по гранту?');
        }

        if (!application.regPaperRightsOwner?.length) {
          isError = true;
          tab.errorFields.push('п 2.4 - Кому будут принадлежать права на регистрационное удостоверение?');
        }

        if (!application.isRegPapeDuringGrant === 'Да') {
          if (!application.isRegPapeDuringGrantHelpCenter?.length) {
            isError = true;
            tab.errorFields.push('п 2.4 - Нужна помощь Центра в получении регистрационного удостоверения?');
          }
        }
      }

      if (!application.isNoAnalogs && !application.applications2InfoTabAnalog_ids?.length) {
        isError = true;
        tab.errorFields.push('п. 2.5. Наличие конкурирующих решений');
      }
    }

    // Краткая информация о проекте
    if (tab.name === 'shortTab') {
      if (application.scientificDirection === 'Иное' && isEmpty(application.customScientificDirection)) {
        isError = true;
        tab.errorFields.push('Научное направление Проекта');
      }

      if (application.projectName.split(' ').filter((el) => el.length > 3).length > 25) {
        isError = true;
        tab.errorFields.push('Название Проекта - не более 25 слов');
      }

      if (isEmpty(application.directorPhone)) {
        isError = true;
        tab.errorFields.push('СОПД руководителя организации-Заявителя (не указан номер телефона)');
      }

      if (!isEmpty(application.directorPhone)) {
        const directorPhoneFormatted = application.directorPhone.replace(/[-+()\s]/g, '');

        if (directorPhoneFormatted[1] === '9' && application.directorSOPDFiles_ids?.length === 0) {
          isError = true;
          tab.errorFields.push('СОПД руководителя организации-Заявителя (согласие)');
        }
      }

      if (application.selectedScientificDirection === 'Иное' && application.customScientificDirection?.length) {
        isError = true;
        tab.errorFields.push('Научное направление Проекта (иное) - наименование');
      }
    }

    // Информация о соисполнителях
    if (tab.name === 'coexecutors') {
      const errors = coexecutorsValidation(application);

      if (errors.length) {
        isError = true;

        tab.errorFields.push(...errors);
      }
    }

    // Смета и план реализации
    if (tab.name === 'finance') {
      smetTablesData.forEach((el) => {
        let tableName = '';
        let name = '';
        let description = '';

        switch (el.type) {
          case 'material':
            tableName = '5.2';
            name = 'Перечень расходных материалов и комплектующих, планируемых к приобретению для выполнения работ по Проекту';
            description = 'Коротко опишите, как будут использованы данные расходные материалы и комплектующие';
            break;
          case 'equipment':
            tableName = '5.3';
            name = 'Перечень оборудования, планируемого к приобретению для выполнения работ по Проекту';
            description = 'Коротко опишите, как будет использовано данное оборудование при выполнении работ';
            break;
          case 'other':
            tableName = '5.4';
            name = 'Перечень прочих прямых расходов, планируемых для выполнения работ по Проекту';
            description = 'Коротко опишите необходимость данных видов расходов для реализации Проекта';
            break;
          case 'paper':
            tableName = '5.5';
            name = 'Перечень накладных и общехозяйственных расходов, предусмотренных учетной политикой организации, и необходимых для реализации Проекта';
            description = 'Коротко опишите необходимость данных видов расходов для реализации Проекта';
            break;
          default:
            break;
        }

        if (isEmpty(el.name) && !tab.errorFields.includes(`${tableName} - ${name}`)) {
          isError = true;
          tab.errorFields.push(`${tableName} - ${name}`);
        }

        if (isEmpty(el.description) && !tab.errorFields.includes(`${tableName} - ${description}`)) {
          isError = true;
          tab.errorFields.push(`${tableName} - ${description}`);
        }
      });

      const additionalErrors = smetaValidation(application);

      if (additionalErrors.length) {
        isError = true;

        tab.errorFields.push(...additionalErrors);
      }
    }

    if (tab.isInner && Array.isArray(applicationFields[tab.name])) {
      if (application[tab.name].length < 1) {
        tab.errorFields.push('Должно быть не менее 1 задачи проекта');
        isError = true;
      }

      application[tab.name].forEach((el) => {
        applicationFields[tab.name].forEach((f) => {
          if (f.type === 'field') {
            if (isEmpty(el[f.name]) || (f.maxLength && el[f.name].length > f.maxLength) || (f.minLength && el[f.name].length < f.minLength)) {
              tab.errorFields.push(f.label);
              isError = true;
            }
          } else if (f.type === 'array') {
            if (el[f.name].length === 0) {
              tab.errorFields.push(f.label);
              isError = true;
            }
          }
        });
      });
    } else if (Array.isArray(applicationFields[tab.name])) {
      // eslint-disable-next-line no-restricted-syntax
      for (const f of applicationFields[tab.name]) {
        if (f.label === 'Аннотация Проекта' && tab.errorFields.includes('Аннотация Проекта')) {
          // eslint-disable-next-line no-continue
          continue;
        }

        if (f.type === 'field') {
          // eslint-disable-next-line max-len
          if (isEmpty(application[f.name]) || (f.maxLength && application[f.name].length > f.maxLength) || (f.minLength && application[f.name].length < f.minLength)) {
            tab.errorFields.push(f.label);
            isError = true;
          }
        } else if (f.type === 'array') {
          if (application[f.name].length === 0) {
            tab.errorFields.push(f.label);
            isError = true;
          }
        } else if (f.type === 'boolean') {
          if (!application[f.name]) {
            tab.errorFields.push(f.label);
            isError = true;
          }
        }
      }
    }
  });

  return { isError, tabs };
};
