import React, { useState, useEffect } from 'react';
import {
  Spin, Breadcrumb, Upload, notification, Button, Modal,
} from 'antd';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {
  UploadOutlined, LoadingOutlined,
} from '@ant-design/icons';
import ProjectBody from './components/ProjectBody/ProjectBody';
import Sopd from './components/Sopd/Sopd';
import { routes } from '../../utils/routes';
import {
  editApplicationStepReportExpert, getExpertProjectByID, getExpertReportURL, uploadFile,
} from '../../utils/http';
import getUserRole from '../../utils/localeStorage/getUserRole';

import './Project.less';


function Application({ history }) {
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(null);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const isAdmin = getUserRole() === 'bigAdmin';
  const [isShowSuccessNotification, setIsShowSuccessNotification] = useState(false);

  useEffect(() => {
    const id = history.location.pathname.split('/')[2];
    getExpertProjectByID(id)
      .then((data) => {
        setApplication(data);
        setLoading(false);
      });
  }, []);

  const onSave = async (values, isPrint) => {
    setLoading(true);
    await editApplicationStepReportExpert(values);
    if (!isPrint) {
      setLoading(false);
    }
  };

  const onPrint = () => {
    setLoading(true);
    getExpertReportURL(application.id)
      .then((url) => {
        window.open(url, '_blank');
        setLoading(false);
      });
  };

  const onUpload = () => setIsShowAddModal(true);

  const onSaveFile = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      if (info.file.type !== 'application/pdf') {
        notification.error({ message: 'Ошибка! Загрузите, пожалуйста, файл в формате PDF' });
        return;
      }
      setLoading(true);
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          editApplicationStepReportExpert({ id: application.id, printFormUrlSigned: file.urlLink })
            .then(() => {
              setLoading(false);
              setIsShowAddModal(false);
              notification.success({ message: 'Сохранено!' });
            });
        });
    }
  };

  const onSaveSopd = (data) => {
    editApplicationStepReportExpert({ ...data, sopdDate: new Date().toISOString() })
      .then(() => {
        if (data.sopdStatus === 'yes') {
          setIsShowSuccessNotification(true);
          setApplication({ ...application, ...data });
        } else {
          history.push(routes.home);
        }
      });
  };

  const onClearSopd = () => {
    editApplicationStepReportExpert({
      id: application.id, sopdDate: '', sopdStatus: '', sopdDeclineReason: '',
    })
      .then(() => notification.success({ message: 'СОПД успешно сброшено' }));
  };

  const onDeleteProject = () => {
    editApplicationStepReportExpert({
      id: application.id, isDeleted: true,
    })
      .then(() => history.push(routes.home));
  };

  return (
    <div className="application-wrapper" style={{ height: 'initial' }}>
      {loading && (
      <Spin
        style={{ position: 'fixed', fontSize: '60px', top: '30%' }}
        indicator={(<LoadingOutlined style={{ fontSize: '100px', margin: '-80px 0px 0px -60px' }} spin />)}
        tip="Загрузка..."
      >
        <div />
      </Spin>
      )}
      <Breadcrumb style={{ marginBottom: '20px' }}>
        <Breadcrumb.Item>
          <a href={routes.homePage}>
            Список заявок
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {`Заявка №${application?.displayID}`}
        </Breadcrumb.Item>
      </Breadcrumb>
      {application && (application.sopdStatus === 'yes' || isAdmin) && (
        <ProjectBody
          application={application}
          disabled={loading}
          onSave={onSave}
          onPrint={onPrint}
          onUpload={onUpload}
          isShowSuccessNotification={isShowSuccessNotification}
          onClearSopd={onClearSopd}
          onDeleteProject={onDeleteProject}
        />
      )}

      {application && (!application.sopdStatus || application.sopdStatus === 'no') && !isAdmin && (
      <Sopd application={application} onSaveSopd={onSaveSopd} />
      )}

      <Modal title="Загрузка файла" visible={isShowAddModal} footer={null}>
        <div style={{ marginTop: '20px' }}>
          <span style={{ color: '#212121' }}>
            Прикрепите пожалуйста подписанную скан-копию в формате pdf
          </span>
          <Upload
            showUploadList={false}
            onChange={(f) => onSaveFile(f)}
            disabled={loading}
            multiple
            action={null}
            style={{ width: '100%' }}
          >
            <Button disabled={loading} type="primary" icon={<UploadOutlined />}>Выберите файл, чтобы загрузить (не более 15мб)</Button>
          </Upload>
        </div>
      </Modal>
    </div>
  );
}

Application.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(Application);
