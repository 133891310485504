/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { getUser, getUsers, editUser } from '../../utils/http';
import { setLocalStorage } from '../../utils/localeStorage/storage';
import api from '../../utils/directualAPI/api';
import logo from '../../assets/images/logo.svg';
import './LoginPage.less';


function LoginPage({ history, location }) {
  const [loading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [isShowRegModal, setIsShowRegModal] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  useEffect(() => {
    getUsers().then((resp) => {
      setIsLoading(false);
      setUsers(resp);
    });
  }, []);

  const onLogin = () => {
    setErrorFields([]);
    const login = document.getElementById('login-login')?.value || null;
    const pass = document.getElementById('login-pass')?.value || null;
    const errors = [];
    if (!login) {
      errors.push('login');
    }
    if (!pass) {
      errors.push('pass');
    }
    if (errors.length > 0) {
      setErrorFields(errors);
      return;
    }

    const username = [
      ...users,
      { id: login, shortName: login, email: login },
    ].find((u) => u.shortName === login || u.email === login).id;
    api
      .structure()
      .auth(username, pass)
      .then((response) => {
        if (response.isError) {
          setErrorFields(['login', 'pass']);
          setIsLoading(false);
        } else {
          getUser(username, response.token).then((user) => {
            const payload = {
              token: response.token,
              ...user,
              birthdayDate: user.birthdayDate
                ? user.birthdayDate.toISOString()
                : '',
            };
            setLocalStorage(payload);
            setIsLoading(false);
            const from = get(location, 'state.from.pathname', '/homePage');
            history.push(from);
          });
        }
      });
  };

  const onRegister = () => {
    setErrorFields([]);
    const fields = [
      'lastName',
      'position',
      'firstName',
      'phone',
      'middleName',
      'email',
      'fullName',
    ];
    const values = {};
    const errors = [];
    fields.forEach((field) => {
      const fieldName = `reg-${field}`;
      values[field] = document.getElementById(fieldName)?.value || null;
      if (!values[field]) {
        errors.push(field);
      }
    });

    const isDzm = document.getElementById('reg-isDzm')?.checked || null;
    values.isDzm = isDzm;

    if (errors.length > 0) {
      setErrorFields(errors);
      return;
    }

    if (errorFields.length === 0) {
      setIsLoading(true);
      editUser(values).then(() => {
        setIsLoading(false);
        setIsShowRegModal(false);
        setIsShowSuccess(true);
      });
    }
  };

  return (
    <div className="welcome-page">
      <div className="welcome-page__wrapper">
        <div className="welcome-page__header">
          <div className="welcome-page__title">Грантовая программа</div>
          <img src={logo} alt="logo" className="welcome-page__logo" />
        </div>
        <div className="welcome-page__notice">
          Внимание!
          {' '}
          <br />
          Срок приема заявок продлен до&nbsp;10&nbsp;октября 2024&nbsp;г.
        </div>
        <div
          className="welcome-page__content step-list"
          style={{ justifyContent: 'start', gap: '30px' }}
        >
          <div className="step-list__item">
            <div className="step-list__desc">
              <p>Прием заявок от&nbsp;медицинских организаций</p>
            </div>
            <div className="step-list__date">
              <span>22 июля 2024 –</span>
              <span>10 октября 2024</span>
            </div>
          </div>
          <div className="step-list__item">
            <div className="step-list__desc">
              <p>Экспертиза заявок</p>
            </div>
            <div className="step-list__date">
              <span>11 октября 2024 –</span>
              <span>20 ноября 2024</span>
            </div>
          </div>
          <div className="step-list__item">
            <div className="step-list__desc">
              <p>Объявление победителей</p>
            </div>
            <div className="step-list__date">29 ноября 2024</div>
          </div>
          {/* <div className="step-list__item">
            <div className="step-list__desc">
              <p>Заключение грантовых Соглашений</p>
            </div>
            <div className="step-list__date">
              <span>10 ноября 2024 –</span>
              {' '}
              <span>01 января 2025</span>
            </div>
          </div> */}
        </div>
        <div className="welcome-page__btns">
          <div
            className="welcome-page__btn w-btn w-btn__trans"
            onClick={() => setIsShowRegModal(true)}
            onKeyDown={() => {}}
          >
            Регистрация
          </div>
          <div
            className="welcome-page__btn w-btn w-btn__white"
            onClick={() => setIsShowLoginModal(true)}
            onKeyDown={() => {}}
          >
            Вход
          </div>
        </div>
      </div>

      <div
        className="auth-form login-form"
        style={{ display: isShowLoginModal ? '' : 'none' }}
      >
        <span
          className="window-close icon-closet"
          onClick={() => {
            setIsShowLoginModal(false);
          }}
          onKeyDown={() => {}}
        />
        <div className="auth-form_scroll">
          <div className="auth-form__title">
            <h3>Добро пожаловать</h3>
            <p>
              Программа‌ ‌финансовой‌ ‌поддержки‌ ‌проведения‌ ‌научных,‌
              ‌клинических‌ ‌и‌ экспериментальных‌ ‌работ‌ ‌по‌
              ‌усовершенствованию‌ ‌методов‌ ‌диагностики‌ ‌и‌ лечения,‌
              ‌направленных‌ ‌на‌ ‌создание‌ ‌высокотехнологичных‌ ‌продуктов
            </p>
          </div>
          <form className="form-common login-form__form">
            <div className="form-common__input">
              <h4>Логин</h4>
              <input
                id="login-login"
                className={`form-input ${
                  errorFields.includes('login') ? 'error' : ''
                }`}
                disabled={loading}
              />
            </div>
            <div className="form-common__input">
              <h4>Пароль</h4>
              <div className="form-input__pass-wrapper">
                <input
                  id="login-pass"
                  className={`form-input form-input__pass ${
                    errorFields.includes('pass') ? 'error' : ''
                  }`}
                  disabled={loading}
                  type={isShowPassword ? 'text' : 'password'}
                />
                <div
                  className="pass-watch"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  onKeyDown={() => {}}
                />
              </div>
            </div>
            <button
              type="button"
              className="w-btn w-btn__blue"
              disabled={loading}
              onClick={onLogin}
            >
              Войти
            </button>
          </form>
        </div>
      </div>

      <div
        className="auth-form registration-form"
        style={{ display: isShowRegModal ? '' : 'none' }}
      >
        <span
          className="window-close icon-closet"
          onClick={() => {
            setIsShowRegModal(false);
          }}
          onKeyDown={() => {}}
        />
        <div className="auth-form_scroll">
          <div className="auth-form__title">
            <h3>Регистрация</h3>
            <p>
              Программа‌ ‌финансовой‌ ‌поддержки‌ ‌проведения‌ ‌научных,‌
              ‌клинических‌ ‌и‌ экспериментальных‌ ‌работ‌ ‌по‌
              ‌усовершенствованию‌ ‌методов‌ ‌диагностики‌ ‌и‌ лечения,‌
              ‌направленных‌ ‌на‌ ‌создание‌ ‌высокотехнологичных‌ ‌продуктов
            </p>
          </div>
          <form className="form-common login-form__form">
            <div className="form-common__input">
              <div className="form-common__input_half">
                <h4>
                  Фамилия
                  <span>*</span>
                </h4>
                <input
                  id="reg-lastName"
                  className={`form-input ${
                    errorFields.includes('lastName') ? 'error' : ''
                  }`}
                  disabled={loading}
                />
              </div>
              <div className="form-common__input_half">
                <h4>
                  Должность
                  <span>*</span>
                </h4>
                <input
                  id="reg-position"
                  className={`form-input ${
                    errorFields.includes('position') ? 'error' : ''
                  }`}
                />
              </div>
            </div>
            <div className="form-common__input">
              <div className="form-common__input_half">
                <h4>
                  Имя
                  <span>*</span>
                </h4>
                <input
                  id="reg-firstName"
                  className={`form-input ${
                    errorFields.includes('firstName') ? 'error' : ''
                  }`}
                  disabled={loading}
                />
              </div>
              <div className="form-common__input_half">
                <h4>
                  Телефон
                  <span>*</span>
                </h4>
                <input
                  id="reg-phone"
                  className={`form-input ${
                    errorFields.includes('phone') ? 'error' : ''
                  }`}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="form-common__input">
              <div className="form-common__input_half">
                <h4>
                  Отчество
                  <span>*</span>
                </h4>
                <input
                  id="reg-middleName"
                  className={`form-input ${
                    errorFields.includes('middleName') ? 'error' : ''
                  }`}
                  disabled={loading}
                />
              </div>
              <div className="form-common__input_half">
                <h4>
                  Email
                  <span>*</span>
                </h4>
                <input
                  id="reg-email"
                  className={`form-input ${
                    errorFields.includes('email') ? 'error' : ''
                  }`}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="form-common__input">
              <div className="form-common__input_half">
                <h4>
                  Название организации
                  <span>*</span>
                </h4>
                <input
                  id="reg-fullName"
                  className={`form-input ${
                    errorFields.includes('fullName') ? 'error' : ''
                  }`}
                  disabled={loading}
                />
              </div>
              <div className="form-common__input_half form-common__input_check">
                <input
                  type="checkbox"
                  className="form-common__check custom-checkbox"
                  name="circle-check"
                  id="reg-isDzm"
                  disabled={loading}
                />
                <label htmlFor="reg-isDzm">
                  Организация подведомственна Департаменту здваоохранения г.
                  Москвы
                </label>
              </div>
            </div>
            <div className="auth-form__btns">
              <button
                type="button"
                className="w-btn w-btn__green"
                onClick={onRegister}
                disabled={loading}
              >
                Зарегистрировать
              </button>
              <div className="auth-form__param">
                <span>*</span>
                — обязательные поля для заполнения
              </div>
            </div>
          </form>
        </div>
      </div>
      {isShowSuccess && (
        <div className="success">
          <h3>Ваша заявка принята на рассмотрение</h3>
          <p>
            Благодарим Вас за регистрацию на портале грантовой программы Мэра
            Москвы по поддержке научно-практических проектов в сфере
            здравоохранения. Ваша заявка на регистрацию будет рассмотрена в
            ближайшее время, уведомление о результате будет отправлено на
            указанный Вами адрес электронной почты
          </p>
          <p>
            <b>Примечание: </b>
            Пожалуйста, обратите внимание, что в некоторых случаях
            письмо с уведомлением может автоматически попасть в папку «Спам»
            Вашего электронного почтового ящика. Если Вы не получите сообщение,
            пожалуйста, проверьте папку «Спам» и при необходимости добавьте
            официальный адрес электронной почты грантовой программы
            medtechapplications@yandex.ru в список надежных отправителей.
          </p>

          <button
            type="button"
            className="w-btn w-btn__green"
            onClick={() => setIsShowSuccess(false)}
            onKeyDown={() => {}}
          >
            Хорошо, спасибо за информацию!
          </button>
        </div>
      )}
      <div
        className="shadow-block"
        style={{
          display:
            isShowLoginModal || isShowRegModal || isShowSuccess ? '' : 'none',
        }}
        onClick={() => {
          setIsShowLoginModal(false);
          setIsShowRegModal(false);
        }}
        onKeyDown={() => {}}
      />
    </div>
  );
}

LoginPage.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  // intl: PropTypes.shape().isRequired,
};

export default withRouter(LoginPage);
