/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, Button, Card, Col, Row, Popconfirm, notification, DatePicker, Modal, Checkbox, InputNumber, Upload,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined,
} from '@ant-design/icons';
import AuthorTabPublicationsModule from './AuthorTabPublicationsModule';
import AuthorTabEventModule from './AuthorTabEventModule';
import AuthorTabRidModule from './AuthorTabRidModule';
import { editApplications2AuthorTabPeople, uploadFile } from '../../../../utils/http';


const labels = [
  {
    label: 'Фамилия',
    key: 'lastName',
  },
  {
    label: 'Имя',
    key: 'firstName',
  },
  {
    label: 'Отчество',
    key: 'middleName',
  },
  {
    label: 'Гражданство Российской Федерации',
    key: 'isRussian',
  },
  {
    label: 'Дата рождения',
    key: 'birthDate',
  },
  {
    label: 'Опыт работы по специальности или направлению научно-практического проекта (лет)',
    key: 'experience',
  },
  {
    label: 'Должность',
    key: 'position',
  },
  {
    label: 'Образование',
    key: 'education',
  },
  {
    label: 'Ученая степень',
    key: 'degree',
  },
  {
    label: 'Ученое звание',
    key: 'title',
  },
  {
    label: 'Профессиональные достижения',
    key: 'achievements',
  },
  {
    label: 'Ключевые научные публикации члена команды по теме Проекта за последние 5 лет',
    key: 'publicationsText',
  },
  {
    label: 'Индекс Хирша',
    key: 'hirsh',
  },
  {
    label: 'Сведения о членстве члена команды в иностранных и российских научно-медицинских общественных объединениях, ассоциациях, союзах и федерациях (при наличии)',
    key: 'participation',
  },
  {
    label: 'Доклады члена команды по теме Проекта на международных научно-практических конференциях за последние 5 лет',
    key: 'eventsText',
  },
  {
    label: 'Наличие патентов и (или) заявок на получение патента на изобретение',
    key: 'ridText',
  },
];


const { TextArea } = Input;
const { Dragger } = Upload;
function AuthorTabPeopleModule({
  elementsArrayProp, applicationId, disabled, updateOriginalObject,
}) {
  const [elementsArray, setElementsArray] = useState([]);
  const [objectInEditId, setObjectInEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const formRef = useRef(null);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [SOPDFiles_ids, setSOPDFiles_ids] = useState([]);
  const [isRussian, setIsRussian] = useState(null);

  useEffect(() => {
    setElementsArray(elementsArrayProp);
    setIsFormDisabled(disabled);
  }, []);

  const onEditClick = (object) => {
    setObjectInEditId(object.id);
    setIsRussian(object.isRussian);
    setSOPDFiles_ids(object.SOPDFiles_ids);
  };

  const onAddClick = () => {
    setLoading(true);
    editApplications2AuthorTabPeople({ applications2_id: applicationId })
      .then((id) => {
        setObjectInEditId(id);
        const updatedArray = [...elementsArray, { id }];
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject('applications2AuthorTabPeople_ids', updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onDeleteClick = (id) => {
    setLoading(true);
    editApplications2AuthorTabPeople({ id, isDeleted: true })
      .then(() => {
        setObjectInEditId(null);
        const updatedArray = elementsArray.filter((p) => p.id !== id);
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject('applications2AuthorTabPeople_ids', updatedArray);
        notification.success({ message: 'Сохранено!' });
      });
  };

  const onCancelClick = () => {
    const values = formRef.current.getFieldsValue();
    const valuesKeys = Object.keys(values);
    let isEmpty = true;
    valuesKeys.forEach((k) => {
      if (values[k]) {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      onDeleteClick(objectInEditId);
    } else {
      setObjectInEditId(null);
      setSOPDFiles_ids([]);
    }
  };

  const onSaveClick = () => {
    let values = formRef.current.getFieldsValue();
    values = { ...values, isRussian };

    let error = false;

    if (!SOPDFiles_ids.length) {
      error = true;
      notification.error({ message: 'Не загружено согласие на обработку данныех' });
    }

    Object.entries(values).forEach(([key, value]) => {
      if (!value && !error) {
        const { label } = labels.find((l) => l.key === key);
        error = true;
        notification.error({ message: `Не заполнено поле: ${label}` });
      }
    });

    if (error) return;

    setLoading(true);
    editApplications2AuthorTabPeople({
      id: objectInEditId,
      birthDateText: values.birthDate ? values.birthDate.format('DD.MM.YYYY') : '',
      ...values,
      SOPDFiles_ids: SOPDFiles_ids.map((f) => f.id).join(','),
    })
      .then(() => {
        const updatedArray = elementsArray.map((p) => (p.id === objectInEditId ? {
          id: objectInEditId, ...p, ...values, SOPDFiles_ids,
        } : p));
        setObjectInEditId(null);
        setSOPDFiles_ids([]);
        setElementsArray(updatedArray);
        setLoading(false);
        updateOriginalObject('applications2AuthorTabPeople_ids', updatedArray);
        notification.success({ message: 'Успешно' });
      });
  };

  const onExpand = (object) => {
    setObjectInEditId(object.id);
    setIsExpanded(true);
    setIsFormDisabled(true);
    setIsRussian(object.isRussian);
    setSOPDFiles_ids(object.SOPDFiles_ids);
  };

  const onCollapse = () => {
    setObjectInEditId(null);
    setIsExpanded(false);
    setIsFormDisabled(disabled);
  };

  const onUpdateOriginalObject = (field, values) => {
    const updatedArray = elementsArray.map((p) => (p.id === objectInEditId ? {
      ...p, [field]: values,
    } : p));
    setElementsArray(updatedArray);
    updateOriginalObject('applications2AuthorTabPeople_ids', updatedArray);
  };

  const onDeleteFile = (file) => setSOPDFiles_ids(SOPDFiles_ids.filter((f) => f.id !== file.id));

  const onAddFile = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => setSOPDFiles_ids([...SOPDFiles_ids, file]));
    }
  };

  const onChangeHirsh = (e) => {
    let cleanedString = '';
    for (let i = 0; i < e.length; i += 1) {
      if (/[0-9,]/.test(e[i])) {
        cleanedString += e[i];
      }
    }
    formRef.current.setFieldsValue({ supervisorHirsh: cleanedString });
  };

  return (
    <div style={{
      margin: '20px', padding: '20px', border: '1px solid #cdcdcd', borderRadius: '10px', display: 'flex', flexDirection: 'column',
    }}
    >
      <span style={{ fontSize: '24px', color: '#212121' }}>Исполнители</span>
      {elementsArray.map((p, i) => (
        <Card
          key={p.id}
          style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
          bodyStyle={{
            borderLeft: objectInEditId === p.id ? '4px solid #1890FF' : '',
            borderRadius: objectInEditId === p.id ? '5px' : '',
          }}
        >
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                {p.lastName ? `${p.lastName} ${p.firstName || ''} ${p.middleName || ''}` : `Исполнитель №${i + 1}`}
              </Col>
              <Col>
                <Row align="middle" gutter={10}>
                  {!objectInEditId && (<Col><Button type="primary" onClick={() => onEditClick(p)} disabled={isFormDisabled}>Редактировать</Button></Col>)}
                  {!objectInEditId && (
                    <Col>
                      <Popconfirm
                        title="Уверены, что хотите удалить исполнителя?"
                        onConfirm={() => onDeleteClick(p.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button type="danger" disabled={isFormDisabled}>Удалить</Button>
                      </Popconfirm>
                    </Col>
                  )}
                  {!objectInEditId && (<Col><Button onClick={() => onExpand(p)}>Показать</Button></Col>)}
                </Row>
              </Col>
            </Row>
            {objectInEditId === p.id
                && (
                  <Modal title="Редактирование члена команды" visible footer={null} width="90vw" maskClosable={false} closable={false}>
                    <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row>
                    <Form initialValues={{ remember: true }} ref={formRef} style={{ marginTop: '20px' }}>
                      <Col>
                        <div className="common-subTitle">ФИО</div>
                        <div className="common-table-wrapper">
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Фамилия</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="lastName"
                              initialValue={p.lastName}
                            >
                              <TextArea autoSize disabled={isFormDisabled} />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell">Имя</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="firstName"
                              initialValue={p.firstName}
                            >
                              <TextArea autoSize disabled={isFormDisabled || loading} />
                            </Form.Item>
                          </div>
                          <div className="common-table-row">
                            <div className="common-table-row-cell" style={{ borderBottom: '1px solid #d9d9d9' }}>Отчество</div>
                            <Form.Item
                              style={{ width: '100%' }}
                              name="middleName"
                              initialValue={p.middleName}
                            >
                              <TextArea autoSize disabled={isFormDisabled} />
                            </Form.Item>
                          </div>
                        </div>

                        <Form.Item
                          style={{ width: '100%' }}
                          name="isRussian"
                          initialValue={p.isRussian}
                        >
                          <Checkbox style={{ marginBottom: '10px' }} defaultChecked={isRussian} onChange={(e) => setIsRussian(e.target.checked)}>Гражданство Российской Федерации</Checkbox>
                        </Form.Item>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">3.3.2. Дата рождения</span>
                          <Form.Item
                            name="birthDate"
                            initialValue={p.birthDate}
                          >
                            <DatePicker disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">Опыт работы по специальности или направлению научно-практического проекта (лет)</span>
                          <Form.Item
                            name="experience"
                            initialValue={p.experience}
                          >
                            <InputNumber
                              disabled={isFormDisabled}
                            />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">Должность</span>
                          <Form.Item
                            name="position"
                            initialValue={p.position}
                          >
                            <Input disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">3.3.3. Образование</span>
                          <span className="common-field-hint">В данном поле можно указать информацию о высшем профессиональном образовании, а также о курсах повышения квалификации и(или) профессиональной подготовки. Информация должна содержать в себе следующее: вид образования, наименование учреждения, название специальности/направления обучения, год окончания. Например: «Высшее образование - специалитет, ФГАОУ ВО "Российский Национальный Исследовательский Медицинский Университет им. Н.И. Пирогова" Министерства здравоохранения Российской Федерации, Лечебное дело, 2022 г.»</span>
                          <Form.Item
                            name="education"
                            initialValue={p.education}
                          >
                            <TextArea autoSize disabled={isFormDisabled} minrows={4} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">3.3.4. Ученая степень</span>
                          <span className="common-field-hint">Например: кандидат биологических наук, доктор медицинских наук и т.п.</span>
                          <Form.Item
                            name="degree"
                            initialValue={p.degree}
                          >
                            <TextArea placeholder='Введите текст. В случае отсутствия, пожалуйста, укажите слово "нет"' autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">3.3.5. Ученое звание</span>
                          <span className="common-field-hint">Например: доцент, профессор, член-корреспондент РАН, академик РАН и т.д.</span>
                          <Form.Item
                            name="title"
                            initialValue={p.title}
                          >
                            <TextArea placeholder='Введите текст. В случае отсутствия, пожалуйста, укажите слово "нет"' autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">3.3.6. Профессиональные достижения</span>
                          <span className="common-field-hint">
                            Например: внедрил инновационные программы лечебной гимнастики по развитию мелкой моторики у детей дошкольного возраста; получил высшую квалификационную категорию
                            по специальности челюстно-лицевая хирургия; прошёл сертификационный цикл
                            по клинической кардиологии и т.д.
                          </span>
                          <Form.Item
                            name="achievements"
                            initialValue={p.achievements}
                          >
                            <TextArea placeholder='Введите текст. В случае отсутствия, пожалуйста, укажите слово "нет"' autoSize disabled={isFormDisabled} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">
                            3.3.7. Ключевые научные публикации члена команды по теме Проекта за последние 5 лет
                            {' '}
                            <b>(максимум - 10 публикаций)</b>
                            :
                          </span>
                          {/* <Form.Item
                            name="publicationsText"
                            initialValue={p.publicationsText}
                          >
                            <TextArea autoSize disabled={disabled} minrows={4} />
                          </Form.Item> */}
                        </div>
                        {/* <div className="common-subTitle">4.3.7. Научные публикации, характеризующие вклад члена команды в реализацию Проекта за последние 5 лет:</div> */}
                        <AuthorTabPublicationsModule
                          elementsArrayProp={p.applications2AuthorTabPublications_ids || []}
                          applicationId={p.id}
                          disabled={isFormDisabled}
                          isAuthorTabPeople
                          updateOriginalObject={onUpdateOriginalObject}
                        />

                        <div className="common-field-wrapper">
                          <span className="common-field-label">3.3.8. Индекс Хирша</span>
                          <span className="common-field-hint">наукометрический показатель, который дает комплексную оценку одновременно числу публикаций ученого и их цитируемости. Значение Индекса Хирша можно найти в системе размещения научных публикаций (по РИНЦ)</span>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Form.Item
                              name="hirsh"
                              initialValue={p.hirsh}
                            >
                              <Input
                                onChange={(e) => onChangeHirsh(e.target.value)}
                                disabled={isFormDisabled}
                              />
                            </Form.Item>
                            <div style={{ marginLeft: '5px' }}>по РИНЦ</div>
                          </div>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">3.3.9. Сведения о членстве члена команды в иностранных и российских научно-медицинских общественных объединениях, ассоциациях, союзах и федерациях (при наличии)</span>
                          <Form.Item
                            name="participation"
                            initialValue={p.participation}
                          >
                            <TextArea placeholder='Введите текст. В случае отсутствия, пожалуйста, укажите слово "нет"' autoSize disabled={isFormDisabled} minrows={4} />
                          </Form.Item>
                        </div>

                        <div className="common-field-wrapper">
                          <span className="common-field-label">
                            3.3.10. Доклады члена команды по теме Проекта на международных научно-практических конференциях  за последние 5 лет
                            {' '}
                            <b>(максимум - 10 мероприятий)</b>
                            :
                          </span>
                          {/* <span className="common-field-hint">Для каждого мероприятия укажите название, дату проведения и примерное количество участников</span>
                          <Form.Item
                            name="eventsText"
                            initialValue={p.eventsText}
                          >
                            <TextArea autoSize disabled={disabled} minrows={4} />
                          </Form.Item> */}
                        </div>
                        {/* <div className="common-subTitle">4.3.10. Доклады на международных научно-практических конференциях за последние 5 (Пять) лет, характеризующие вклад члена команды в реализацию Проекта</div> */}
                        <AuthorTabEventModule
                          elementsArrayProp={p.applications2AuthorTabEvent_ids || []}
                          applicationId={p.id}
                          disabled={isFormDisabled}
                          isAuthorTabPeople
                          updateOriginalObject={onUpdateOriginalObject}
                        />

                        <div className="common-field-wrapper">
                          <span className="common-field-label">
                            3.3.11. Наличие патентов и (или) заявок на получение патента на изобретение
                            {' '}
                            <b>(максимум - 10 патентов)</b>
                            :
                          </span>
                          <span className="common-field-hint">Укажите вид и дату регистрации охранного документа, наименование РИД и краткое описание объекта интеллектуальной собственности</span>
                          {/* <Form.Item
                            name="ridText"
                            initialValue={p.ridText}
                          >
                            <TextArea autoSize disabled={disabled} minrows={4} />
                          </Form.Item> */}
                        </div>
                        {/* <div className="common-subTitle">4.3.11. Наличие патентов и (или) заявок на получение патента на изобретение) (при наличии)</div> */}
                        <AuthorTabRidModule
                          elementsArrayProp={p.applications2AuthorTabRid_ids || []}
                          applicationId={p.id}
                          disabled={isFormDisabled}
                          isAuthorTabPeople
                          updateOriginalObject={onUpdateOriginalObject}
                        />
                        <div style={{ marginTop: '20px' }}>
                          Скачайте, заполните и подпишите Согласие на обработку персональных данных. Шаблон доступен по
                          {' '}
                          <a href="https://api.directual.com/fileUploaded/medtechgrants/81736099-eb7a-4890-8d06-be8e2975a141.docx">ссылке</a>
                        </div>
                        <Dragger
                          showUploadList={false}
                          onChange={(file) => onAddFile(file)}
                          disabled={disabled}
                          multiple
                          action={null}
                          accept=".pdf"
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Выберите или перенесите файл в формате .pdf, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
                        </Dragger>
                        {SOPDFiles_ids.map((f) => (
                          <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                            <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
                            {!disabled && <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => onDeleteFile(f)} /></Col>}
                            <Col>{f.name}</Col>
                          </Row>
                        ))}

                      </Col>
                    </Form>
                    <Row justify="end">
                      {objectInEditId === p.id && isExpanded && (<Col><Button type="primary" onClick={() => onCollapse()}>Закрыть</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button type="primary" onClick={onSaveClick} disabled={isFormDisabled} style={{ marginRight: '10px' }}>Сохранить</Button></Col>)}
                      {objectInEditId === p.id && !isExpanded && (<Col><Button onClick={onCancelClick}>Отменить</Button></Col>)}
                    </Row>
                  </Modal>
                )}
          </Col>
        </Card>
      ))}
      {!objectInEditId && <Button type="primary" onClick={onAddClick} style={{ marginTop: '20px', width: '200px', fontSize: '16px' }} disabled={isFormDisabled}>Добавить исполнителя</Button>}
    </div>
  );
}

AuthorTabPeopleModule.propTypes = {
  elementsArrayProp: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  applicationId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  updateOriginalObject: PropTypes.func.isRequired,
};

export default AuthorTabPeopleModule;
