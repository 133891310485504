/* eslint-disable object-curly-newline */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Button, message, Spin } from 'antd';
import md5 from 'md5';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import Users from './components/Users/Users';
import Applications from './components/Applications/Applications';
import ApplicationsStep2 from './components/ApplicationsStep2/ApplicationsStep2';
// import Survey from '../../../../components/Survey/Survey';
import GreatArchive from './components/GreatArchive/GreatArchive';
import RegApplications from './components/RegApplications/RegApplications';
import { routeGenerator, routes } from '../../../../utils/routes';
import {
  generateApplicationsXLS, editApplications2, getApplications2, getSupportedApplications, getApplicationsForFinance, getApplicationSurvey,
  getApplicationSurveyPostmonitoring,
} from '../../../../utils/http';
import getUserRole from '../../../../utils/localeStorage/getUserRole';
import getUserId from '../../../../utils/localeStorage/getUserId';
// import getUserShortName from '../../../../utils/localeStorage/getUserShortName';
import getYear from '../../../../utils/localeStorage/getYear';
import './HomePageDesktop.less';
import CreateAppAdmin from './components/CreateAppAdmin/CreateAppAdmin';
import onExportXLS from './utils/createXLSDocument';
import exportZip from './utils/exportZip';
import ExpertProjects from './components/ExpertProjects/ExpertProjects';
import exportFinanceXLS from './utils/exportFinance/exportFinanceXLS';
import exportSurveyXLS from './utils/exportSurveyXLS';
import exportSurvey2024XLS from './utils/expoerSurvey2024XLS';
import DraftApplications from './components/DraftApplications/DraftApplications';
// import organisations from '../../../ApplicationStep2/organisations';


@withRouter
class HomePageDesktop extends Component {
  state = {
    currentSection: {},
    loading: false,
    isShowModal: false,
    buttons: [],
  }

  componentDidMount() {
    let buttons = [];
    let currentSection = {};
    switch (getUserRole()) {
      case 'bigAdmin':
        buttons = [
          { id: 'Applications', name: 'Заявки 2022', state: { is2022: true, is2023: false }, key: '2022Application' },
          { id: 'Applications', name: 'Заявки 2023', state: { is2022: false, is2023: true }, key: '2023Application' },
          { id: 'Applications2', name: 'Заявки 2024', state: {}, key: 'Applications2' },
          { id: 'Projects', name: 'Проекты Экспертов', state: {}, key: 'expertProjects' },
          { id: 'Registration', name: 'Заявки на регистрацию', state: {}, key: 'Registration' },
          { id: 'Users', name: 'Активные пользователи', state: {}, key: 'Users' },
          { id: 'download', name: 'Скачать все заявки xls', state: { type: 'xls2022' } },
          // { id: 'download', name: 'Скачать заявки xls формат 2023', state: { type: 'xls2023' } },
          { id: 'download', name: 'Скачать заявки zip 2023', state: { type: 'zip2023' } },
          { id: 'download', name: 'Скачать учет финансов xls', state: { type: 'finance' } },
          { id: 'download', name: 'Скачать опрос xls', state: { type: 'survey' } },
          { id: 'GreatArchive', name: 'Великий Архив', state: {}, key: 'GreatArchive' },
          { id: 'download', name: 'Скачать опрос xls 2024', state: { type: 'survey2024' } },
        ];
        currentSection = { id: 'Applications', state: { is2022: true, is2023: false }, key: '2022Application' };
        break;
      case 'External':
        buttons = [{ id: 'Users', name: 'Активные пользователи', state: {}, key: 'Users' }];
        currentSection = { id: 'Users', state: {}, key: 'Users' };
        break;
      case 'user':
      case 'noDSM':
      case 'DSM':
        buttons = [];
        if (getYear() === '2022') {
          buttons.push({ id: 'Applications2', name: 'Мои заявки 2024', state: {}, key: 'Applications2' });
          buttons.push({ id: 'Applications', name: 'Мои заявки 2023', state: { is2022: false, is2023: true }, key: '2023Application' });
          buttons.push({ id: 'Applications', name: 'Мои заявки 2022', state: { is2022: true, is2023: false }, key: '2022Application' });
          buttons.push({ id: 'Applications', name: 'Активные гранты', state: { is2022: true, is2023: true }, key: 'ActiveApplications' });
        }
        if (getYear() === '2024') {
          buttons.push({ id: 'Applications2', name: 'Мои заявки 2024', state: {}, key: 'Applications2' });
          buttons.push({ id: 'Applications', name: 'Активные гранты', state: { is2022: true, is2023: true }, key: 'ActiveApplications' });
        }
        if (getUserId() === 'Test2022') {
          buttons.push({ id: 'Survey', name: 'Опрос', state: {}, key: 'Survey' });
        }
        buttons.push({ id: 'DraftApplications', name: 'Архив', state: { isArchive: true }, key: 'DraftApplications' });
        currentSection = { id: 'Applications2', state: {}, key: 'Applications2' };
        break;
      case 'expertDZM':
      case 'expertFederal':
        buttons = [{ id: 'Projects', name: 'Проекты', state: { is2023: true }, key: 'Projects' }, { id: 'Profile', name: 'Профиль', key: 'Profile' }];
        currentSection = { id: 'Projects', state: {}, key: 'Projects' };
        break;
      default: break;
    }
    this.setState({ buttons, currentSection });
  }

  onMenuClick = (id, state, key) => {
    if (id === 'download') {
      switch (state.type) {
        case 'xls2022':
          this.generateApplicationsXLS();
          break;
        case 'xls2023':
          this.onGenerateXls2023();
          break;
        case 'zip2023':
          this.onExportZip2023();
          break;
        case 'finance':
          this.onExportFinance();
          break;
        case 'survey':
          this.onExportSurvey();
          break;
        case 'survey2024':
          this.onExportSurvey2024();
          break;
        default: break;
      }
    } else if (id === 'Profile') {
      const { history } = this.props;
      history.push(routes.profile);
    } else {
      this.setState({ currentSection: { id, state, key } });
    }
  }

  onCloseModal = () => {
    this.setState({ isShowModal: false });
  }

  generateApplicationsXLS = () => {
    this.setState({ loading: true });
    generateApplicationsXLS({ id: md5(new Date() + Math.random()) })
      .then((url) => {
        this.setState({ loading: false });
        if (url && url !== '') {
          window.open(url, '_blank');
        } else {
          message.error('Нет заявок');
        }
      });
  }

  onCreateApplication = () => {
    const { history } = this.props;
    this.setState({ loading: true });
    editApplications2({ webUser_id: getUserId(), createDate: moment(), status: 'Черновик', year: '2024' })
      .then((id) => history.push(routeGenerator.application2Link(id)));
  }

  onGenerateXls2023 = () => {
    this.setState({ loading: true });
    getApplications2()
      .then((data) => {
        onExportXLS(data);
        this.setState({ loading: false });
      });
  }

  onExportZip2023 = () => {
    this.setState({ loading: true });
    getApplications2()
      .then((data) => {
        exportZip(data.filter((d) => d.status === 'На доработке'));
        this.setState({ loading: false });
      });
  }

  onExportFinance = () => {
    getApplicationsForFinance()
      .then((result) => exportFinanceXLS(result));
  }

  onExportSupportedZip = () => {
    this.setState({ loading: true });
    getSupportedApplications()
      .then(() => {
        // exportZip(data);
        this.setState({ loading: false });
      });
  }

  onExportSurvey = () => {
    this.setState({ loading: true });
    getApplicationSurvey({ pageSize: 180 })
      .then((data) => {
        exportSurveyXLS(data);
        this.setState({ loading: false });
      });
  }

  onExportSurvey2024 = () => {
    this.setState({ loading: true });
    getApplicationSurveyPostmonitoring({ pageSize: 60 })
      .then((data) => {
        exportSurvey2024XLS(data.data);
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      currentSection, loading, isShowModal, buttons,
    } = this.state;

    const isShowAddButton = getUserRole() !== 'noDSM';
    // const isShowAddButton = organisations.map((e) => e.shortName).includes(getUserShortName()) || getUserId() === 'Test2022' || getUserRole() === 'bigAdmin';

    return (
      <div className="homePageDesktop-wrapper">
        {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />} style={{ position: 'absolute', top: '40%', left: '40%' }} />}
        <div className="homePageDesktop-buttons-wrapper">
          {/* {((getUserRole() !== 'external' && isShowNewForm && moment('18.03.2023', 'DD.MM.YYYY').isAfter(moment())) || (getUserRole() === 'bigAdmin')) && ( */}

          {/* {((getUserRole() === 'bigAdmin' || getUserId() === 'Test2022') && ( */}
          {isShowAddButton && (
          <Button
            className="homePageDesktop-buttons-apply"
            onClick={() => (getUserRole() === 'bigAdmin' ? this.setState({ isShowModal: true }) : this.onCreateApplication())}
          >
            Подать заявку
          </Button>
          )}


          {buttons.map((button) => (
            <Button
              className="homePageDesktop-buttons-menu"
              style={{ backgroundColor: currentSection.key === button.key ? '#06b5f1' : '#fff', color: currentSection.key === button.key ? '#fff' : '#212121' }}
              onClick={() => this.onMenuClick(button.id, button.state, button.key)}
            >
              {button.name}
            </Button>
          ))}

        </div>
        <div className="homePageDesktop-table-wrapper">
          {currentSection.id === 'Applications' && <Applications filterParams={currentSection.state} />}
          {currentSection.id === 'Projects' && <ExpertProjects />}

          {currentSection.id === 'Users' && <Users />}
          {currentSection.id === 'Registration' && <RegApplications />}
          {currentSection.id === 'GreatArchive' && <GreatArchive />}
          {currentSection.id === 'Applications2' && <ApplicationsStep2 />}
          {currentSection.id === 'DraftApplications' && <DraftApplications />}
          {/* {currentSection.id === 'Survey' && <Survey />} */}
        </div>
        {isShowModal && <CreateAppAdmin onCloseModal={this.onCloseModal} />}
      </div>
    );
  }
}

HomePageDesktop.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(HomePageDesktop);
