/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import {
  notification, Input, Select, Table,
  Alert,
} from 'antd';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';
import getUserId from '../../../../../../utils/localeStorage/getUserId';
import {
  getApplications2,
} from '../../../../../../utils/http';
import getColumns from './getColumns';
import getAdminColumns from './getAdminColumns';
import scientificDirectionCatalogue from '../../../../../ApplicationStep2/Common/ShortTab/scientificDirectionCatalogue';
import './ApplicationsStep2.css';


const selectStatusOptions = [
  {
    id: 'Черновик',
    label: 'Черновик',
    value: 'Черновик',
  },
  {
    id: 'На обработке',
    label: 'На обработке',
    value: 'На обработке',
  },
  {
    id: 'На доработке',
    label: 'На доработке',
    value: 'На доработке',
  },
  {
    id: 'Принята к рассмотрению',
    label: 'Принята к рассмотрению',
    value: 'Принята к рассмотрению',
  },
  {
    id: 'В архиве',
    label: 'В архиве',
    value: 'В архиве',
  },
];
const { Search } = Input;
function ApplicationsStep2() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDatasource] = useState([]);
  const [selectStatusValue, setSelectedStatusValue] = useState(['Черновик', 'На обработке', 'На доработке', 'Принята к рассмотрению', 'В архиве']);
  const [selectScientificDirectionnValue, setSelectScientificDirectionnValue] = useState([]);
  const [searchText, setSearchText] = useState(null);

  const onGetData = (text, status, direction) => {
    setLoading(true);

    const searchObj = {
      searchText: text,
      status: status.join(','),
      scientificDirection: direction.join(','),
      webUserId: getUserRole() === 'bigAdmin' ? null : getUserId(),
    };
    getApplications2({ webUserId: getUserId(), pageSize: '60', ...searchObj })
      .then((applications) => {
        setLoading(false);
        setDatasource(applications);
      })
      .catch((error) => notification.error({ message: 'Ошибка!', descriptio: error.message }));
  };

  useEffect(() => {
    onGetData(searchText, selectStatusValue, selectScientificDirectionnValue);
  }, []);

  const onSearch = (value) => {
    setSearchText(value);
    onGetData(value, selectStatusValue, selectScientificDirectionnValue);
  };
  const onChangeStatus = (value) => {
    setSelectedStatusValue(value);
    onGetData(searchText, value, selectScientificDirectionnValue);
  };
  const onChangeDirection = (value) => {
    setSelectScientificDirectionnValue(value);
    onGetData(searchText, selectStatusValue, value);
  };

  return (
    <div className="applications-wrapper">
      <div className="applications-buttons-wrapper">
        <div className="applications-button-wrapper">
          <div>Поиск</div>
          <Search
            placeholder="Поиск по номеру, теме..."
            style={{ width: '100%' }}
            onSearch={onSearch}
          />
        </div>
        {getUserRole() !== 'noDSM' && (
          <div className="applications-button-wrapper">
            <div>Фильтр по статусу</div>
            <Select
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              value={selectStatusValue}
              options={selectStatusOptions}
              onChange={(newValue) => onChangeStatus(newValue)}
              placeholder="Выберите статус..."
              maxTagCount="responsive"
            />
          </div>
        )}
        {getUserRole() !== 'noDSM' && (
          <div className="applications-button-wrapper">
            <div>Фильтр по направлению</div>
            <Select
              allowClear
              mode="multiple"
              style={{ width: '100%' }}
              value={selectScientificDirectionnValue}
              options={scientificDirectionCatalogue.map((sp) => ({ label: sp, value: sp }))}
              onChange={(newValue) => onChangeDirection(newValue)}
              placeholder="Выберите направление..."
              maxTagCount="responsive"
            />
          </div>
        )}
      </div>
      {getUserRole() !== 'bigAdmin' && (
      <Alert
        type="error"
        style={{ margin: '-12px 0px 20px 0px', width: '75%' }}
        description={(
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b>Продлены сроки заявочной кампании грантовой программы</b>
            <br />
            <div>
              Срок
              {' '}
              <b>подачи заявок</b>
              {' '}
              на получение финансовой поддержки для реализации актуальных научно-практических проектов в сфере медицины
              <b> продлен до 10 октября 2024 года (включительно).</b>
              <br />
              <br />
              Чтобы принять участие в&nbsp;конкурсном отборе в&nbsp;рамках заявочной кампании 2024&nbsp;года, необходимо до&nbsp;10&nbsp;октября подать заявку на&nbsp;получение финансовой поддержки для реализации научно-практического проекта в&nbsp;сфере здравоохранения и&nbsp;медицины. Подготовьте и&nbsp;загрузите заверенную печатью и&nbsp;подписанную руководителем медицинского учреждения заявку в&nbsp;поле &laquo;Загрузить подписанную заявку и&nbsp;направить на&nbsp;рассмотрение&raquo; в&nbsp;карточке соответствующей заявки. Первичная обработка заявки по&nbsp;формальным критериям займёт до&nbsp;5&nbsp;рабочих дней, после чего заявка может быть отправлена на&nbsp;доработку. Повторная подача исправленной заявки будет возможна до&nbsp;23:59 10&nbsp;октября 2024&nbsp;года.
              {' '}
              <br />
              <br />
              Пожалуйста, учитывайте это при подаче заявки!
            </div>
            <br />
            Объявление победителей запланировано на 29 ноября 2024 года.
          </div>
            )}
      />
      )}
      <div className="applications-table-wrapper ">
        <Table
          dataSource={orderBy(dataSource, 'status', 'desc')}
          columns={getUserRole() === 'bigAdmin' ? getAdminColumns() : getColumns()}
          loading={loading}
          scroll={{ y: '50vh' }}
          pagination={false}
          rowKey="id"
        />
      </div>
    </div>
  );
}

// ApplicationsStep2.propTypes = {
//   history: PropTypes.shape().isRequired,
// };


export default ApplicationsStep2;
