/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, Row, Card, Alert, Upload, Modal, Spin, Form, Popconfirm, DatePicker, notification,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined, LoadingOutlined,
} from '@ant-design/icons';
import {
  editApplicationStepReport, uploadFile, editApplication,
} from '../../../../../../utils/http';
import getUserRole from '../../../../../../utils/localeStorage/getUserRole';


const { Dragger } = Upload;
class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application: null,
      loading: true,
      isShowUploadActModal: false,
      uploadActFile: null,
      isShowNirReport: true,
    };
    this.commonFormRef = React.createRef();
  }

  componentDidMount() {
    const { initialApplication } = this.props;
    this.setState({
      loading: false,
      application: initialApplication,
    });
  }

  onSaveUploadActFile = () => {
    const { application, uploadActFile } = this.state;
    editApplicationStepReport({ id: application.id, actReport_id: uploadActFile.id })
      .then(() => this.setState({
        application: { ...application, actReport_id: uploadActFile },
        uploadActFile: null,
        isShowUploadActModal: false,
      }));
  }

  onActFileUpload = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          this.setState({ uploadActFile: file });
        });
    }
  }

  onActFileRemove = () => {
    this.setState({ uploadActFile: null });
  }

  onNirTemplateFilesUpload = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          const { application } = this.state;
          editApplicationStepReport({ id: application.id, nirTemplateFiles_ids: [...application.nirTemplateFiles_ids.map((f) => f.id), file.id].join(',') })
            .then(() => this.setState((prevState) => ({
              application: {
                ...prevState.application,
                nirTemplateFiles_ids: [...prevState.application.nirTemplateFiles_ids, file],
              },
            })));
        });
    }
  }

  onNirTemplateFilesRemove = (file) => {
    const { application } = this.state;
    editApplicationStepReport({ id: application.id, nirTemplateFiles_ids: application.nirTemplateFiles_ids.filter((f) => f.id !== file.id).map((f) => f.id).join(',') })
      .then(() => this.setState((prevState) => ({
        application: {
          ...prevState.application,
          nirTemplateFiles_ids: prevState.application.nirTemplateFiles_ids.filter((f) => f.id !== file.id),
        },
      })));
  }

  onNirReportFileUpload = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      form.append('file', info.file.originFileObj);
      uploadFile(form, headers)
        .then((file) => {
          const { application } = this.state;
          editApplicationStepReport({ id: application.id, nirReport_ids: [...application.nirReport_ids.map((f) => f.id), file.id].join(',') })
            .then(() => this.setState((prevState) => ({
              application: {
                ...prevState.application,
                nirReport_ids: [...prevState.application.nirReport_ids, file],
              },
            })));
        });
    }
  }

  onNirReportFileRemove = (file) => {
    const { application } = this.state;
    editApplicationStepReport({ id: application.id, nirReport_ids: application.nirReport_ids.filter((f) => f.id !== file.id).map((f) => f.id).join(',') })
      .then(() => this.setState((prevState) => ({
        application: {
          ...prevState.application,
          nirReport_ids: prevState.application.nirReport_ids.filter((f) => f.id !== file.id),
        },
      })));
  }


  onSaveCommonForm = () => {
    const values = this.commonFormRef.current.getFieldsValue();
    editApplicationStepReport(values)
      .then(() => {
        const { updateParentApplication } = this.props;
        const { application } = this.state;
        notification.success({ message: 'Сохранено' });

        this.setState({ application: { ...application, ...values } });
        updateParentApplication({ applicationStepReports: application.applicationStepReports.map((step) => (step.id === values.id ? { ...step, endDate: values.endDate } : step)) });
      });
  }

  onDeleteStep = () => {
    const { application } = this.state;
    const newStepNumbers = Number((Number(application.maxStepsNumber) - 1).toFixed(0));
    const newApplicationStepReports = application.applicationStepReports.filter((step) => step.id !== application.id);
    Promise.all([
      editApplicationStepReport({ id: application.id, isDeleted: true }),
      editApplication({ id: application.parentAppId, maxStepsNumber: newStepNumbers, applicationStepReport_ids: newApplicationStepReports.map((e) => e.id).join(',') }),
    ]).then(() => {
      const { updateParentApplication } = this.props;
      notification.success({ message: 'Сохранено' });
      this.setState({ application: { ...application, maxStepsNumber: newStepNumbers, applicationStepReports: newApplicationStepReports } });
      updateParentApplication({ applicationStepReports: newApplicationStepReports, maxStepsNumber: newStepNumbers });
    });
  }

  render() {
    const {
      application, loading, isShowUploadActModal, uploadActFile, isShowNirReport,
    } = this.state;
    return (
      <div className="application-reports">
        {loading && (
          <Spin
            style={{ position: 'fixed', fontSize: '60px', top: '30%' }}
            indicator={(<LoadingOutlined style={{ fontSize: '100px', margin: '-80px 0px 0px -60px' }} spin />)}
            tip="Загрузка..."
          >
            <div />
          </Spin>
        )}
        {!application && !loading
                  && (
                  <Alert
                    message="Уважаемые Грантополучатели!"
                    description="Что-то пошло не так. Просьба обратиться к ответственному сотруднику оператора конкурсного отбора: Звонарева Елена Сергеевна +7(916)104-57-96"
                    type="warning"
                  />
                  )}
        {application && (
        <Col>

          {getUserRole() === 'bigAdmin' && (
          <Card style={{ width: '100%', borderRadius: '15px', marginTop: '20px' }}>
            <div className="application-main-wrapper" style={{ display: 'flex', padding: 0 }}>
              <Col>
                <Form
                  initialValues={{ remember: true }}
                  ref={this.commonFormRef}
                >
                  <Form.Item
                    name="id"
                    initialValue={application.id}
                    hidden
                  />
                  <div className="application-main-box-content-wrapper">
                    <span className="application-main-box-label">Дата окончания этапа</span>
                    <Form.Item
                      name="endDate"
                      initialValue={application.endDate}
                    >
                      <DatePicker style={{ width: '100%' }} placeholder="Заполняется автоматически" format="DD.MM.YYYY" />
                    </Form.Item>
                  </div>
                </Form>
                <Row style={{ marginTop: '20px' }} gutter={10}>
                  <Col><Button type="primary" onClick={this.onSaveCommonForm}>Сохранить</Button></Col>
                  {Number(application.stepNumber) === application.maxStepsNumber && (
                  <Col>
                    <Popconfirm
                      title="Вы действительно удалить этап?"
                      onConfirm={this.onDeleteStep}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <Button type="danger">Удалить этап</Button>
                    </Popconfirm>
                  </Col>
                  )}
                </Row>
              </Col>
            </div>
          </Card>
          )}
          <Card
            style={{
              width: '100%', borderRadius: '15px', marginTop: '20px', height: '100%',
            }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Акт сдачи-приемки выполненных работ по этапу</Col>
              <Col>
                <Row gutter={10}>
                  {getUserRole() === 'bigAdmin' && (
                  <Col>
                    <Button type="primary" onClick={() => this.setState({ isShowUploadActModal: true })} shape="round">
                      Загрузить
                    </Button>
                  </Col>
                  )}
                  {application.actReport_id && (
                  <Col>
                    <Button type="primary" onClick={() => window.open(application.actReport_id.urlLink)} shape="round" style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}>
                      Скачать
                    </Button>
                  </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>

          <Card
            style={{ width: '100%', borderRadius: '15px', marginTop: '20px' }}
          >
            <Row justify="space-between" align="middle">
              <Col style={{ fontSize: '20px', color: '#212121' }}>Отчет о НИР по ГОСТ 7.32-2017</Col>
              <Col style={{ cursor: 'pointer' }} onClick={() => this.setState((prevState) => ({ isShowNirReport: !prevState.isShowNirReport }))}>
                {isShowNirReport ? <Button>Закрыть</Button> : <Button>Посмотреть</Button>}
              </Col>
            </Row>
            <Alert
              type="warning"
              style={{ margin: '10px 0px' }}
              description="Ниже представлены вспомогательные документы для подготовки отчета, соответствующего общим требованиям к структуре и правилам оформления, установленным «ГОСТ 7.32-2017. Межгосударственный стандарт. Система стандартов по информации, библиотечному и издательскому делу. Отчет о научно-исследовательской работе. Структура и правила оформления»."
            />
            {isShowNirReport && (
              <Col span={24} style={{ paddingTop: '20px' }}>
                {getUserRole() === 'bigAdmin' && (
                <Dragger
                  showUploadList={false}
                  onChange={this.onNirTemplateFilesUpload}
                  action={null}
                  multiple
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Выберите или перенесите файлы, чтобы загрузить (не более 15мб)</p>
                </Dragger>
                )}
                {application.nirTemplateFiles_ids.map((f) => (
                  <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                    <Col><Button type="primary" onClick={() => window.open(f.urlLink)}>Скачать</Button></Col>
                    {getUserRole() === 'bigAdmin' && <Col><Button onClick={() => this.onNirTemplateFilesRemove(f)}>Удалить</Button></Col>}
                    <Col style={{ color: '#212121', fontSize: '20px' }}>{f.name}</Col>
                  </Row>
                ))}
                <Row style={{ fontSize: '18px', color: '#212121', marginTop: '20px' }}>Файлы отчета</Row>
                <Dragger
                  showUploadList={false}
                  onChange={this.onNirReportFileUpload}
                  action={null}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Выберите или перенесите файлы, чтобы загрузить</p>
                </Dragger>
                {application.nirReport_ids.map((f) => (
                  <Row key={f.id} style={{ margin: '5px 0px' }} gutter={12}>
                    <Col><Button type="primary" onClick={() => window.open(f.urlLink)}>Скачать</Button></Col>
                    <Col><Button onClick={() => this.onNirReportFileRemove(f)}>Удалить</Button></Col>
                    <Col style={{ color: '#212121', fontSize: '20px' }}>{f.name}</Col>
                  </Row>
                ))}
              </Col>
            )}
          </Card>

        </Col>
        )}


        {isShowUploadActModal
        && (
        <Modal
          visible
          width="70vw"
          maskClosable={false}
          onCancel={() => this.setState({ isShowUploadActModal: false, uploadActFile: null })}
          title={`Загрузка файла для ${application.displayID}`}
          footer={[
            <Button key="submit" type="primary" onClick={this.onSaveUploadActFile}>
              Загрузить
            </Button>]}
        >
          <Col>
            {!uploadActFile && (
            <Dragger
              showUploadList={false}
              onChange={this.onActFileUpload}
              action={null}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Выберите или перенесите файл, чтобы загрузить (не более 15мб). Для прикрепления нескольких файлов осуществляйте их загрузку по очереди, а не одновременно</p>
            </Dragger>
            )}
            {uploadActFile
            && (
            <Row style={{ margin: '5px 0px' }} gutter={12}>
              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(uploadActFile.urlLink)} /></Col>
              <Col><DeleteOutlined style={{ cursor: 'pointer' }} onClick={this.onActFileRemove} /></Col>
              <Col>{uploadActFile.name}</Col>
            </Row>
            )}
          </Col>
        </Modal>
        )}
      </div>
    );
  }
}

Reports.propTypes = {
  initialApplication: PropTypes.shape().isRequired,
  updateParentApplication: PropTypes.func.isRequired,
};

export default Reports;
